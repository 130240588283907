import { default as $axios } from "axios";

export const accessTokenKey = "__access_token__";
export const refreshTokenKey = "__refresh_token__";
export const isFirstLoginKey = "__is_first_login__";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Accept-Language": "ro",
  "Saas-App-Token": process.env.REACT_APP_SAAS_TOKEN
};

export const cmsClient = $axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: defaultHeaders
});

export const ssoClient = $axios.create({
  baseURL: process.env.REACT_APP_BASE_SSO,
  headers: {
    "Accept-Language": "ro",
    "Content-Type": "application/json"
  }
});

const authInterceptor = (config) => {
  const token = localStorage.getItem(accessTokenKey);

  if (token) {
    config.headers!["Authorization"] = `Token ${token}`;
  }

  return config;
};

cmsClient.interceptors.request.use(authInterceptor, (error) => {
  return Promise.reject(error);
});

ssoClient.interceptors.request.use(authInterceptor, (error) => {
  return Promise.reject(error);
});

export const setLocalStorageTokens = (access: string, refresh: string) => {
  localStorage.setItem(accessTokenKey, access);
  localStorage.setItem(refreshTokenKey, refresh);
};

export const removeLocalStorageTokens = () => {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
};

export const getLocalStorageTokens = () => ({
  access: localStorage.getItem(accessTokenKey),
  refresh: localStorage.getItem(refreshTokenKey)
});
