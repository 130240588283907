import { Flex, Progress, Space, Tag, theme, Typography } from "antd";
import { getPercentage } from "features/voting/utils";
import { ChoicesEntity } from "types";
import { styles } from "./style";

interface Props {
  choices: ChoicesEntity[];
}

export const Choices = ({ choices }: Props) => {
  const totalResponses = choices?.reduce(
    (sum, choice) => sum + choice?.total_responses,
    0
  );

  const cssStyles = styles();
  const { token } = theme.useToken();

  return (
    <Flex vertical gap={24}>
      {choices?.map((choice) => {
        const totalAnswers = choice?.total_responses;

        return (
          <Flex key={choice.id} vertical gap={8}>
            <Typography.Title level={5}>{choice?.text}</Typography.Title>
            <Flex align="center" justify="center" gap={24}>
              <Progress
                percent={getPercentage(totalAnswers, totalResponses)}
                css={cssStyles.progress}
                strokeColor={token.colorPrimary}
              />
              <Tag color="blue" css={cssStyles.tag}>
                {getPercentage(totalAnswers, totalResponses)}%
              </Tag>
              <Space size={4}>{totalAnswers} voturi</Space>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
