import { useState } from "react";
import Chart from "react-apexcharts";
import { Card, Flex, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { TableOrderingSelect } from "components";
import { chartYearOptions } from "features/statistics/utils";
import { useChart } from "hooks";
import { stringifyAndParseQueryParams } from "utils/queryParams";

const chartOptions = {
  plotOptions: {
    bar: {
      columnWidth: "10%"
    }
  },
  stroke: {
    show: false
  },
  xaxis: {
    categories: [
      "Ian",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Iun",
      "Iul",
      "Aug",
      "Sep",
      "Oct",
      "Noi",
      "Dec"
    ]
  }
};

export const ProjectChart = () => {
  const [selectedYear, setSelectedYear] = useState(chartYearOptions[0].value);

  const queryParams = {
    year: selectedYear
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data } = useQuery({
    ...queries.projects.getChart(stringifiedParams)
  });

  const seriesData = data?.map((item) => item?.total) || [];

  const series = [
    {
      name: "",
      data: seriesData
    }
  ];

  const chart = useChart({
    ...chartOptions,
    tooltip: {
      x: {
        show: true,
        formatter: (value: number) => `${value} ${selectedYear}`
      },
      y: {
        formatter: (value: number) => `${value}`
      }
    }
  });

  return (
    <Card
      id="statistic-projects"
      styles={{
        body: {
          padding: 48
        }
      }}
    >
      <Flex gap={32} vertical>
        <Flex justify="space-between" align="center">
          <Typography.Title level={4}>
            Numărul de proiecte depuse
          </Typography.Title>
          <TableOrderingSelect
            defaultValue={chartYearOptions[0]}
            options={chartYearOptions}
            onChange={(value) => setSelectedYear(value)}
          />
        </Flex>
        <Chart type="bar" series={series} options={chart} height={320} />
      </Flex>
    </Card>
  );
};
