import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Dropdown,
  DropDownProps,
  Input,
  MenuProps,
  Radio,
  Row,
  Space,
  Typography
} from "antd";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api, { queries } from "api";
import { DEFAULT_SIZE_PAGINATION } from "app-constants";
import { Add } from "assets/svg";
import { MediaPageTable } from "features/media/components";
import { filterByStatus, Filters, FilterState, showErrorMessage } from "utils";

/**
 * MediaPage is used to list the media files and to be able
 * to filter by status, search term and media type
 *
 */
const mediaTypes = {
  all: "All",
  image: "Image",
  audio: "Audio",
  video: "Video",
  text: "Text",
  attachment: "Attachment",
  application: "Application"
};

const defaultMediaType = Object.keys(mediaTypes)[0];
const defaultStatus = filterByStatus[0].value;

const sortItems: MenuProps["items"] = Object.entries(mediaTypes).map(
  ([key, label]) => ({ key, label })
);

export const MediaPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search);

  // Handle Change Filters
  const handleChangeFilters = useCallback(
    (type: keyof FilterState, value: string | number) => {
      // Reset pageNumber on filtering
      const pageNumber = type === Filters.PAGE ? value : 1;
      const newFilters = {
        ...params,
        ...(!!value && { [type]: value }),
        page: pageNumber
      };

      if (value === defaultMediaType) {
        delete newFilters[type];
      }

      const url = queryString.stringifyUrl({
        url: "/media",
        query: newFilters
      });

      navigate(url);
    },
    [params, navigate]
  );

  // Fetch media list
  const {
    data: media,
    refetch: refetchMediaList,
    isLoading
  } = useQuery({
    ...queries.media.search(params),
    queryFn: () => {
      const { searchTerm, type, status, page } = params;

      const newParams = queryString.stringify({
        search: searchTerm !== "" ? searchTerm : undefined,
        content_type:
          type !== defaultMediaType &&
          Object.keys(mediaTypes).includes(type as string)
            ? type
            : undefined,
        status: status !== defaultStatus ? status : undefined,
        page,
        ordering: "-modified_at"
      });

      return api.media.search(newParams);
    },
    onError: (error) => showErrorMessage(error)
  });

  const paginationConfig = {
    current: Number(params.page) || 1,
    total: media?.count || 0,
    pageSize: DEFAULT_SIZE_PAGINATION,
    onChange: (value: number) => handleChangeFilters(Filters.PAGE, value),
    showSizeChanger: false
  };

  const dropDownProps: DropDownProps = {
    menu: {
      items: sortItems,
      onClick: (item) => {
        handleChangeFilters(Filters.SORT, item.key);
      }
    },
    trigger: ["click"],
    placement: "bottomRight"
  };

  const menuItem =
    mediaTypes[
      !!params?.type && Object.keys(mediaTypes).includes(params?.type as string)
        ? (params?.type as string)
        : defaultMediaType
    ];

  return (
    <>
      <Row align="middle" justify="space-between" className="mb-20">
        <Col xs={24} lg={8}>
          <div className="d-flex align-items-center gap-8">
            <Typography.Title level={5} className="text-nowrap">
              {`Media (${media?.count || 0})`}
            </Typography.Title>
            <Input.Search
              allowClear
              className="mw-300"
              placeholder="Search"
              size="large"
              onChange={(e) =>
                handleChangeFilters(Filters.SEARCH, e.target.value)
              }
            />
          </div>
        </Col>
        <Col>
          <Space>
            <Radio.Group
              defaultValue={(params.status as string) || defaultStatus}
              options={filterByStatus}
              optionType="button"
              buttonStyle="solid"
              size="large"
              onChange={(value) =>
                handleChangeFilters(Filters.FILTER, value.target.value)
              }
            />

            <Dropdown.Button size="large" {...dropDownProps}>
              {menuItem}
            </Dropdown.Button>

            <Button
              type="primary"
              size="large"
              icon={<Add />}
              onClick={() =>
                navigate("/media-form/create", {
                  state: { background: location }
                })
              }
            >
              Add media
            </Button>
          </Space>
        </Col>
      </Row>

      <MediaPageTable
        loading={isLoading}
        mediaList={media?.results || []}
        fetchMediaList={refetchMediaList}
        paginationConfig={paginationConfig}
      />
    </>
  );
};
