import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  List,
  message,
  Modal,
  ModalProps,
  Pagination,
  Popconfirm,
  Spin,
  Typography
} from "antd";
import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import { Edit } from "assets/svg";
import {
  MultiLanguageForm,
  SharedFormItemProps
} from "components/MultiLanguageForm";
import { ProjectFields } from "types";
import { showErrorMessage, useTablePagination } from "utils";
import { styles } from "./styles";

const MAX_PER_PAGE = 8;

const formItems: SharedFormItemProps[] = [
  {
    name: "name",
    label: "Nume domeniu",
    rules: [{ required: true }],
    component: <Input />,
    shared: false
  }
];

export const DomainModal = ({ open, onCancel }: ModalProps) => {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const [showCreateInput, setShowCreateInput] = useState(false);
  const [editingDomain, setEditingDomain] = useState<ProjectFields | null>(
    null
  );

  const pagination = useTablePagination();
  const queryClient = useQueryClient();
  const cssStyles = styles();

  const queryParams = {
    page: pagination?.current,
    per_page: MAX_PER_PAGE
  };

  const { data, isLoading } = useQuery({
    ...queries.projectDomains.all(queryParams)
  });

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (id: number) => api.projectDomains.deleteDomain(id),
    onSuccess: () => {
      message.success("Domeniul a fost șters cu succes!");
      queryClient.invalidateQueries(queries.projectDomains.all());
    },
    onError: () => {
      message.error(
        "Domeniul nu poate fi șters deoarece este legat de un proiect."
      );
    }
  });

  const { mutate: createMutation } = useMutation({
    mutationFn: (fields: ProjectFields) => api.projectDomains.create(fields),
    onSuccess: () => {
      message.success("Domeniul a fost creat cu succes!");
      queryClient.invalidateQueries(queries.projectDomains.all());
    },
    onError: showErrorMessage
  });

  const { mutate: updateMutation } = useMutation({
    mutationFn: (field: ProjectFields) =>
      api.projectDomains.update(Number(editingDomain?.id), field),
    onSuccess: () => {
      message.success("Domeniul a fost actualizat cu succes!");
      queryClient.invalidateQueries(queries.projectDomains.all());
      setEditingDomain(null); // Close the edit form
    },
    onError: showErrorMessage
  });

  const onAddDomain = async () => {
    try {
      const values = await form.validateFields();
      createMutation({
        name: values.name_ro,
        ...values
      });
      setShowCreateInput(false);
      form.resetFields();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const onEditClick = (domain: ProjectFields) => {
    setEditingDomain(domain);
  };

  const onEditDomain = async () => {
    try {
      const values = await editForm.validateFields();
      updateMutation({
        name: values.name_ro,
        ...values
      });
      editForm.resetFields();
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    if (editingDomain) {
      editForm.setFieldsValue(editingDomain);
    } else {
      editForm.resetFields();
    }
  }, [editingDomain, editForm]);

  return (
    <Modal
      destroyOnClose
      centered
      open={open}
      onCancel={onCancel}
      footer={
        <Pagination
          {...pagination}
          pageSize={MAX_PER_PAGE}
          showSizeChanger={false}
          total={data?.count}
        />
      }
      styles={{
        body: {
          height: 500
        }
      }}
    >
      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin />
        </Flex>
      ) : (
        <Flex vertical css={cssStyles.wrapper}>
          <Flex justify="space-between" align="center">
            <Typography.Text
              strong
              style={{
                fontSize: 18
              }}
            >
              Domenii
            </Typography.Text>
            <Button
              type="primary"
              size="middle"
              onClick={() => setShowCreateInput(true)}
            >
              <Flex align="center" gap={4}>
                <PlusCircleFilled /> Adaugă un domeniu nou
              </Flex>
            </Button>
          </Flex>

          {showCreateInput && (
            <>
              <MultiLanguageForm form={form} formItems={formItems} />
              <Flex gap={4} justify="flex-end">
                <Button type="primary" htmlType="submit" onClick={onAddDomain}>
                  Adaugă
                </Button>
                <Button onClick={() => setShowCreateInput(false)}>
                  Anulează
                </Button>
              </Flex>
            </>
          )}

          <List
            dataSource={data?.results}
            renderItem={(item) => (
              <Card css={cssStyles.card}>
                <Flex justify="space-between" align="center">
                  {item.name}
                  <Flex gap={8}>
                    <Button
                      type="link"
                      onClick={() => onEditClick(item)}
                      css={cssStyles.editButton}
                    >
                      <Edit />
                    </Button>

                    <Popconfirm
                      title="Sigur doriți să ștergeți acest domeniu?"
                      onConfirm={() => deleteMutation(item.id)}
                    >
                      <DeleteOutlined css={cssStyles.trashIcon} />
                    </Popconfirm>
                  </Flex>
                </Flex>
              </Card>
            )}
          />
        </Flex>
      )}

      {editingDomain && (
        <Modal
          title="Editează domeniul"
          open={!!editingDomain?.id}
          onCancel={() => setEditingDomain(null)}
          footer={null}
          forceRender
        >
          <MultiLanguageForm
            form={editForm}
            formItems={formItems}
            initialValues={editingDomain}
          />

          <Flex gap={4} justify="flex-end">
            <Button type="primary" htmlType="submit" onClick={onEditDomain}>
              Actualizează
            </Button>
            <Button
              onClick={() => {
                editForm.resetFields();
                setEditingDomain(null);
              }}
            >
              Anulează
            </Button>
          </Flex>
        </Modal>
      )}
    </Modal>
  );
};
