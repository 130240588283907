import { Key } from "react";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import {
  CreateVotePayload,
  Paginated,
  VotingEntity,
  VotingResultsEntity
} from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<VotingEntity[]>>(
    "/cms/voting",
    {
      params: queryParams
    }
  );

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<VotingEntity>(`/cms/voting/${id}`);

  return data;
};

const getResultsById = async (id: number) => {
  const { data } = await ssoClient.get<VotingResultsEntity>(
    `/cms/voting/${id}/results`
  );

  return data;
};

const update = async (id: number, voting: CreateVotePayload) => {
  const { data } = await ssoClient.patch(`/cms/voting/${id}`, voting);

  return data;
};

const deleteVoting = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/voting/${id}`);

  return data;
};

const create = async (votingData: CreateVotePayload) => {
  const { data } = await ssoClient.post(`/cms/voting`, votingData);

  return data;
};

const bulkDelete = async (id: Key[]) => {
  const { data } = await ssoClient.delete(`/cms/voting/bulk-delete`, {
    data: { ids: id }
  });

  return data;
};

const voting = {
  getAll,
  getById,
  create,
  update,
  deleteVoting,
  bulkDelete
};

export const votingQueries = createQueryKeys("voting", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  resultsById: (id) => ({
    queryKey: [id],
    queryFn: () => getResultsById(id)
  })
});

export default voting;
