import { useNavigate } from "react-router-dom";
import { Avatar, Flex } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getLocalStorageTokens,
  queries,
  removeLocalStorageTokens,
  setLocalStorageTokens
} from "api";
import authorization from "api/authorization";
import { getUserInitials } from "utils";

export const UserProfile = () => {
  const navigate = useNavigate();

  const tokens = getLocalStorageTokens();

  const logoutMutate = () => {
    removeLocalStorageTokens();
    navigate("/authentication/login");
  };

  const { data } = useQuery({
    ...queries.profile.profile(),
    retry: false,
    onError: () => {
      tokens.refresh ? mutate({ refresh: tokens?.refresh }) : logoutMutate();
    }
  });

  const { mutate } = useMutation({
    mutationFn: authorization.tokenRefresh,
    onSuccess: (data) => {
      setLocalStorageTokens(data?.access ?? "", data?.refresh ?? "");
      navigate(0);
    },
    onError: () => {
      logoutMutate();
    }
  });

  return (
    <Flex gap={8} align="center">
      <Avatar src={data?.photo?.url} style={{ backgroundColor: "#171cb3" }}>
        {getUserInitials(data?.first_name, data?.last_name)}
      </Avatar>
      {data?.first_name} {data?.last_name}
    </Flex>
  );
};
