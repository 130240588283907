const IdeaIcon = () => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-6.10352e-05 16C-6.10352e-05 7.16344 7.16338 0 15.9999 0H39.9999C48.8365 0 55.9999 7.16344 55.9999 16V40C55.9999 48.8366 48.8365 56 39.9999 56H15.9999C7.16338 56 -6.10352e-05 48.8366 -6.10352e-05 40V16Z"
        fill="#FFFDDC"
      />
      <path
        d="M35.2099 22.36C34.1699 20.26 32.1599 18.71 29.8299 18.2C27.3899 17.66 24.8899 18.24 22.9799 19.78C21.0599 21.31 19.9699 23.6 19.9699 26.05C19.9699 28.64 21.5199 31.35 23.8599 32.92V33.75C23.8499 34.03 23.8399 34.46 24.1799 34.81C24.5299 35.17 25.0499 35.21 25.4599 35.21H30.5899C31.1299 35.21 31.5399 35.06 31.8199 34.78C32.1999 34.39 32.1899 33.89 32.1799 33.62V32.92C35.2799 30.83 37.2299 26.42 35.2099 22.36Z"
        fill="#FCBF04"
      />
      <path
        d="M31.2599 38C31.1999 38 31.1299 37.99 31.0699 37.97C29.0599 37.4 26.9499 37.4 24.9399 37.97C24.5699 38.07 24.1799 37.86 24.0799 37.49C23.9699 37.12 24.1899 36.73 24.5599 36.63C26.8199 35.99 29.1999 35.99 31.4599 36.63C31.8299 36.74 32.0499 37.12 31.9399 37.49C31.8399 37.8 31.5599 38 31.2599 38Z"
        fill="#FCBF04"
      />
    </svg>
  );
};

export default IdeaIcon;
