import { SelectProps } from "antd";
import { NotificationStatus, NotificationType } from "types";

export const sortDirectionOptions: SelectProps["options"] = [
  { label: "Ascendent", value: "id" },
  { label: "Descendent", value: "-id" }
];

export const typeDisplayText = {
  [NotificationType.ALERT]: "Alerte",
  [NotificationType.EVENT]: "Evenimente"
};

export const statusDisplayText = {
  [NotificationStatus.FAILED]: "Eșuate",
  [NotificationStatus.PENDING]: "În așteptare",
  [NotificationStatus.SENT]: "Trimise"
};

export const sortByStatusOptions = [
  { label: "Statut: Toate", value: "" },
  ...Object.entries(statusDisplayText).map(([value, label]) => ({
    label,
    value
  }))
];

export const sortByTypeOptions = [
  { label: "Tip: Toate", value: "" },
  ...Object.entries(typeDisplayText).map(([type, label]) => ({
    label: `Tip: ${label}`,
    value: type
  }))
];
