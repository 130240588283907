import { Key, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Input, message, Row, Table, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import api, { queries } from "api";
import { Add } from "assets/svg";
import {
  DeleteButton,
  DeleteConfirmModal,
  TableOrderingSelect
} from "components";
import { LoadingScreen } from "components/LoadingScreen";
import { sortProjectDirectionOptions } from "features/projects/utils";
import { VotingEntity } from "types";
import { resetPagination, showErrorMessage, useTablePagination } from "utils";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { getVotingTableColumns } from "../components";

export const VotingPage = () => {
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useState("id");

  const navigate = useNavigate();
  const pagination = useTablePagination();
  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const queryParams = {
    ordering,
    page: pagination.current,
    per_page: pagination.pageSize,
    search
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.voting.all(stringifiedParams)
  });

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const { mutate: deleteOne } = useMutation({
    mutationFn: (id: number) => api.voting.deleteVoting(id),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.voting.all());
    },
    onError: showErrorMessage
  });

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: (ids: Key[]) => api.voting.bulkDelete(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.voting.all());
      setSelectedRowKeys([]);
    },
    onError: showErrorMessage
  });

  const onSelectRowChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange
  };

  const onBulkDelete = () => {
    const ids = rowSelection?.selectedRowKeys.map((key) => key);

    const onOk = () => bulkDelete(ids);
    DeleteConfirmModal(onOk, true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const getActionColumnMenu = (context: VotingEntity): MenuProps["items"] => [
    {
      key: "edit",
      label: "Editează",
      onClick: () => {
        navigate(`/voting/edit/${context.id}`);
      }
    },
    {
      key: "delete",
      label: "Șterge",
      onClick: () => {
        const onOk = () => deleteOne(context.id);
        DeleteConfirmModal(onOk);
      }
    }
  ];

  const currentDate = dayjs();
  const activeVotingExists = data?.results?.some(
    (voting) =>
      currentDate.isAfter(voting.start_date) &&
      currentDate.isBefore(voting.end_date)
  );

  const onCreateClick = () => {
    if (activeVotingExists) {
      message.error(
        "Există o votare activă. Închide votarea activă pentru a crea una nouă."
      );
      return;
    }

    navigate(`/voting/add`);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Flex gap={4} align="baseline">
          <Typography.Title level={2}>Management votări</Typography.Title>
          <Typography.Title level={5}>({data?.count ?? 0})</Typography.Title>
        </Flex>

        <Button
          id="add-voting-button"
          type="primary"
          size="large"
          icon={<Add />}
          onClick={onCreateClick}
        >
          Creează votare
        </Button>
      </Row>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          className="mw-300"
          placeholder="Caută votare..."
          size="large"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex gap={8} align="center" justify="center">
          {hasSelected && (
            <DeleteButton
              loading={isBulkDeleteLoading}
              onClick={onBulkDelete}
            />
          )}
          <TableOrderingSelect
            defaultValue={sortProjectDirectionOptions?.[0]?.value}
            options={sortProjectDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />
        </Flex>
      </Row>

      <Table
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={getVotingTableColumns(getActionColumnMenu)}
        dataSource={data?.results ?? []}
        bordered
        pagination={{ ...pagination, total: data?.count }}
      />
    </>
  );
};
