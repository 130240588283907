import { Link } from "react-router-dom";
import { Button, Dropdown, Flex, Tag, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import { getActiveVoting } from "features/polls/utils";
import { PollEntity } from "types";
import { compareDates, DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

dayjs.extend(isBetween);

const getPollStatusTag = (startDate, endDate) => {
  const isActivePoll = getActiveVoting(startDate, endDate);

  const status = isActivePoll ? "Activ" : "Inactiv";
  return <Tag color={isActivePoll ? "green" : "red"}>{status}</Tag>;
};

const getTitleColor = (startDate, endDate) => {
  const isActivePoll = getActiveVoting(startDate, endDate);

  return {
    style: {
      color: isActivePoll ? "black" : "#9CA3AF"
    },
    strong: isActivePoll ?? false
  };
};

export const getTableColumns = (
  getActionColumnMenu: (content: PollEntity) => MenuProps["items"],
  onSwitch: (content: PollEntity) => void
) => {
  const columns: ColumnProps<PollEntity>[] = [
    {
      title: "ID",
      key: "id",
      render: ({ id, start_date, end_date }) => (
        <Typography.Text {...getTitleColor(start_date, end_date)}>
          {id}
        </Typography.Text>
      ),
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Titlu",
      key: "title",
      render: ({ id, title }) => (
        <Link to={`/polls/details/${id}`}>{title}</Link>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: "Întrebări",
      key: "total_questions",
      render: ({ total_questions, start_date, end_date }) => (
        <Typography.Text {...getTitleColor(start_date, end_date)}>
          {total_questions}
        </Typography.Text>
      ),
      sorter: (a, b) => a.total_questions - b.total_questions
    },
    {
      title: "Data de inceput",
      key: "start_date",
      width: 200,
      render: ({ start_date, end_date }) => (
        <Typography.Text {...getTitleColor(start_date, end_date)}>
          {start_date
            ? moment(start_date).format(DAY_MONTH_YEAR_HOUR_MINUTE)
            : "-"}
        </Typography.Text>
      ),
      sorter: (a, b) => compareDates(dayjs(a.start_date), dayjs(b.start_date))
    },
    {
      title: "Data de sfarsit",
      key: "end_date",
      width: 200,
      render: ({ end_date, start_date }) => (
        <Typography.Text {...getTitleColor(start_date, end_date)}>
          {end_date ? moment(end_date).format(DAY_MONTH_YEAR_HOUR_MINUTE) : "-"}
        </Typography.Text>
      ),
      sorter: (a, b) => compareDates(dayjs(a.end_date), dayjs(b.end_date))
    },
    {
      title: "Ultima actualizare",
      key: "updated_at",
      render: ({ end_date, start_date, updated_at }) => (
        <Typography.Text {...getTitleColor(start_date, end_date)}>
          {updated_at
            ? moment(updated_at).format(DAY_MONTH_YEAR_HOUR_MINUTE)
            : "-"}
        </Typography.Text>
      ),
      sorter: (a, b) => compareDates(dayjs(a.updated_at), dayjs(b.updated_at))
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      key: "actions",
      render: (_, item) => (
        <Flex align="center" justify="right" gap={8}>
          {getPollStatusTag(item?.start_date, item?.end_date)}
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            menu={{ items: getActionColumnMenu(item) }}
          >
            <Button icon={<EllipsisOutlined />} type="text" />
          </Dropdown>
        </Flex>
      )
    }
  ];

  return columns;
};
