import { ColumnProps } from "antd/es/table";
import purify from "dompurify";

import moment from "moment";
import {
  statusDisplayText,
  typeDisplayText
} from "features/notifications/utils";

import { NotificationEntity } from "types";
import { DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

export const getTableColumns = () => {
  const columns: ColumnProps<NotificationEntity>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Titlu",
      key: "subject",
      dataIndex: "subject"
    },
    {
      title: "Conținut",
      key: "body",
      render: ({ body }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(body).slice(0, 100)
          }}
        />
      )
    },
    {
      title: "Tip",
      width: 150,
      key: "type",
      render: ({ type }) => typeDisplayText[type],
      sorter: (a, b) => a.type.localeCompare(b.type)
    },
    {
      title: "Statut",
      key: "status",
      width: 150,
      render: ({ status }) => statusDisplayText[status],
      sorter: (a, b) => a.status.localeCompare(b.status)
    },
    {
      title: "Data trimiterii",
      key: "created_at",
      width: 200,
      render: ({ sent_at, publish_at }) =>
        publish_at
          ? moment(publish_at).format(DAY_MONTH_YEAR_HOUR_MINUTE)
          : moment(sent_at || undefined).format(DAY_MONTH_YEAR_HOUR_MINUTE)
    }
  ];

  return columns;
};
