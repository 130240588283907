import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Flex,
  message,
  Popconfirm,
  Progress,
  theme,
  Typography
} from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import api from "api";
import { statusDisplayText } from "features/projects/utils";
import { getPercentage } from "features/voting/utils";
import { ProjectEntity, ProjectStatus } from "types";
import { showErrorMessage } from "utils";
import { MONTH_YEAR } from "utils/dateFormats";
import { styles } from "./styles";

type Props = {
  data?: ProjectEntity;
  isDraft: boolean;
};

const { Title, Text } = Typography;

export const ExtraContent = ({ isDraft, data }: Props) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const cssStyles = styles(data?.status);

  const { mutate } = useMutation({
    mutationFn: () => api.projects.deleteProject(Number(data?.id)),
    onSuccess: () => {
      message.success("Proiectul a fost șters cu succes");
      navigate("/projects");
    },
    onError: showErrorMessage
  });

  return (
    <Flex
      gap={4}
      vertical
      style={{
        width: "30%"
      }}
    >
      {isDraft ? (
        <Card styles={cssStyles.card}>
          <Flex gap={8}>
            <Button
              type="primary"
              size="middle"
              onClick={() => navigate(`/projects/edit/${data?.id}`)}
            >
              <Flex align="center" gap={8}>
                <PlusCircleOutlined />
                Modifică proiectul
              </Flex>
            </Button>
            <Popconfirm
              title="Ești sigur că vrei să ștergi acest proiect?"
              okText="Da"
              onConfirm={() => mutate()}
              placement="bottom"
              cancelText="Nu"
            >
              <Button type="default" danger size="middle">
                <Flex align="center" gap={8}>
                  <MinusCircleOutlined />
                  Sterge acest proiect
                </Flex>
              </Button>
            </Popconfirm>
          </Flex>
        </Card>
      ) : (
        <>
          <Card styles={cssStyles.card}>
            <Flex vertical gap={10}>
              <Flex gap={10} css={cssStyles.tag} align="center">
                <div css={cssStyles.dot} />
                <Text>
                  Proiect{" "}
                  {statusDisplayText[data?.status ?? ProjectStatus.DRAFT]}
                </Text>
              </Flex>

              {data?.start_date && (
                <>
                  <Title
                    level={5}
                    style={{
                      color: "#9CA3AF"
                    }}
                  >
                    Perioada proiectului
                  </Title>
                  <Text css={cssStyles.font14}>
                    {dayjs(data?.start_date).format(MONTH_YEAR)} -{" "}
                    {dayjs(data?.end_date).format(MONTH_YEAR)}
                  </Text>
                </>
              )}
              <Divider
                style={{
                  margin: 0
                }}
              />

              <Title level={5}>
                {data?.total_votes} / {data?.voting_total_votes} voturi
              </Title>
              <Progress
                percent={getPercentage(
                  data?.total_votes,
                  data?.voting_total_votes
                )}
                css={cssStyles.progress}
                strokeColor={token.colorPrimary}
              />
            </Flex>
          </Card>
          <Card styles={cssStyles.card}>
            <Flex vertical gap={8}>
              <Title level={5} css={cssStyles.font18}>
                Buget:
              </Title>
              <Title level={5}>{data?.budget} MDL</Title>
            </Flex>
          </Card>
          {data?.financial_resources?.map((resource) => (
            <Card styles={cssStyles.card} key={resource?.name}>
              <Flex vertical gap={8}>
                <Title level={5}>{resource?.name}</Title>
                <Title level={5}>{resource?.amount} MDL</Title>
              </Flex>
            </Card>
          ))}
          {data?.organization && (
            <Card styles={cssStyles.card}>
              <Flex vertical gap={8}>
                <Title level={5} css={cssStyles.font18}>
                  Organizatia:
                </Title>
                <Title level={5}>{data?.organization}</Title>
              </Flex>
            </Card>
          )}
        </>
      )}
    </Flex>
  );
};
