import { Input, theme } from "antd";
import { OTPProps } from "antd/es/input/OTP";

import { styles } from "./styles";

interface OTPInputProps extends OTPProps {}

export const OTPInput = ({ ...props }: OTPInputProps) => {
  const { token } = theme.useToken();

  const cssStyle = styles(token);

  return (
    <div css={cssStyle.otpInput}>
      <Input.OTP size="large" length={4} {...props} />
    </div>
  );
};
