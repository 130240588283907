import { Modal } from "antd";

const { confirm } = Modal;

export const DeleteConfirmModal = (onOk: () => void, multiple?: boolean) => {
  const multipleText = multiple ? "aceste inscrieri" : "aceasta inscriere";

  confirm({
    title: `Stergere ${multipleText}?`,
    content: `Sunteti sigur ca doriti sa stergeti ${multipleText}?`,
    okText: `Da, stergeti ${multipleText}`,
    okType: "danger",
    centered: true,
    cancelText: "Anulare",
    okButtonProps: {
      className: "btn"
    },
    cancelButtonProps: {
      className: "btn btn-default"
    },
    onOk
  });
};
