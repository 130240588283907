import { Key } from "react";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { IdeaEntity, Paginated } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<IdeaEntity[]>>("/cms/ideas", {
    params: queryParams
  });

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<IdeaEntity>(`/cms/ideas/${id}`);

  return data;
};

const getStats = async () => {
  const { data } = await ssoClient.get(`/cms/ideas/stats`);

  return data;
};

const update = async (id: number, idea: IdeaEntity) => {
  const { data } = await ssoClient.patch(`/cms/ideas/${id}`, idea);

  return data;
};

const deleteIdea = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/ideas/${id}`);

  return data;
};

const bulkDelete = async (id: Key[]) => {
  const { data } = await ssoClient.delete(`/cms/ideas/bulk-delete`, {
    data: { ids: id }
  });

  return data;
};

const ideas = {
  getAll,
  getById,
  getStats,
  update,
  deleteIdea,
  bulkDelete
};

export const ideasQueries = createQueryKeys("ideas", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  getStats: () => ({
    queryKey: ["stats"],
    queryFn: getStats
  })
});

export default ideas;
