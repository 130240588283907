import { Button, Flex, Form, Input } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { theme } from "antd/lib";

type Props = {
  langKey: string;
};

export const FinancialResources = ({ langKey }: Props) => {
  const { token } = theme.useToken();

  return (
    <Form.List name="financial_resources">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields?.length < 2 && (
              <Flex justify="end" align="center">
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => add()}
                    block
                    icon={<PlusCircleOutlined />}
                    style={{
                      color: token.colorPrimary
                    }}
                  >
                    Adaugă o resursă financiară
                  </Button>
                </Form.Item>
              </Flex>
            )}

            {fields.map(({ key, name, ...restField }) => (
              <Flex vertical key={key}>
                <Form.Item
                  {...restField}
                  name={[name, `name_${langKey}`]}
                  label={`Resurse ${langKey}`}
                  rules={[
                    {
                      required: true,
                      message: "Titlu e obligatoriu"
                    }
                  ]}
                >
                  <Input placeholder="Titlu" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Suma"
                  name={[name, "amount"]}
                  rules={[
                    {
                      required: true,
                      message: "Suma"
                    }
                  ]}
                >
                  <Input placeholder="Suma" />
                </Form.Item>

                {fields.length > 1 && (
                  <Button type="text" onClick={() => remove(name)}>
                    <MinusCircleOutlined style={{ color: "red" }} />
                    Sterge resursa
                  </Button>
                )}
              </Flex>
            ))}
          </>
        );
      }}
    </Form.List>
  );
};
