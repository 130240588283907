import React, { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { Button, MenuProps, Result, Space, Typography } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import api, { queries } from "api";
import { Add } from "assets/svg";
import { LoadingScreen } from "components/LoadingScreen";
import {
  LocalesPageTable,
  LocalesPageModal
} from "features/locales/components";
import { TableLocales } from "features/locales/utils";
import { UserRole } from "types";
import { showErrorMessage } from "utils";

import { handleErrors } from "./utils";

interface LocalesModalState {
  visible: boolean;
  locale?: TableLocales | null;
}

/**
 * LocalesPage is used to show, edit and create locales
 */
const LocalesPage = () => {
  // States for the modal locales
  const [modalState, setModalState] = useState<LocalesModalState>({
    visible: false
  });

  // Fetch locales
  const {
    data,
    refetch: refetchLocales,
    isLoading
  } = useQuery({
    ...queries.locales.all(),
    onError: (error) => showErrorMessage(error)
  });

  // Add locale modal handlers
  const handleAddMediaClick = () => setModalState({ visible: true });
  const handleCancel = () => setModalState({ visible: false, locale: null });

  // Reset modal state and fetch the locales
  const updateLocalesState = () => {
    refetchLocales();
    setModalState({ visible: false, locale: null });
  };

  const updateMutation = useMutation(
    ({ localeId, data }: { localeId?: string; data }) =>
      localeId ? api.locales.update(localeId, data) : api.locales.create(data),
    {
      onSuccess: () => updateLocalesState(),
      onError: (error) => handleErrors(error)
    }
  );

  const deleteMutation = useMutation(api.locales.remove, {
    onSuccess: () => updateLocalesState(),
    onError: (error) => showErrorMessage(error)
  });

  const handleOk = useCallback(
    async (value: string) => {
      const isEdit = modalState.locale;

      updateMutation.mutate({
        localeId: isEdit ? modalState.locale!.id : undefined,
        data: { value }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalState.locale]
  );

  // Table columns
  const getActionColumnMenu: (locale: TableLocales) => MenuProps["items"] = (
    locale
  ) => [
    {
      label: "Edit",
      key: "edit",
      onClick: () => {
        setModalState({ visible: true, locale });
      }
    },
    {
      label: "Delete",
      key: "delete",
      onClick: () => {
        deleteMutation.mutate(locale.id);
      }
    }
  ];

  // Set default value for the select on editing, undefined is for placeholder
  const defaultValue = modalState.locale ? modalState.locale.value : undefined;

  const { data: profileInfo, isLoading: profileLoading } = useQuery({
    ...queries.profile.profile()
  });

  if (profileLoading) {
    return <LoadingScreen />;
  }

  if (profileInfo?.role !== UserRole.SUPERUSER) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Nu aveți permisiunea de a accesa această pagină."
        extra={
          <Link to="/statistics">
            <Button type="primary">Inapoi</Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      <Space className="d-flex justify-content-between mb-20">
        <Typography.Title level={5} className="mb-0">
          Locales {`(${data?.count || 0})`}
        </Typography.Title>
        <Button
          type="primary"
          icon={<Add />}
          onClick={handleAddMediaClick}
          size="large"
        >
          Add locale
        </Button>
      </Space>

      <LocalesPageTable
        loading={
          isLoading || updateMutation.isLoading || deleteMutation.isLoading
        }
        locales={data?.results || []}
        columns={getActionColumnMenu}
      />
      <LocalesPageModal
        visible={modalState.visible}
        defaultValue={defaultValue}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default LocalesPage;
