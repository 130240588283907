import { IdeaIcon, ProjectIcon, UserIcon } from "assets/svg";

export const StatisticCardInfo = {
  projects: {
    id: "projects",
    title: "Numărul total de proiecte",
    icon: <ProjectIcon />
  },
  users: {
    id: "statistic-users",
    title: "Numărul de cetățeni activi",
    icon: <UserIcon />
  },
  ideas: {
    id: "ideas",
    title: "Numărul total de idei",
    icon: <IdeaIcon />
  }
};

export const chartYearOptions = [
  {
    label: "2024",
    value: "2024"
  },
  {
    label: "2025",
    value: "2025"
  }
];
