import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Modal, Spin, Tooltip } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { MODAL_WIDTH } from "app-constants";
import { FormMediaUpload } from "components/FormBuilder";
import { MediaData } from "types";
import { showErrorMessage } from "utils";

const formEditId = "media-form-edit";
const formCreateId = "media-form-edit";

export const MediaForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [tooltipText, setTooltipText] = useState("Copy link to clipboard");

  const {
    data: media,
    refetch: refetchMedia,
    isLoading
  } = useQuery({
    ...queries.media.byId(id),
    enabled: !!id,
    onError: (error) => showErrorMessage(error)
  });

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    (data: MediaData) => {
      return id ? api.media.update(id, data) : api.media.create(data);
    },
    {
      onSuccess: () => {
        if (id) {
          message.success("File was edited successfully!");
          navigate(-1);
          refetchMedia();
        } else {
          message.success("File was uploaded successfully!");
          navigate(`/media`);
        }

        queryClient.invalidateQueries(queries.media.search({}).queryKey);
      },
      onError: (error) => showErrorMessage(error)
    }
  );

  const setUrlInClipboard = useCallback(() => {
    if (media?.instance?.url) {
      navigator.clipboard.writeText(media?.instance?.url);
      setTooltipText("Copied");
      setTimeout(() => setTooltipText("Copy link to clipboard"), 2000);
    }
  }, [media?.instance?.url]);

  return (
    <Modal
      open
      {...(id && { confirmLoading: isLoadingMutation })}
      title={id ? `Edit ${media?.instance?.name || ""}` : "Create new media"}
      onCancel={() => (id ? navigate(-1) : navigate("/media"))}
      okButtonProps={{
        form: id ? formEditId : formCreateId,
        htmlType: "submit",
        ...(!id && { loading: isLoadingMutation })
      }}
      okText={id ? "Edit" : "Create"}
      width={MODAL_WIDTH}
    >
      {id ? (
        <>
          <Spin spinning={isLoading}>
            <FormMediaUpload
              formId={formEditId}
              formData={media?.instance}
              onSubmit={(data: MediaData) => mutate(data)}
            />
          </Spin>
          <Tooltip title={tooltipText}>
            <span className="fw-500">Copy link: </span>
            <label style={{ cursor: "pointer" }} onClick={setUrlInClipboard}>
              {media?.instance?.url}
            </label>
          </Tooltip>
        </>
      ) : (
        <FormMediaUpload
          formId={formCreateId}
          onSubmit={(data: MediaData) => mutate(data)}
        />
      )}
    </Modal>
  );
};
