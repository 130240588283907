import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  message,
  Space,
  DatePickerProps,
  Typography
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Dayjs } from "dayjs";
import api, { queries } from "api";
import { ContentPublishDate } from "features/content/components";
import { styles } from "features/projects/Layouts/ActiveLayout/Header/styles";
import { NotificationEntity } from "types";
import { showErrorMessage } from "utils";
import { NotificationForm } from "../components";

export const NotificationCreate = () => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const [publishDate, setPublishDate] = useState<Dayjs | null>(null);
  const [datePickerStatus, setDatePickerStatus] =
    useState<DatePickerProps["status"]>();

  const cssStyles = styles();

  const navigate = useNavigate();

  const onChangePublishDate = (date: Dayjs | null) => {
    setDatePickerStatus(undefined);
    setPublishDate(date);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: NotificationEntity) => api.notifications.create(data),
    onSuccess: () => {
      message.success("Notificarea a fost creată cu succes!");
      queryClient.invalidateQueries(queries.notifications.all());
      navigate("/notifications");
    },
    onError: showErrorMessage
  });

  const onSubmit = async () => {
    try {
      setDatePickerStatus(publishDate ? undefined : "error");
      const values = await form.validateFields();

      if (!publishDate) {
        return;
      }

      const payload = {
        subject: values?.subject_ro,
        body: values?.body_ro,
        publish_at: publishDate?.toISOString(),
        ...values
      };
      mutate(payload);
    } catch (error) {
      message.error(
        "Este necesară completarea tuturor formularelor în toate limbile"
      );
    }
  };

  return (
    <>
      <Card styles={cssStyles.card} css={cssStyles.card} className="mb-32">
        <Space direction="vertical">
          <ContentPublishDate
            status={datePickerStatus}
            publishDate={publishDate}
            onChange={onChangePublishDate}
          />
          {datePickerStatus && (
            <Typography.Text type="danger">
              Vă rugăm introduceți data data publicării
            </Typography.Text>
          )}
        </Space>

        <Space>
          <Button onClick={() => navigate("/notifications")} size="large">
            Anulare
          </Button>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            onClick={onSubmit}
            loading={isLoading}
          >
            Crează
          </Button>
        </Space>
      </Card>
      <NotificationForm form={form} />
    </>
  );
};
