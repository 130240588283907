import { File } from "./projects";

export enum UserRole {
  SUPERUSER = "superuser",
  EMPLOYEE = "city_hall_employee",
  CITIZEN = "citizen"
}

export type UserEntity = {
  id: number;
  first_name: string;
  last_name: string;
  address: string;
  email: string;
  //used to prevent auto fill of email field
  obscureFieldName?: string;
  phone: string;
  photo: File;
  role: UserRole;
  type: string;
  last_login: string;
  is_active: boolean;
};

export type InviteUser = {
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
};

export type PasswordData = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};
