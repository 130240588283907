import { default as actions } from "./actions";
import { default as authorization } from "./authorization";
import { default as content } from "./content";
import { default as contentType } from "./content-type";
import { default as forms } from "./forms";
import { default as ideas } from "./ideas";
import { default as locales } from "./locales";
import { default as media } from "./media";
import { default as notifications } from "./notifications";
import { default as polls } from "./polls";
import { default as profile } from "./profile";
import { default as projectDomains } from "./projectDomains";
import { default as projects } from "./projects";
import { default as settings } from "./settings";
import { default as users } from "./users";
import { default as voting } from "./voting";

const api = {
  actions,
  content,
  contentType,
  forms,
  locales,
  media,
  settings,
  authorization,
  users,
  notifications,
  projects,
  projectDomains,
  ideas,
  polls,
  voting,
  profile
};

export * from "./queries";
export * from "./axios";

export default api;
