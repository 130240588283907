import { Button, Flex, Form, Input, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const PLACEHOLDER_TEXT = "Raspuns";

export const ChoicesForm = (type: string, name: number, langKey: string) => {
  return (
    <>
      {type !== "text" && (
        <Form.Item label="Raspunsuri">
          <Form.List name={[name, "choices"]}>
            {(subFields, subOpt) => (
              <Flex vertical gap={16}>
                {subFields.map((subField) => (
                  <Flex key={subField.key} gap={8}>
                    <Form.Item
                      label="Raspuns"
                      name={[subField.name, `text_${langKey}`]}
                      rules={[
                        {
                          required: true,
                          message: "Acest camp este obligatoriu"
                        }
                      ]}
                      style={{
                        width: "100%"
                      }}
                    >
                      <Input placeholder={PLACEHOLDER_TEXT} />
                    </Form.Item>
                    <Space
                      style={{
                        marginTop: 10
                      }}
                    >
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name);
                        }}
                      />
                    </Space>
                  </Flex>
                ))}
                {subFields?.length <= 3 && (
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + Adauga {PLACEHOLDER_TEXT}
                  </Button>
                )}
              </Flex>
            )}
          </Form.List>
        </Form.Item>
      )}
    </>
  );
};
