import React from "react";
import { BrowserRouter } from "react-router-dom";
import { App as AntdApp, ConfigProvider } from "antd";

import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import roRO from "antd/locale/ro_RO";
import dayjs from "dayjs";
import { ErrorBoundary } from "components";
import { useApiConfig } from "hooks";
import { defaultTheme } from "theme";
import AppRouter from "./AppRouter";

require("dayjs/locale/ro");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

dayjs.locale("ro");

const App: React.FC = () => {
  const apiConfig = useApiConfig();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <AntdApp>
            <ConfigProvider theme={defaultTheme} locale={roRO}>
              <AppRouter apiConfig={apiConfig} />
            </ConfigProvider>
          </AntdApp>
        </ErrorBoundary>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
