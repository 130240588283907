import { Button, Flex, Typography } from "antd";
import { Trash } from "assets/svg";

export const DeleteButton = ({ onClick, loading, ...props }) => {
  return (
    <Button
      type="default"
      size="large"
      danger
      onClick={onClick}
      loading={loading}
      {...props}
    >
      <Flex align="center" justify="center" gap={4}>
        <Trash />
        <Typography.Text type="danger" strong>
          Șterge
        </Typography.Text>
      </Flex>
    </Button>
  );
};
