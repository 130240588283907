import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Locale, Paginated, SingleLocale } from "types";
import { cmsClient } from "./axios";

const create = async (data: Locale) => {
  const response = await cmsClient.post("/locale/", data);
  return response.data;
};

const getAll = async () => {
  const response = await cmsClient.get<Paginated<Locale[]>>("/locale/");
  return response.data;
};

const getAvailable = async () => {
  const response = await cmsClient.get<SingleLocale>("/locale/available/");
  return response.data;
};

const update = async (id: string, data: Locale) => {
  const response = await cmsClient.put(`/locale/${id}/`, data);
  return response.data;
};

const remove = async (id: string) => {
  const response = await cmsClient.delete(`/locale/${id}/`);
  return response.data;
};

const locales = {
  create,
  getAll,
  getAvailable,
  update,
  remove
};

export const localesQueries = createQueryKeys("locales", {
  all: () => ({
    queryKey: [""],
    queryFn: () => getAll()
  }),
  available: () => ({
    queryKey: [""],
    queryFn: () => getAvailable()
  })
});

export default locales;
