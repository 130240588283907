import { ApexOptions } from "apexcharts";
import { merge } from "lodash";

export const useChart = (options?: ApexOptions) => {
  const baseOptions = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: false },
      foreColor: "#9CA3AF"
    },

    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04
        }
      }
    },

    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    dataLabels: { enabled: false },

    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round"
    },

    grid: {
      borderColor: "#E5E7EB",
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false }
    },

    tooltip: {
      x: {
        show: true
      },
      marker: {
        show: true
      }
    }
  };

  return merge(baseOptions, options);
};
