import { css } from "@emotion/react";

export const styles = () => ({
  arrowButton: css`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
});
