import { css } from "@emotion/react";

export const styles = () => ({
  mapWrapper: css`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    .leaflet-control-attribution {
      display: none;
    }

    .leaflet-touch .leaflet-bar {
      border: none;
    }

    .leaflet-bar a:first-of-type {
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }

    .leaflet-bar a:last-of-type {
      border-bottom-left-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  `,
  mapContainer: css`
    height: 280px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
  `
});
