import queryString from "query-string";

export type QueryParams = {
  [key: string]: string | number | boolean | null | undefined;
};

export const stringifyAndParseQueryParams = (queryParams: QueryParams) => {
  const stringified = queryString.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true
  });

  return queryString.parse(stringified);
};
