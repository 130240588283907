import { useState } from "react";
import { Divider, Drawer, Flex, Segmented, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import purify from "dompurify";
import moment from "moment";
import { queries } from "api";
import {
  statusDisplayText,
  typeDisplayText
} from "features/notifications/utils";

import { NotificationStatus, NotificationType } from "types";
import { DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

const timeFormat = (time?: string) =>
  moment(time).format(DAY_MONTH_YEAR_HOUR_MINUTE);

const { Text } = Typography;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notification?: number;
};

export const NotificationViewDrawer = ({
  isOpen,
  onClose,
  notification
}: Props) => {
  const [selectedLang, setSelectedLang] = useState("ro");

  const onLangChange = (value) => {
    setSelectedLang(value);
  };

  const { data } = useQuery({
    ...queries.notifications.byId(notification),
    enabled: !!notification
  });

  return (
    <Drawer
      width={400}
      destroyOnClose
      open={isOpen}
      onClose={onClose}
      title={
        <Flex gap={4}>
          <Text strong>ID Notificare</Text>
          <Text strong>{data?.id}</Text>
        </Flex>
      }
    >
      <Flex gap={4}>
        <Text strong>Tip: </Text>
        <Text>{typeDisplayText[data?.type ?? NotificationType.EVENT]}</Text>
      </Flex>

      <Flex gap={4}>
        <Text strong>Statut: </Text>
        <Text>
          {statusDisplayText[data?.status ?? NotificationStatus.PENDING]}
        </Text>
      </Flex>

      <Divider />

      <Flex vertical gap={8}>
        <Segmented
          block
          options={[
            { label: "Ro", value: "ro" },
            { label: "Ru", value: "ru" },
            { label: "En", value: "en" }
          ]}
          value={selectedLang}
          onChange={onLangChange}
        />

        <Flex vertical gap={2}>
          <Text strong>Titlu</Text>
          <Text strong>{data ? data[`subject_${selectedLang}`] : ""}</Text>
        </Flex>
      </Flex>

      <Divider />

      <Flex vertical gap={2}>
        <Text strong>Mesaj notificare</Text>
        <div
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(data ? data[`body_${selectedLang}`] : "")
          }}
        />
      </Flex>

      <Divider />

      <Flex vertical gap={8}>
        <Flex justify="space-between">
          <Text strong>Data & ora expedierii</Text>
          <Text>
            {data?.publish_at
              ? timeFormat(data?.publish_at)
              : timeFormat(data?.sent_at || undefined)}
          </Text>
        </Flex>
      </Flex>
    </Drawer>
  );
};
