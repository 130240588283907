import React, { useEffect, useCallback, useReducer } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import classnames from "classnames";
import {
  FieldsValidation,
  FieldsValidationContextValue
} from "./FieldsValidation";
import FormPreview from "./FormPreview";
import FormProperties from "./FormProperties";
import FormSidebar from "./FormSidebar";
import { FormBuilderContext } from "./context";
import { FormField, Field } from "./interface";
import { initialState, reducer, ReducerActionType } from "./store";
import { normalizeFormItems } from "./utils";
import "./FormBuilder.css";

export interface FormBuilderProps {
  className?: string;
  title?: string;
  items?: Field[];
  formData?: FormField[];
  formId?: string;
  onSubmit?: (formData: FormField[]) => void;
  onCancel?: () => void;
  onChange?: (formData: FormField[]) => void; // For repeater
  setValidation?: React.Dispatch<
    React.SetStateAction<FieldsValidationContextValue | undefined>
  >; // For repeater
}

/**
 * FormBuilder component render form builder with a bunch of fields on the sidebar
 * with the ability to drag them to the center and set properties for each field apart
 * @param items - sidebar fields(text, number, date etc...)
 * @param formData - default form data from api, that will render already built form fields
 * @param onSubmit - form save handler, take items from built form and send to api
 */

const FormBuilder: React.FC<FormBuilderProps> = ({
  className,
  items = [],
  formData = [],
  formId,
  onSubmit,
  onChange,
  setValidation
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Set sidebar fields
  const setSidebarItems = (sidebarItems: Field[]) => {
    dispatch({
      type: ReducerActionType.SET_SIDEBAR_ITEMS,
      sidebarItems
    });
  };

  // Set form fields
  const setFormItems = useCallback(
    (formItems: FormField[], normalize = false, watchChange = true) => {
      const normalizedItems = normalize
        ? normalizeFormItems(formItems, items)
        : formItems;
      watchChange && onChange?.(normalizedItems);

      dispatch({
        type: ReducerActionType.SET_FORM_ITEMS,
        formItems: normalizedItems
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  // Set current field for properties sidebar
  const setCurrentItem = (item: FormField | null) => {
    dispatch({
      type: ReducerActionType.SET_CURRENT_ITEM,
      currentItem: item
    });
  };

  // Init sidebar items and form fields
  useEffect(() => {
    // Set sidebar items
    if (items.length !== 0) {
      setSidebarItems(items);
    }

    // Set form default fields
    if (formData) {
      setFormItems(formData, true, false);
    }
  }, [items, formData, setFormItems]);

  // Form Handlers
  const handleFormAddItems = (
    formItems: FormField[],
    draggedItem?: FormField
  ) => {
    // Set new form items, `formItems` already normalized
    dispatch({
      type: ReducerActionType.SET_FORM_ITEMS,
      formItems
    });

    onChange?.(formItems);

    // Set current item after the item was dragged
    if (draggedItem) {
      setCurrentItem(draggedItem);
    }
  };

  const handleClickItem = (fieldId: string) => {
    const field = state.formItems.find(
      (formItem: FormField) => formItem.id === fieldId
    );

    setCurrentItem(field);
  };

  const handleDeleteItem = (fieldId: string) => {
    const formItems = state.formItems.filter(
      (formItem: FormField) => formItem.id !== fieldId
    );

    // Remove item form
    setFormItems(formItems);

    if (state.currentItem && state.currentItem.id === fieldId) {
      // Reset properties if current item is deleted
      setCurrentItem(null);
    }
  };

  const handleFormChangeItem = (changedItem: any, watchChange = true) => {
    const formItems = state.formItems.map((formItem: FormField) =>
      formItem.id === changedItem.id ? changedItem : formItem
    );

    // Replace changed item to form
    setFormItems(formItems, undefined, watchChange);

    // Set changed item to current
    setCurrentItem(changedItem);
  };

  const handleFormSave = () => {
    const savedFormItems = state.formItems.map((formItem: FormField) => {
      // Omit id and properties_form
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, fieldType, properties_form, isNameDisabled, ...item } =
        formItem;

      return item;
    });

    // Disable name for current form items on save
    setFormItems(state.formItems, undefined, false);

    if (state.currentItem) {
      // Disabled name for current item
      const disabledCurrentItem: FormField = {
        ...state.currentItem,
        isNameDisabled: true
      };

      setCurrentItem(disabledCurrentItem);
    }

    onSubmit && onSubmit(savedFormItems);
  };

  const currentItemId =
    state.currentItem && state.currentItem.id
      ? state.currentItem.id
      : "no-selected-item";

  return (
    <div className={classnames("form-builder", className)}>
      <div className="form-builder__container">
        <FieldsValidation
          state={state}
          setValidation={setValidation}
          onChangeItem={handleFormChangeItem}
        >
          <FormBuilderContext.Provider
            value={{ builderProps: { items, formData } }}
          >
            <DndProvider backend={HTML5Backend}>
              <FormSidebar items={state.sidebarItems} />

              <FormPreview
                currentItem={state.currentItem}
                items={state.formItems}
                onAddItems={handleFormAddItems}
                onClickItem={handleClickItem}
                onDeleteItem={handleDeleteItem}
              />
              <FormProperties
                key={currentItemId}
                formId={formId}
                currentItem={state.currentItem}
                onChangeItem={handleFormChangeItem}
                onSubmit={handleFormSave}
              />
            </DndProvider>
          </FormBuilderContext.Provider>
        </FieldsValidation>
      </div>
    </div>
  );
};

export default FormBuilder;
