import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import { Flex } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { ProjectEntity } from "types";
import { styles } from "./styles";

export const ProjectMap = ({ lat, lng }: ProjectEntity["location"]) => {
  const cssStyles = styles();

  return (
    <Flex css={cssStyles.mapWrapper}>
      <MapContainer
        zoomControl={false}
        center={{
          lat,
          lng
        }}
        zoom={14}
        css={cssStyles.mapContainer}
      >
        <TileLayer
          attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          icon={L.icon({
            iconUrl: "/assets/map/marker.svg",
            iconSize: [48, 48]
          })}
          position={{
            lat,
            lng
          }}
        />

        <ZoomControl position="bottomright" />
      </MapContainer>
    </Flex>
  );
};
