import { useNavigate } from "react-router-dom";
import { MenuProps, Table } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { queries } from "api";
import { ContentEntity } from "types";
import { showErrorMessage } from "utils";

import { getTableColumns } from "./tableColumns";
import {
  normalizeContentList,
  TableContentType,
  showDeleteConfirm,
  showDuplicateConfirm
} from "./utils";

interface Props {
  contentList: ContentEntity[];
  fetchContentList?: () => void;
  paginationConfig?: any;
  loading?: boolean;
}

export const ContentListTable = ({
  loading,
  contentList,
  paginationConfig
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutationConfig = {
    onSuccess: () =>
      queryClient.invalidateQueries(queries.content.search({}).queryKey),
    onError: (error: Error) => showErrorMessage(error)
  };

  const duplicateContentMutation = useMutation(
    ({ contentTypeId, contentId }: Record<string, string>) =>
      api.content.duplicate(contentTypeId, contentId),
    mutationConfig
  );

  const deleteContentMutation = useMutation(
    ({ contentTypeId, contentId }: Record<string, string>) =>
      api.content.remove(contentTypeId, contentId),
    mutationConfig
  );

  const getActionColumnMenu: (
    content: TableContentType
  ) => MenuProps["items"] = (content) => [
    {
      label: "Edit",
      key: "edit",
      onClick: () => {
        navigate(`/content/edit/${content.contentType.id}/${content.id}/`, {
          state: { name: content.name }
        });
      }
    },
    {
      label: "Duplicate",
      key: "duplicate",
      onClick: () => {
        const { id, contentType } = content;
        const onOk = async () =>
          duplicateContentMutation.mutate({
            contentTypeId: contentType.id,
            contentId: id
          });
        showDuplicateConfirm(onOk, content.name);
      }
    },
    {
      label: "Delete",
      key: "delete",
      onClick: () => {
        const { id, contentType } = content;
        const onOk = async () =>
          deleteContentMutation.mutate({
            contentTypeId: contentType.id,
            contentId: id
          });
        showDeleteConfirm(onOk);
      }
    }
  ];

  const columns = getTableColumns(getActionColumnMenu);
  const data = normalizeContentList(contentList);

  return (
    <Table
      id="content-table"
      loading={loading || deleteContentMutation.isLoading}
      columns={columns}
      dataSource={data}
      pagination={{ ...paginationConfig, hideOnSinglePage: true }}
      bordered
    />
  );
};
