import React, { useEffect, useState } from "react";

import { getLocalStorageTokens, removeLocalStorageTokens } from "api/axios";
import { LoadingScreen } from "components/LoadingScreen";

interface SessionProviderProps {
  withoutLoggedInRoutes?: React.ReactNode;
}

export const SessionProvider = ({
  children,
  withoutLoggedInRoutes
}: React.PropsWithChildren<SessionProviderProps>) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const tokens = getLocalStorageTokens();

  useEffect(() => {
    if (tokens.access && tokens.refresh) {
      setIsLoggedIn(true);
    } else {
      removeLocalStorageTokens();
      setIsLoggedIn(false);
    }
    setIsLoading(false);
  }, [tokens]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <>{isLoggedIn ? children : withoutLoggedInRoutes}</>;
};
