import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { IdeasTable } from "features/ideas/components";
import { styles } from "features/users/components/Wrapper/styles";
import { ProjectStatus } from "types";
import { ProjectsTable } from "../ProjectsTable";

const tabItems: TabsProps["items"] = [
  {
    key: "active",
    label: `Proiecte`,
    children: <ProjectsTable initialStatus={ProjectStatus.ACTIVE} />
  },
  {
    key: "proposed",
    label: "Proiecte depuse",
    children: <ProjectsTable initialStatus={ProjectStatus.DRAFT} />
  },
  {
    key: "proposed-ideas",
    label: "Idei depuse",
    children: <IdeasTable />
  }
];

export const TabsWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") ?? tabItems?.[0]?.key;
  const [activeTab, setActiveTab] = useState<string | undefined>(initialTab);

  const cssStyle = styles();

  useEffect(() => {
    setSearchParams({ tab: activeTab ?? "" });
  }, [activeTab, setSearchParams]);

  return (
    <Tabs
      id="projects-tabs"
      onChange={(key) => {
        setActiveTab(key);
      }}
      activeKey={activeTab}
      items={tabItems}
      css={cssStyle.tabs}
      size="large"
    />
  );
};
