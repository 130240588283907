import { Card, Divider, Flex, Typography } from "antd";
import purify from "dompurify";
import { Carousel } from "features/projects/components/Carousel";
import { ProjectDetails } from "features/projects/types";
import { styles } from "../ExtraContent/styles";

const { Title } = Typography;

export const ActiveLayout = ({ data }: ProjectDetails) => {
  const projectAttachments = data?.attachments;

  const cssStyles = styles();

  return (
    <Card>
      <Flex vertical gap={8}>
        <Title level={5} css={cssStyles.font18}>
          Titlu Proiect
        </Title>
        <Title level={2}>{data?.title_ro}</Title>
      </Flex>

      <Divider />

      {projectAttachments && projectAttachments?.length > 0 && (
        <Carousel data={projectAttachments} />
      )}

      <Flex
        vertical
        gap={8}
        style={{
          marginBottom: 24
        }}
      >
        <Title level={5} css={cssStyles.font18}>
          Domeniul
        </Title>
        <Title level={2}>{data?.field?.name}</Title>
      </Flex>

      <Flex vertical gap={8}>
        <Title level={5} css={cssStyles.font18}>
          Descriere proiect
        </Title>
        <div
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(data?.description ?? "")
          }}
        />
      </Flex>
    </Card>
  );
};
