import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { LeftOutlined, MailOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";

import authorization from "api/authorization";
import { Login } from "types";
import { showErrorMessage } from "utils";

import { AuthLayout, FormWrapper } from "../components";
import { OtpCheckPage } from "./OtpCheckPage";

export const ResetPasswordPage = () => {
  const [form] = Form.useForm();

  const [showOTP, setShowOTP] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: authorization.resetPassword,
    onError: showErrorMessage,
    onSuccess: () => setShowOTP(true)
  });

  const onSubmit = (values: Login) => {
    sessionStorage.setItem("email", values.email);
    mutate(values);
  };

  return (
    <AuthLayout>
      {!showOTP ? (
        <FormWrapper
          form={form}
          onFinish={onSubmit}
          title="Resetează parola"
          subTitle="Introdu adresa de e-mail pentru a primi instrucțiuni de resetare a parolei."
          topStep={
            <Link to="/authentication/login">
              <Button size="large" icon={<LeftOutlined />} />
            </Link>
          }
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input
              placeholder="Introdu adresa de e-mail"
              suffix={<MailOutlined />}
              size="large"
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Trimite instrucțiuni de resetare
          </Button>
        </FormWrapper>
      ) : (
        <OtpCheckPage type="reset" />
      )}
    </AuthLayout>
  );
};
