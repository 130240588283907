export enum NotificationType {
  ALERT = "alert",
  EVENT = "event"
}

export enum NotificationStatus {
  PENDING = "pending",
  SENT = "sent",
  FAILED = "failed"
}

export type NotificationEntity = {
  id: number;
  subject: string;
  body: string;
  type: NotificationType;
  status: NotificationStatus;
  created_at: string;
  sent_at: string | null;
  publish_at: string | null;
};
