import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Action } from "types/action";
import { cmsClient } from "./axios";

const getAll = async () => {
  const { data } = await cmsClient.get<Action[]>("/actions/");

  return data;
};

const getById = async (id: string) => {
  const { data } = await cmsClient.get<Action[]>(`/actions/${id}/`);

  return data;
};

const actions = {
  getAll,
  getById
};

export const actionsQueries = createQueryKeys("actions", {
  all: () => ({
    queryKey: ["list"],
    queryFn: getAll
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  })
});

export default actions;
