import { SelectProps } from "antd";
import { UserRole } from "types";

export const roleDisplayText = {
  [UserRole.SUPERUSER]: "Super User",
  [UserRole.EMPLOYEE]: "Angajați Primărie",
  [UserRole.CITIZEN]: "Cetățeni"
};

export const sortDirectionOptions: SelectProps["options"] = [
  { label: "Ascendent", value: "first_name" },
  { label: "Descendent", value: "-first_name" }
];
