import { Link } from "react-router-dom";
import { Button, Dropdown, MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import Status from "components/Status";

import { contentTypeCategories } from "utils";

import { TableContentType } from "./utils";

export const getTableColumns = (
  getActionColumnMenu: (content: TableContentType) => MenuProps["items"]
): ColumnProps<TableContentType>[] => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string, item) => (
        <Link to={`/content-types/${item.id}`}>{name}</Link>
      )
    },
    {
      title: "API Name",
      dataIndex: "code_name",
      key: "code_name"
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value) => {
        return contentTypeCategories.find((c) => c.value === value)?.label;
      }
    },
    {
      title: "Fields",
      dataIndex: "fields",
      key: "fields"
    },
    {
      title: "Updated",
      dataIndex: "updatedDate",
      key: "updatedDate"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Status status={status} />
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      key: "actions",
      render: (_, item) => (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button icon={<EllipsisOutlined />} type="text" />
        </Dropdown>
      )
    }
  ];
};
