import { createQueryKeys } from "@lukemorales/query-key-factory";

import { NotificationEntity, Paginated } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<NotificationEntity[]>>(
    "/cms/notifications",
    {
      params: queryParams
    }
  );

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<NotificationEntity>(
    `/cms/notifications/${id}`
  );

  return data;
};

const create = async (notificationData: NotificationEntity) => {
  const { data } = await ssoClient.post(`/cms/notifications`, notificationData);

  return data;
};

const notifications = {
  getAll,
  create
};

export const notificationsQueries = createQueryKeys("notifications", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  })
});

export default notifications;
