import React, { useState } from "react";

import { Form } from "antd";
import { NamePath } from "antd/lib/form/interface";

import { FieldItemProps } from "./FormFields/interface";

export interface HelpTextOrErrorProps {
  name: NamePath;
  data: FieldItemProps;
}

const HelpTextOrError: React.FC<HelpTextOrErrorProps> = ({ name, data }) => {
  const [, setPrevErrors] = useState<string[]>([]); // using for rerender

  return (
    <Form.Item
      dependencies={[name]}
      noStyle
      children={(form) => {
        if (form.isFieldValidating(name)) {
          setTimeout(() => {
            // Wait field to finish validating, then rerender
            form
              .validateFields([name])
              .catch((e) => console.error(e)) // avoid errors in console
              .finally(() => {
                setPrevErrors(form.getFieldError(name));
              });
          }, 0); // Timeout to not update the state while rendering
        }

        const errors = form.getFieldError(name);
        return errors.length ? errors : data?.properties?.help_text;
      }}
    />
  );
};

export default HelpTextOrError;
