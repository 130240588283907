import { SelectProps } from "antd";
import { ProjectFields, ProjectStatus } from "types";

export const sortProjectDirectionOptions: SelectProps["options"] = [
  { label: "Ascendent", value: "id" },
  { label: "Descendent", value: "-id" }
];

export const statusDisplayText = {
  [ProjectStatus.DRAFT]: "Propuse",
  [ProjectStatus.ACTIVE]: "Activ",
  [ProjectStatus.ARCHIVED]: "Finalizat"
};

export const sortByProjectStatusOptions = [
  ...Object.entries(statusDisplayText).map(([value, label]) => ({
    label,
    value
  }))
];

export const sortProjectsByDomains = (domains: ProjectFields[]) => {
  return [
    { label: "Toate domeniile", value: "" },
    ...(domains ?? []).map(({ id, name }: ProjectFields) => ({
      label: name,
      value: id
    }))
  ];
};

export const getProjectCreateYears = (projects: string[]) => {
  const years = projects?.filter(
    (value, index, self) => self.indexOf(value) === index
  );

  return [
    { label: "Toti anii", value: "" },
    ...(years ?? []).map((year) => ({
      label: year,
      value: year
    }))
  ];
};

export const videoFormats = ["mp4", "webm", "ogg", "mov"];
