import { css } from "@emotion/react";

export const styles = () => ({
  wrapper: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  `
});
