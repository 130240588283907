import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Typography
} from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import { InviteUser, UserEntity, UserRole } from "types";
import { showErrorMessage } from "utils";

type Props = {
  onClose: () => void;
} & ModalProps;

type FooterProps = {
  onSubmit: () => void;
  onClose: () => void;
  isLoading: boolean;
};

const Footer = ({ onSubmit, onClose, isLoading }: FooterProps) => {
  return (
    <Flex
      gap={10}
      justify="flex-end"
      align="flex-end"
      style={{
        borderRadius: "0 0 8px 8px",
        backgroundColor: "#F4F6F7",
        padding: 16
      }}
    >
      <Button onClick={onClose} size="large">
        Anulează
      </Button>
      <Button
        type="primary"
        onClick={onSubmit}
        size="large"
        loading={isLoading}
      >
        Trimite invitație
      </Button>
    </Flex>
  );
};

export const InviteModal = ({ onClose, open }: Props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: InviteUser) => api.users.invite(data),
    onSuccess: () => {
      notification.success({
        message: "Invitația a fost trimisă cu succes"
      });
      queryClient.invalidateQueries(queries.users.all());
      onClose();
    },
    onError: (error) => showErrorMessage(error)
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutate({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        role: UserRole.EMPLOYEE
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title=""
      open={open}
      centered
      okText="Trimite invitație"
      cancelText="Anulează"
      onCancel={onClose}
      style={{
        maxWidth: 450
      }}
      styles={{
        content: {
          padding: 0
        },
        body: {
          padding: 48
        }
      }}
      footer={Footer({ onSubmit, onClose, isLoading })}
    >
      <Form form={form} layout="vertical">
        <Flex vertical gap={"8px"}>
          <Typography.Title level={2}>
            Invită un angajat în sistem
          </Typography.Title>
          <Typography.Text>
            Indică datele angajatului în câmpul de mai jos.
          </Typography.Text>
        </Flex>
        <Form.Item<UserEntity>
          label="Nume"
          name="first_name"
          rules={[{ required: true, message: "Introduceți numele" }]}
        >
          <Input placeholder="Introduceți numele" />
        </Form.Item>
        <Form.Item<UserEntity>
          label="Prenumele"
          name="last_name"
          rules={[{ required: true, message: "Introduceți prenumele" }]}
        >
          <Input placeholder="Introduceți prenumele" />
        </Form.Item>
        <Form.Item<UserEntity>
          label="Adresa de e-mail"
          name="email"
          rules={[
            { required: true, message: "Introduceți emailul", type: "email" }
          ]}
        >
          <Input placeholder="Introduceți adresa de e-mail" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
