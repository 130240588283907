import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { ViewIdeaDetails } from "../components";

export const ViewIdeaPage = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    ...queries.ideas.byId(Number(id)),
    enabled: !!id
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <ViewIdeaDetails data={data} />;
};
