import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { styles } from "./styles";

export const BackButton = ({ path, tab }: { path: string; tab: string }) => {
  const navigate = useNavigate();
  const cssStyles = styles();

  return (
    <Button
      css={cssStyles.arrowButton}
      onClick={() =>
        navigate({
          pathname: path,
          search: `tab=${tab}`
        })
      }
    >
      <ArrowLeftOutlined />
    </Button>
  );
};
