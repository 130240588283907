import { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { CKEDITOR_FILE_PATH } from "app-constants";
import { AppLayout } from "components";
import { LoginPage } from "features/authentication/pages/LoginPage";
import { ResetPasswordConfirmPage } from "features/authentication/pages/ResetPasswordConfirmPage";
import { ResetPasswordPage } from "features/authentication/pages/ResetPasswordPage";
import { SuccessSetPasswordPage } from "features/authentication/pages/SuccessSetPasswordPage";
import { ContentFormPage } from "features/content/pages/ContentFormPage";
import { ContentListPage } from "features/content/pages/ContentListPage";
import { ContentTypeProvider } from "features/content-types/contexts";
import { ContentTypePage } from "features/content-types/pages/ContentTypePage";
import { ContentTypesPage } from "features/content-types/pages/ContentTypesPage";
import { FormsCreatePage, FormsEditPage } from "features/forms/pages";
import { FormsPage } from "features/forms/pages/FormsPage";
import { ViewIdeaPage } from "features/ideas/pages";
import LocalesPage from "features/locales/pages/LocalesPage";
import { MediaForm } from "features/media/pages/MediaForm";
import { MediaPage } from "features/media/pages/MediaPage";
import {
  NotificationCreate,
  NotificationList
} from "features/notifications/pages";
import { CreateEditPoll, PollDetails, PollsPage } from "features/polls/pages";
import { UserProfile } from "features/profile/pages";
import { TabsWrapper } from "features/projects/components";
import { EditProject, ViewProject } from "features/projects/pages";
import { SettingsPage } from "features/settings/pages";
import { Statistics } from "features/statistics/pages";
import { UsersTable, Wrapper } from "features/users/components";
import {
  CreateVotingPage,
  VotingPage,
  VotingResults
} from "features/voting/pages";
import { withCKEditorMediaRoute } from "hoc";
import { UserRole } from "types";
import { SessionProvider } from "./SessionProvider";

const AppRouter = () => {
  const location = useLocation();

  const background = location.state && location.state.background;

  if ([CKEDITOR_FILE_PATH].includes(location.pathname)) return null;

  return (
    <SessionProvider
      withoutLoggedInRoutes={
        <Routes location={location}>
          <Route path="authentication">
            <Route index element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route
              path="reset-password-confirm"
              element={<ResetPasswordConfirmPage />}
            />
            <Route
              path="reset-password-success"
              element={<SuccessSetPasswordPage />}
            />
          </Route>

          <Route
            path="*"
            element={<Navigate to="/authentication/login" replace />}
          />
        </Routes>
      }
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ContentTypeProvider>
          <AppLayout>
            <Routes location={background || location}>
              <Route path="statistics" element={<Statistics />} />
              <Route path="users">
                <Route index element={<Wrapper />} />
                <Route
                  path="citizens"
                  element={
                    <Wrapper>
                      <UsersTable role={UserRole.CITIZEN} />
                    </Wrapper>
                  }
                />
                <Route
                  path="employees"
                  element={
                    <Wrapper>
                      <UsersTable role={UserRole.EMPLOYEE} />
                    </Wrapper>
                  }
                />
              </Route>
              <Route path="content-types">
                <Route index element={<ContentTypesPage />} />
                <Route path=":id" element={<ContentTypePage />} />
              </Route>
              <Route path="content">
                <Route index element={<ContentListPage />} />
                <Route path="create/:typeId" element={<ContentFormPage />} />
                <Route path="edit/:typeId/:id" element={<ContentFormPage />} />
              </Route>
              <Route path="media">
                <Route index element={<MediaPage />} />
              </Route>
              <Route path="locales" element={<LocalesPage />} />

              <Route path="notifications">
                <Route index element={<NotificationList />} />
                <Route path="add" element={<NotificationCreate />} />
              </Route>

              <Route path="polls">
                <Route index element={<PollsPage />} />
                <Route path="edit/:id" element={<CreateEditPoll />} />
                <Route path="add" element={<CreateEditPoll />} />
                <Route path="details/:id" element={<PollDetails />} />
              </Route>
              <Route path="projects">
                <Route index element={<TabsWrapper />} />
                <Route path="view/:id" element={<ViewProject />} />
                <Route path="edit/:id" element={<EditProject />} />
              </Route>

              <Route path="idea/view/:id" element={<ViewIdeaPage />} />

              <Route path="voting">
                <Route index element={<VotingPage />} />
                <Route path="add" element={<CreateVotingPage />} />
                <Route path="edit/:id" element={<CreateVotingPage />} />
                <Route path="results/:id" element={<VotingResults />} />
              </Route>

              <Route path="settings" element={<SettingsPage />} />
              <Route path="profile" element={<UserProfile />} />

              <Route path="forms">
                <Route index element={<FormsPage />} />
                <Route path="create" element={<FormsCreatePage />} />
                <Route path=":id" element={<FormsEditPage />} />
                <Route path=":id/:tab" element={<FormsEditPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/statistics" replace />} />
            </Routes>

            {background && (
              <Routes>
                <Route path="media-form">
                  <Route path="create" element={<MediaForm />} />
                  <Route path="edit/:id" element={<MediaForm />} />
                </Route>
              </Routes>
            )}
          </AppLayout>
        </ContentTypeProvider>
      </Suspense>
    </SessionProvider>
  );
};

export default withCKEditorMediaRoute(AppRouter, CKEDITOR_FILE_PATH);
