import dayjs from "dayjs";

export const DAY_MONTH_YEAR_HOUR_MINUTE = "DD/MM/YYYY HH:mm";
export const YEAR_FORMAT = "YYYY";
export const DATE_FORMAT = "YYYY-MM-DD";
export const MONTH_YEAR = "MMMM DD, YYYY";
export const FORMAT_DAY_MONTH_YEAR__WITH_DOT = "DD.MM.YYYY";
export const FORAMT_DAY_MONTH_YEAR_WITH_SLASH = "DD/MM/YYYY";

export const compareDates = (
  dateA: dayjs.Dayjs,
  dateB: dayjs.Dayjs
): number => {
  const aTime = dateA.unix();
  const bTime = dateB.unix();

  if (aTime < bTime) {
    return -1;
  } else if (aTime > bTime) {
    return 1;
  } else {
    return 0;
  }
};
