import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { LockFilled } from "@ant-design/icons";

import { useMutation } from "@tanstack/react-query";
import authorization from "api/authorization";
import { SetPassword } from "types";
import { showErrorMessage } from "utils";
import { AuthLayout, CheckPassword, FormWrapper, PageTag } from "../components";

export const ResetPasswordConfirmPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const otp_code = searchParams.get("otp_code");
  const email = searchParams.get("email");

  const { mutate: verifyOtp } = useMutation({
    mutationFn: authorization.verifyOTPCode,
    onError: showErrorMessage
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: authorization.setPassword,
    onError: showErrorMessage,
    onSuccess: () => navigate("/authentication/reset-password-success")
  });

  const onSubmit = (values: SetPassword) => {
    const payload = {
      ...values,
      email: email ?? (sessionStorage.getItem("email") as string)
    };
    mutate(payload);
  };

  useEffect(() => {
    if (otp_code && email) {
      verifyOtp({ otp_code, email, code_type: "reset_password" });
    }
  }, [otp_code, email, verifyOtp]);

  return (
    <AuthLayout>
      <FormWrapper
        form={form}
        title="Creați o parolă nouă"
        subTitle="Vă rugăm să creați o parolă nouă."
        topStep={<PageTag icon={<LockFilled />} />}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Creează o parolă"
          name="password"
          rules={[{ required: true, message: "Introduceți parola nouă" }]}
        >
          <Input.Password placeholder="Creează o parolă" size="large" />
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.password !== curValues.password
          }
        >
          {({ getFieldValue }) => (
            <CheckPassword value={getFieldValue("password")} />
          )}
        </Form.Item>

        <Form.Item
          label="Repetă parola"
          name="confirm_password"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Parolele nu se potrivesc."));
              }
            })
          ]}
        >
          <Input.Password placeholder="Repetă parola" size="large" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          size="large"
          loading={isLoading}
        >
          Resetați parola
        </Button>
      </FormWrapper>
    </AuthLayout>
  );
};
