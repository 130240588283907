export const Error = () => (
  <svg
    width="400px"
    height="400px"
    viewBox="0 0 400 400"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(4,0,0,4,0,0)">
      <path
        d="M10.961 57.000 A40.000 40.000 0 1 0 90.961 57.000 A40.000 40.000 0 1 0 10.961 57.000 Z"
        fill="#d8e0eb"
      ></path>
      <path
        d="M40.1,27a1,1,0,0,1-1,1h-6a1,1,0,0,1-1-1V24h8Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M25.104 46L36.104 38 47.104 46 25.104 46z"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M34.104 36.000 A2.000 2.000 0 1 0 38.104 36.000 A2.000 2.000 0 1 0 34.104 36.000 Z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M36.104 28L36.104 34"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M66.961,93.671a39.816,39.816,0,0,0,6-3.259V24h-6Z"
        fill="#ffffff"
      ></path>
      <path
        d="M72.961 90.412L72.961 24 66.961 24 66.961 93.671"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M66.961 24L72.961 30 66.961 36 72.961 42 66.961 48 72.961 54 66.961 60 72.961 66 66.961 72 72.961 78 66.961 84 72.961 90"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M67.104 10H73.104V18H67.104z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M13.311,24a.5.5,0,0,1-.353-.854L18.1,18h66v6Z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M73.104 10L70.104 3 67.104 10 73.104 10z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M91.1,23a1,1,0,0,1-1,1h-6V18a1,1,0,0,1,1-1h6Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M30.104 18L70.104 3 91.104 17"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M73.104 10L67.104 16.918"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M18.104 18L24.104 24 30.104 18 36.104 24 42.104 18 48.104 24 54.104 18 60.104 24 66.104 18 72.104 24 78.104 18 84.104 24"
        fill="none"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M8.896 46.000 L62.170 46.000 L62.170 85.847 L8.896 85.847 Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M59.07,46H12a3.1,3.1,0,0,0-3.1,3.1v4.275H62.17V49.1A3.1,3.1,0,0,0,59.07,46Z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M11.996 49.668 A1.550 1.550 0 1 0 15.096 49.668 A1.550 1.550 0 1 0 11.996 49.668 Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinejoin="round"
      ></path>
      <path
        d="M17.350 49.668 A1.550 1.550 0 1 0 20.450 49.668 A1.550 1.550 0 1 0 17.350 49.668 Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinejoin="round"
      ></path>
      <path
        d="M22.703 49.668 A1.550 1.550 0 1 0 25.803 49.668 A1.550 1.550 0 1 0 22.703 49.668 Z"
        fill="#c6dff4"
        stroke="#311313"
        strokeLinejoin="round"
      ></path>
      <path
        d="M41.286,77.225a2.706,2.706,0,0,1-1.925-.8L35.533,72.6,31.7,76.427a2.722,2.722,0,0,1-3.849-3.851l3.828-3.827-3.828-3.828A2.722,2.722,0,0,1,31.7,61.07L35.533,64.9l3.828-3.829a2.723,2.723,0,1,1,3.85,3.851l-3.828,3.828,3.828,3.827a2.724,2.724,0,0,1-1.925,4.649Z"
        fill="#ffffff"
        stroke="#311313"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
);

export default Error;
