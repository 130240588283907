"use client";

import { Dispatch, SetStateAction } from "react";

import {
  Flex,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  notification
} from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";

import { Trash } from "assets/svg";
import { videoFormats } from "features/projects/utils";
import { File } from "types";
import { styles } from "./style";

const { Text } = Typography;

type Props = {
  files: File[];
  setFormData: Dispatch<SetStateAction<File[]>>;
  multiple?: boolean;
  avatar?: boolean;
} & UploadProps;

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
const MAX_FILES_NUMBER = 10;

export const UploadComponent = ({
  setFormData,
  files,
  multiple = true,
  avatar = false
}: Props) => {
  const cssStyles = styles();

  const errorNotification = (description: string) => {
    notification.error({
      message: "Error",
      description,
      duration: 3
    });
  };

  const onDelete = (index: number) => {
    setFormData((formData) => {
      return formData?.filter((_, idx) => idx !== index) || [];
    });
  };

  const acceptFiles = avatar
    ? "png, .jpg, .jpeg"
    : ".png, .jpg, .jpeg, video/*";

  const props = {
    action: process.env.REACT_APP_FILESTORAGE_URL,
    accept: acceptFiles,
    multiple: multiple,
    showUploadList: false,
    beforeUpload(currentFile: RcFile) {
      if (currentFile?.size > MAX_FILE_SIZE) {
        errorNotification("Fișierul prea mare");
        throw new Error();
      } else if (
        !avatar &&
        files?.length === (multiple ? MAX_FILES_NUMBER : 1)
      ) {
        errorNotification("Numărul maxim de fișiere a fost atins");
        throw new Error();
      } else if (
        (files ?? []).find(
          (file) => (file?.name || file?.file_name) === currentFile.name
        )
      ) {
        errorNotification("Fișierul a fost deja adăugat");
        throw new Error();
      } else if (acceptFiles.split(", ").includes(currentFile?.type)) {
        errorNotification("Formatul fișierului nu este permis");
        throw new Error();
      }
    },

    onChange: (info: UploadChangeParam<UploadFile>) => {
      if (info.file.status === "done" && info.file.response?.url) {
        const fileDetails = {
          name: info.file.response.name,
          url: info.file.response.url,
          size: info.file.size
        };

        if (avatar) {
          setFormData([fileDetails]);
        } else {
          setFormData((formData) => [...(formData ?? []), fileDetails]);
        }
      }
    }
  };

  return (
    <Flex vertical>
      <Upload {...props} css={cssStyles.upload}>
        <Flex
          vertical
          justify="center"
          align="center"
          css={cssStyles.uploadBox}
        >
          Plasați aici fișierul sau selectați
          <Flex gap="1" vertical align="center">
            <Text type="secondary">
              Fișierele permise: .png, .jpg, .jpeg {avatar ? "" : "și video"}
            </Text>
            <Text type="secondary">Dimensiune maximă: 20 MB</Text>
          </Flex>
        </Flex>
      </Upload>

      {!avatar && (
        <div style={{ marginTop: "10px" }}>
          {files?.map((file, index) => {
            const fileSize = file?.size ?? file?.file_size;
            const isVideo = videoFormats.some((format) =>
              file?.url?.includes(format)
            );

            return (
              <Flex
                key={index}
                justify="space-between"
                align="center"
                css={cssStyles.fileContainer}
              >
                <Space>
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      width: 88,
                      height: 60,
                      borderRadius: 12,
                      overflow: "hidden"
                    }}
                  >
                    {isVideo ? (
                      <Typography.Text style={{ fontSize: 24 }}>
                        ▶️
                      </Typography.Text>
                    ) : (
                      <img
                        src={file.url}
                        alt="thumbnail"
                        css={cssStyles.image}
                      />
                    )}
                  </Flex>

                  <Flex vertical>
                    <Text>{file?.name || file?.file_name}</Text>
                    <Text type="secondary">
                      {fileSize ? Math.round(fileSize / 1024) : 0} KB
                    </Text>
                  </Flex>
                </Space>

                <Flex
                  onClick={() => onDelete(index)}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <Trash />
                </Flex>
              </Flex>
            );
          })}
        </div>
      )}
    </Flex>
  );
};
