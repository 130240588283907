import { Divider, Flex, Row, Typography } from "antd";

import { PasswordForm } from "../components/PasswordForm";
import { ProfileForm } from "../components/ProfileForm";

export const UserProfile = () => {
  return (
    <div id="profile-form">
      <Row className="mb-20" align="middle" justify="space-between">
        <Flex gap={4} align="baseline">
          <Typography.Title level={2}>Profil</Typography.Title>
        </Flex>
      </Row>

      <Divider />
      <Flex vertical gap={20}>
        <ProfileForm />
        <PasswordForm />
      </Flex>
    </div>
  );
};
