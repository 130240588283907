import React, { useState, useEffect, useRef } from "react";
import { Modal, Tabs } from "antd";
import Editor from "@monaco-editor/react";
import { encode, decode } from "js-base64";
import { FormField, FieldProperties } from "../../../interface";
import "./ModalCodeEditor.css";

const { TabPane } = Tabs;

enum TabsEnum {
  XML = "xml",
  LOGS = "logs"
}

interface TabState {
  tab: string;
  warns: string[];
}

interface ModalCodeEditorProps {
  visible: boolean;
  setCodeEditorVisible: (visible: boolean) => void;
  currentItem?: FormField | null;
  properties: FieldProperties;
  changeCode: (code: string) => void;
}

const ModalCodeEditor: React.FC<ModalCodeEditorProps> = ({
  visible,
  setCodeEditorVisible,
  currentItem,
  properties,
  changeCode
}) => {
  const [modalEditorCode, setModalEditorCode] = useState(
    "// Please add your code"
  );

  useEffect(() => {
    if (currentItem && currentItem.properties?.script) {
      setModalEditorCode(decode(currentItem.properties.script));
    }
  }, [currentItem]);

  // Tab State
  const [state, setState] = useState<TabState>({
    tab: TabsEnum.XML,
    warns: []
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);

  const handleEditorDidMount = (editor: HTMLDivElement): void => {
    if (editorRef) {
      editorRef.current = editor;
    }
  };

  const handleClick = (): void => {
    if (editorRef && editorRef.current) {
      const code = editorRef.current.getValue();
      setModalEditorCode(code);

      const encodedCode = encode(code);
      changeCode(encodedCode);
    }
    setCodeEditorVisible(false);
  };

  // Handle modal cancel
  const handleCancel = (): void => {
    setCodeEditorVisible(false);
  };

  return (
    <Modal
      width="1000px"
      className="modal-code-editor"
      centered
      visible={visible}
      onOk={handleClick}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Tabs
        className="modal-code-tabs"
        activeKey={state.tab}
        onChange={(tab: string): void => setState({ tab, warns: state.warns })}
      >
        <TabPane tab="XML" key={TabsEnum.XML}>
          <Editor
            height="46.5vh"
            defaultLanguage={properties.language}
            defaultValue={modalEditorCode}
            onMount={handleEditorDidMount}
          />
        </TabPane>

        <TabPane tab="Logs" key={TabsEnum.LOGS}>
          <div className="modal-code-logs__container">
            <code>
              {state.warns.length > 0 ? (
                state.warns.map((warn, idx) => (
                  <p key={`${warn}-${idx}`}>
                    {idx}: {warn}
                  </p>
                ))
              ) : (
                <p>There are no logs!</p>
              )}
            </code>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ModalCodeEditor;
