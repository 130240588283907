import { createQueryKeys } from "@lukemorales/query-key-factory";
import { Field, FormField } from "components/FormBuilder";

import {
  ContentType,
  CountResponse,
  CreateContentType,
  EditContentType
} from "types";
import { cmsClient } from "./axios";

import { queryParamsWrapper } from "./utils";

const create = async (data: CreateContentType) => {
  const response = await cmsClient.post("/content-type/create/", data);
  return response.data;
};

const getAll = queryParamsWrapper(async (query?: string | URLSearchParams) => {
  const response = await cmsClient.get<ContentType[]>(
    `/content-type/list/${query}`
  );
  return response.data;
});

const getFormsList = async () => {
  const response = await cmsClient.get<ContentType[]>(
    "/content-type/forms-list/"
  );
  return response.data;
};

const getById = async (id: string) => {
  const response = await cmsClient.get<ContentType>(
    `/content-type/retrieve/${id}/`
  );
  return response.data;
};

const check = async (id: string) => {
  const response = await cmsClient.get<CountResponse>(
    `/content-type/count/${id}/`
  );
  return response.data;
};

const update = async (id: string, data: EditContentType) => {
  const response = await cmsClient.put(`/content-type/edit/${id}/`, data);
  return response.data;
};

const duplicate = async (id: string, data: CreateContentType) => {
  const response = await cmsClient.post(`/content-type/copy/${id}/`, data);
  return response.data;
};

const save = async (id: string, data: FormField[]) => {
  const response = await cmsClient.put(`/content-type/mappings/${id}/`, data);
  return response.data;
};

const remove = async (id: string) => {
  const response = await cmsClient.delete(`/content-type/delete/${id}/`);
  return response.data;
};

const getSidebarComponents = async () => {
  const response = await cmsClient.get<Field[]>("/content-type/components/");
  return response.data;
};

const contentType = {
  create,
  getAll,
  getFormsList,
  getById,
  check,
  update,
  duplicate,
  save,
  remove,
  getSidebarComponents
};

export const contentTypeQueries = createQueryKeys("content-type", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  formsList: () => ({
    queryKey: ["forms-list"],
    queryFn: () => getFormsList()
  }),
  byId: (contentTypeId) => ({
    queryKey: [contentTypeId],
    queryFn: () => getById(contentTypeId)
  }),
  check: (contentTypeId) => ({
    queryKey: [contentTypeId],
    queryFn: () => check(contentTypeId)
  }),
  components: () => ({
    queryKey: [""],
    queryFn: () => getSidebarComponents()
  })
});

export default contentType;
