import { Flex, Spin } from "antd";
import { styles } from "./styles";

export const LoadingScreen = () => {
  const cssStyles = styles();

  return (
    <Flex css={cssStyles.wrapper} justify="center" align="center">
      <Spin size="large" />
    </Flex>
  );
};
