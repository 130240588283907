import { Link } from "react-router-dom";
import { Alert, Card, Divider, Flex, Typography } from "antd";
import purify from "dompurify";
import { ProjectMap } from "features/projects/components";
import { Carousel } from "features/projects/components/Carousel";
import { IdeaEntity, ProjectEntity } from "types";
import { styles } from "../ExtraContent/styles";

const { Title } = Typography;

type Props = {
  data?: ProjectEntity | IdeaEntity;
};

export const DraftLayout = ({ data }: Props) => {
  const projectAttachments = data?.attachments;

  const cssStyles = styles();

  return (
    <Flex gap={8} vertical>
      {!!data?.status && (
        <Alert
          description={
            <Typography.Text
              style={{
                color: "black"
              }}
            >
              <Flex gap={4} align="center">
                Proiectul propus va fi activ dacă este adăugat spre
                <Link to="/voting">votare</Link>.
              </Flex>
            </Typography.Text>
          }
          type="info"
          showIcon
        />
      )}
      <Card>
        <Flex justify="space-between" align="center">
          <Title level={5} css={cssStyles.font18}>
            Nume Solicitant
          </Title>
          <Title level={4}>
            {data?.first_name} {data?.last_name}{" "}
          </Title>
        </Flex>
        <Divider />
        {data?.budget && (
          <>
            <Flex justify="space-between" align="center">
              <Title level={5} css={cssStyles.font18}>
                Buget
              </Title>
              <Title level={4}>{data?.budget} MDL</Title>
            </Flex>
            <Divider />
          </>
        )}
        {data?.organization && (
          <>
            <Flex justify="space-between" align="center">
              <Title level={5} css={cssStyles.font18}>
                Organizația
              </Title>
              <Title level={4}>{data?.organization}</Title>
            </Flex>
            <Divider />
          </>
        )}
        <Flex justify="space-between" align="center">
          <Title level={5} css={cssStyles.font18}>
            Date de contact
          </Title>
          <Flex vertical gap={4}>
            <Title level={4}>{data?.phone}</Title>
            <Title level={4}>{data?.email}</Title>
          </Flex>
        </Flex>
        <Divider />
        <Flex vertical gap={8}>
          <Title level={5} css={cssStyles.font18}>
            Descriere proiect
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(data?.description ?? "")
            }}
          />
        </Flex>
        <Divider />
        {projectAttachments && projectAttachments?.length > 0 && (
          <Carousel data={projectAttachments} />
        )}
        {data?.location?.lat && (
          <Flex vertical gap={24}>
            <Title level={5} css={cssStyles.font18}>
              Locația pe mapă
            </Title>
            <ProjectMap {...data?.location} />
            <Divider />
          </Flex>
        )}

        <Flex justify="space-between" align="center">
          <Title level={5} css={cssStyles.font18}>
            Adresa
          </Title>
          <Title level={4}>{data?.address}</Title>
        </Flex>
      </Card>
    </Flex>
  );
};
