export const styles = () => ({
  card: {
    ".ant-card-body": {
      "::before, ::after": {
        content: "none"
      }
    },
    body: {
      padding: "10px 24px",
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center"
    }
  }
});
