import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, message, Divider, Input, Spin } from "antd";
import { ModalProps } from "antd/es/modal";

import { ApiConfig } from "types";
import RadioButtons, { RadioButtonsProps } from "../../../RadioButtons";
import { Media, Status } from "../../FormFields/interface";
import { APIContext } from "../../context";

import FormMediaSelect from "./FormMediaSelect";
import { getListMedia } from ".";

interface MediaResponse {
  count: number;
  next: string;
  previous: string;
  results: Media[];
}

interface FormMediaSelectProps extends ModalProps {
  contentType?: string;
  visible: boolean;
  onOk: (data: any) => void;
  apiModalConfig?: ApiConfig;
}

const FormMediaSelectModal: React.FC<FormMediaSelectProps> = ({
  visible,
  onOk,
  contentType,
  apiModalConfig,
  ...props
}) => {
  // Set api config and get references from api
  const apiConfig = useContext(APIContext) || apiModalConfig;
  const [media, setMedia] = useState<MediaResponse>();
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const [loading, setLoading] = useState(false);

  const initFilters = {
    pageNumber: 1,
    pageSize: 20,
    contentType
  };

  const [filters, setFilters] = useState<any>(initFilters);

  const redioButtons: RadioButtonsProps["items"] = [
    { label: "All", value: Status.ALL },
    { label: "Published", value: Status.PUBLISHED },
    { label: "Draft", value: Status.DRAFT }
  ];

  const handleChangeFilters = (
    type: string | string[],
    value: (string | number) | (string | number)[],
    pagination = false
  ) => {
    setFilters((prevState: any) => ({
      ...prevState,
      ...(Array.isArray(type)
        ? Object.fromEntries(
            type.map((v, i) => [v, Array.isArray(value) ? value[i] : value])
          )
        : { [type]: value }),
      ...(!pagination && { pageNumber: 1 })
    }));
  };

  const fetchMediaList = useCallback(async () => {
    try {
      setLoading(true);
      if (!apiConfig) return;
      const { pageNumber, pageSize, contentType, status, ...rest } = filters;

      const query = JSON.stringify({
        page: pageNumber,
        content_type: contentType,
        page_size: pageSize,
        status: status !== Status.ALL ? status : undefined,
        ...rest
      });

      const params = new URLSearchParams(JSON.parse(query));

      const response = await getListMedia(apiConfig, params);

      setMedia(response);
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [apiConfig, filters]);

  useEffect(() => {
    if (visible) {
      fetchMediaList();
    }
  }, [visible, fetchMediaList]);

  const paginationConfig = {
    current: filters.pageNumber,
    total: media?.count || 0,
    pageSize: filters.pageSize,
    onChange: (pageNumber: number, pageSize: number) =>
      handleChangeFilters(
        ["pageNumber", "pageSize"],
        [pageNumber, pageSize],
        true
      )
  };

  const onClickCard = (file: Media) => {
    setSelectedMedia(file);
  };

  const onSave = () => {
    onOk(selectedMedia);
    setSelectedMedia(undefined);
    setFilters(initFilters);
  };

  return (
    <Modal
      title="Select media file"
      width={1200}
      open={visible}
      okButtonProps={{
        disabled: !selectedMedia
      }}
      destroyOnClose={true}
      onOk={onSave}
      {...props}
    >
      <Spin spinning={loading} size="large">
        <div className="form-media-select__filter">
          <Input.Search
            defaultValue={filters.search}
            placeholder="Search"
            allowClear
            onSearch={(value) => handleChangeFilters("search", value)}
            size="large"
          />
          <RadioButtons
            defaultValue={filters.status || Status.ALL}
            items={redioButtons}
            onChange={(value) => handleChangeFilters("status", value)}
          />
        </div>
        <Divider />
        <FormMediaSelect
          onClick={onClickCard}
          media={media?.results}
          selectedMedia={selectedMedia}
          pagination={paginationConfig}
        />
      </Spin>
    </Modal>
  );
};

export default FormMediaSelectModal;
