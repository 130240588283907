// tslint:disable max-line-length
import React from "react";

const File = () => (
  <svg height="1em" viewBox="0 0 409.6 409.6" width="1em">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m341.335938 0h-273.070313c-37.699219 0-68.265625 30.566406-68.265625 68.265625v153.597656h102.398438v187.738281h307.203124v-341.335937c0-37.699219-30.566406-68.265625-68.265624-68.265625zm-238.9375 187.734375h-68.261719v-119.46875c0-18.851563 15.28125-34.136719 34.132812-34.136719 18.851563 0 34.128907 15.289063 34.128907 34.136719zm273.066406 187.730469h-238.929688v-307.199219c0-12.5-3.609375-24.058594-9.46875-34.136719h214.269532c18.851562 0 34.128906 15.285156 34.128906 34.136719zm0 0"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m187.734375 290.136719h136.535156v34.128906h-136.535156zm0 0"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m187.734375 221.863281h136.535156v34.136719h-136.535156zm0 0"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m187.734375 153.601562h136.535156v34.132813h-136.535156zm0 0"
    />
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="m187.734375 85.335938h85.335937v34.132812h-85.335937zm0 0"
    />
  </svg>
);

export default File;
