import { css } from "@emotion/react";

export const styles = () => ({
  card: css`
    border-radius: 16px;
    width: 100%;
    min-height: 145px;
  `,
  cardBody: {
    body: {
      padding: 16,
      height: "100%"
    }
  },
  titleText: css`
    font-size: 16px;
    color: #9399a1;
  `,
  descriptionText: css`
    color: #4b5563;
  `,
  descriptionTextPositive: css`
    color: #0ed065;
  `,
  descriptionTextNegative: css`
    color: #f03d3d;
  `
});
