import { Button, Checkbox, Flex, Form, Input, Select } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { theme } from "antd/lib";
import { SurveyTypeOptions } from "features/polls/utils";
import { ChoicesForm } from "./ChoicesForm";

type Props = {
  langKey: string;
};

export const QuestionList = ({ langKey }: Props) => {
  const { token } = theme.useToken();

  return (
    <Form.List name="questions">
      {(fields, { add, remove }) => {
        return (
          <>
            <Flex justify="end" align="center">
              <Form.Item>
                <Button
                  type="text"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                  style={{
                    color: token.colorPrimary
                  }}
                >
                  Adaugă o întrebare
                </Button>
              </Form.Item>
            </Flex>

            {fields.map(({ key, name, ...restField }) => (
              <Flex vertical key={key}>
                <Form.Item
                  {...restField}
                  name={[name, `text_${langKey}`]}
                  label={`Întrebare ${langKey}`}
                  rules={[
                    {
                      required: true,
                      message: "Titlu intrebarii e obligatoriu"
                    }
                  ]}
                >
                  <Input placeholder="Titlu întrebării" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "is_required"]}
                  label="Obligatoriu de raspuns?"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label={`Tipul răspunsului ${langKey}`}
                  name={[name, "type"]}
                  rules={[
                    {
                      required: true,
                      message: "Selectati tipul de intrebare"
                    }
                  ]}
                >
                  <Select options={SurveyTypeOptions} />
                </Form.Item>

                {fields.length > 1 && (
                  <Button type="text" onClick={() => remove(name)}>
                    <MinusCircleOutlined style={{ color: "red" }} />
                    Sterge întrebarea
                  </Button>
                )}

                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues["questions"][name]?.type !==
                    currentValues["questions"][name]?.type
                  }
                  style={{
                    paddingTop: 16
                  }}
                >
                  {({ getFieldValue }) => {
                    const type = getFieldValue([["questions"], name, "type"]);
                    return type && ChoicesForm(type, name, langKey);
                  }}
                </Form.Item>
              </Flex>
            ))}
          </>
        );
      }}
    </Form.List>
  );
};
