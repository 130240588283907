import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Flex,
  Progress,
  Space,
  theme,
  Typography
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { queries } from "api";
import { BackArrow, VotingProject } from "assets/svg";
import { styles } from "features/projects/Layouts/ActiveLayout/Header/styles";
import { getColorFromStatus } from "features/projects/Layouts/ExtraContent/styles";
import { statusDisplayText } from "features/projects/utils";
import { ProjectStatus } from "types";
import { MONTH_YEAR } from "utils/dateFormats";
import { styles as projectCardStyles } from "../styles";
import { getPercentage } from "../utils";

export const VotingResults = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const cssStyles = styles();
  const { token } = theme.useToken();
  const cssStylesCard = projectCardStyles();

  const { data } = useQuery({
    ...queries.voting.resultsById(Number(id)),
    enabled: !!id
  });

  const votingPeriod = `${dayjs(data?.start_date).format(MONTH_YEAR)} - ${dayjs(
    data?.end_date
  ).format(MONTH_YEAR)}`;

  const totalResponses = data?.projects?.reduce(
    (sum, choice) => sum + choice?.total_votes,
    0
  );

  return (
    <>
      <Card styles={cssStyles.card} css={cssStyles.card} className="mb-32">
        <Space>
          <Flex align="center" gap={8}>
            <Typography.Title level={5}>Votare {votingPeriod}</Typography.Title>
          </Flex>
        </Space>

        <Space>
          <Button onClick={() => navigate(`/voting/edit/${id}`)} size="large">
            Editează
          </Button>
        </Space>
      </Card>
      <Card>
        <Flex justify="space-between" align="center">
          <Flex gap={16} align="center">
            <VotingProject />
            <Typography.Title level={5}>
              Proiecte pentru votare
            </Typography.Title>
          </Flex>
          <Flex gap={4} align="center">
            <CalendarOutlined />
            <Typography.Title level={5}>{votingPeriod}</Typography.Title>
          </Flex>
        </Flex>
        <Divider />

        <Flex vertical gap={12}>
          {data?.projects?.map((project) => (
            <Card key={project?.id}>
              <Flex gap={4} align="center" justify="space-between">
                <Flex vertical gap={4}>
                  <Typography.Title level={5}>
                    {project?.title}
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      color: "#4B5563",
                      fontSize: 12
                    }}
                  >
                    {votingPeriod}
                  </Typography.Text>
                </Flex>

                <Flex gap={24} align="center" justify="center">
                  <Flex gap={10} css={cssStylesCard.tag} align="center">
                    {project?.status !== ProjectStatus.DRAFT && (
                      <div
                        css={cssStylesCard.dot}
                        style={{
                          backgroundColor: getColorFromStatus(project?.status)
                        }}
                      />
                    )}
                    <Typography.Text>
                      Proiect{" "}
                      {
                        statusDisplayText[
                          project?.status ?? ProjectStatus.DRAFT
                        ]
                      }
                    </Typography.Text>
                  </Flex>
                  <Flex
                    vertical
                    style={{
                      minWidth: 120
                    }}
                  >
                    <Typography.Text strong>
                      {project?.total_votes} / {totalResponses} voturi
                    </Typography.Text>
                    <Progress
                      percent={getPercentage(
                        project?.total_votes,
                        totalResponses
                      )}
                      css={cssStylesCard.progress}
                      strokeColor={token.colorPrimary}
                    />
                  </Flex>

                  <Link
                    to={`/projects/view/${project?.id}`}
                    style={{
                      color: "inherit"
                    }}
                  >
                    <BackArrow />
                  </Link>
                </Flex>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Card>
    </>
  );
};
