import { Login, Reset, SetPassword, Tokens } from "types";

import { ssoClient } from "./axios";

const authorization = {
  login: async (data: Login) => {
    const response = await ssoClient.post<Tokens>("/auth/initiate-login", data);

    return response.data;
  },

  confirmLogin: async (data: Login) => {
    const response = await ssoClient.post<Tokens>("/auth/confirm-login", data);

    return response.data;
  },

  register: async (data: Login) => {
    const response = await ssoClient.post<Tokens>(
      "/auth/initiate-registration",
      data
    );

    return response.data;
  },

  confirmRegister: async (data: Omit<Login, "password">) => {
    const response = await ssoClient.post<Tokens>(
      "/auth/confirm-registration",
      data
    );

    return response.data;
  },

  resetPassword: async (data: Omit<Login, "password">) => {
    const response = await ssoClient.post<void>("/auth/reset-password", data);

    return response.data;
  },

  setPassword: async (data: SetPassword) => {
    const response = await ssoClient.post<void>("/auth/set-password", data);

    return response.data;
  },

  verifyOTPCode: async (data: Reset) => {
    const response = await ssoClient.post<void>("/auth/verify-otp-code", data);

    return response.data;
  },

  tokenRefresh: async (data: { refresh: string }) => {
    const response = await ssoClient.post<Tokens>("/auth/token/refresh", data);

    return response.data;
  }
};

export default authorization;
