import { useParams } from "react-router-dom";
import { Card, Divider, Flex, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { MONTH_YEAR } from "utils/dateFormats";
import { Choices, TextAnswers } from "../components";
import { styles } from "../styles";

const { Title } = Typography;

export const PollDetails = () => {
  const { id } = useParams();

  const cssStyles = styles();

  const { data, isLoading } = useQuery({
    ...queries.polls.resultsById(id)
  });

  const StyledTitle = ({ title }: { title: string }) => (
    <Title css={cssStyles.font18}>{title}</Title>
  );

  const formmatDate = (start?: string, end?: string) => {
    return `${dayjs(start).format(MONTH_YEAR)} - ${dayjs(end).format(
      MONTH_YEAR
    )}`;
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Card
      css={cssStyles.card}
      style={{
        maxWidth: "70%"
      }}
    >
      <Flex vertical gap={8}>
        <StyledTitle title="Titlu Sondaj" />
        <Title level={3}>{data?.title}</Title>
      </Flex>

      <Divider />
      <Flex vertical gap={8}>
        <StyledTitle title="Perioada sondaj" />
        <Title level={3}>{formmatDate(data?.start_date, data?.end_date)}</Title>
      </Flex>

      <Divider />
      <Flex vertical gap={8}>
        <StyledTitle title="Descriere" />
        <Title level={4}>{data?.description}</Title>
      </Flex>

      <Divider />
      <Flex vertical>
        <Flex
          style={{
            marginBottom: 24
          }}
        >
          <StyledTitle title="Rezultate" />
        </Flex>
        {data?.questions?.map((question, index) => (
          <Flex vertical key={question?.id} gap={32}>
            {question?.choices && (
              <>
                <Title level={4}>
                  {index + 1}. {question?.text}
                </Title>
                <Choices choices={question?.choices} />
              </>
            )}

            {question?.type === "text" && (
              <>
                <Title level={4}>
                  {index + 1}. {question?.text}
                </Title>
                <TextAnswers question={question} />
              </>
            )}
            <Divider />
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};
