import { Button, Dropdown, Flex, MenuProps, Switch, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import moment from "moment";
import { roleDisplayText } from "features/users/utils";
import { UserEntity, UserRole } from "types";
import { compareDates, DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

const getTitleColor = (is_active: boolean) => {
  return {
    style: {
      color: is_active ? "black" : "#9CA3AF"
    },
    strong: is_active ? true : false
  };
};

export const getTableColumns = (
  getActionColumnMenu: (content: UserEntity) => MenuProps["items"],
  onSwitch: (content: UserEntity) => void,
  userRole: UserRole
) => {
  const columns: ColumnProps<UserEntity>[] = [
    {
      title: "ID",
      key: "id",
      render: ({ id, is_active }) => (
        <Typography.Text {...getTitleColor(is_active)}>{id}</Typography.Text>
      ),
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Nume Prenume",
      key: "first_name",
      render: ({ first_name, last_name, is_active }) => (
        <Typography.Text {...getTitleColor(is_active)}>
          {first_name ? `${first_name}   ${last_name}` : "-"}
        </Typography.Text>
      ),
      sorter: (a, b) =>
        (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name)
    },
    {
      title: "Adresa de e-mail",
      key: "email",
      render: ({ email, is_active }) => (
        <Typography.Text {...getTitleColor(is_active)}>{email}</Typography.Text>
      ),
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    ...(userRole === UserRole.EMPLOYEE
      ? [
          {
            title: "Rol",
            key: "role",
            render: ({ role, is_active }) => (
              <Typography.Text {...getTitleColor(is_active)}>
                {roleDisplayText[role]}
              </Typography.Text>
            )
          }
        ]
      : []),
    {
      title: "Ultima activitate",
      key: "last_login",
      render: ({ last_login, is_active }) => (
        <Typography.Text {...getTitleColor(is_active)}>
          {last_login
            ? moment(last_login).format(DAY_MONTH_YEAR_HOUR_MINUTE)
            : "-"}{" "}
        </Typography.Text>
      ),
      sorter: (a: UserEntity, b: UserEntity) => {
        return compareDates(dayjs(a.last_login), dayjs(b.last_login));
      }
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      key: "actions",
      render: (_, item) => (
        <Flex align="center" justify="right" gap={8}>
          <Switch
            checked={item.is_active}
            onChange={() => onSwitch(item)}
            size="small"
          />
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            menu={{ items: getActionColumnMenu(item) }}
          >
            <Button icon={<EllipsisOutlined />} type="text" />
          </Dropdown>
        </Flex>
      )
    }
  ];

  return columns;
};
