import { actionsQueries as actions } from "./actions";
import { contentQueries as content } from "./content";
import { contentTypeQueries as contentType } from "./content-type";
import { formsQueries as forms } from "./forms";
import { ideasQueries as ideas } from "./ideas";
import { localesQueries as locales } from "./locales";
import { mediaQueries as media } from "./media";
import { notificationsQueries as notifications } from "./notifications";
import { pollQueries as polls } from "./polls";
import { profileQueries as profile } from "./profile";
import { projectDomainQueries as projectDomains } from "./projectDomains";
import { projectQueries as projects } from "./projects";
import { settingsQueries as settings } from "./settings";
import { usersQueries as users } from "./users";
import { votingQueries as voting } from "./voting";

export const queries = {
  actions,
  content,
  contentType,
  forms,
  locales,
  media,
  settings,
  users,
  notifications,
  projects,
  projectDomains,
  ideas,
  polls,
  voting,
  profile
};
