import { css } from "@emotion/react";

export const styles = () => ({
  card: {
    body: {
      padding: 16
    }
  },
  font18: css`
    font-size: 18px !important;
    font-weight: 500 !important;
  `
});
