import { Key } from "react";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { InviteUser, Paginated, UserEntity } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<UserEntity[]>>("/cms/users", {
    params: queryParams
  });

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<UserEntity>(`/cms/users/${id}`);

  return data;
};

const update = async (id: number, userData: UserEntity) => {
  const { data } = await ssoClient.patch(`/cms/users/${id}`, userData);

  return data;
};

const deleteUser = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/users/${id}`);

  return data;
};

const activate = async (id: number) => {
  const { data } = await ssoClient.patch(`/cms/users/${id}/activate`);

  return data;
};

const deactivate = async (id: number) => {
  const { data } = await ssoClient.patch(`/cms/users/${id}/deactivate`);

  return data;
};

const bulkDelete = async (id: Key[]) => {
  const { data } = await ssoClient.delete(`/cms/users/bulk-delete`, {
    data: { ids: id }
  });

  return data;
};

const getStats = async () => {
  const { data } = await ssoClient.get(`/cms/users/stats`);

  return data;
};

const invite = async (userData: InviteUser) => {
  const { data } = await ssoClient.post(`/cms/users`, userData);

  return data;
};

const users = {
  getAll,
  getById,
  update,
  deleteUser,
  activate,
  deactivate,
  bulkDelete,
  getStats,
  invite
};

export const usersQueries = createQueryKeys("users", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  getStats: () => ({
    queryKey: ["stats"],
    queryFn: () => getStats()
  })
});

export default users;
