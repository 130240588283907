const ProjectIcon = () => {
  return (
    <svg
      width={57}
      height={56}
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333374 16C0.333374 7.16344 7.49682 0 16.3334 0H40.3334C49.1699 0 56.3334 7.16344 56.3334 16V40C56.3334 48.8366 49.1699 56 40.3334 56H16.3334C7.49682 56 0.333374 48.8366 0.333374 40V16Z"
        fill="#EFF6FF"
      />
      <path
        d="M38.3334 24.52V19.98C38.3334 18.57 37.6934 18 36.1034 18H32.0634C30.4734 18 29.8334 18.57 29.8334 19.98V24.51C29.8334 25.93 30.4734 26.49 32.0634 26.49H36.1034C37.6934 26.5 38.3334 25.93 38.3334 24.52Z"
        fill="#3B82F6"
      />
      <path
        d="M38.3334 35.77V31.73C38.3334 30.14 37.6934 29.5 36.1034 29.5H32.0634C30.4734 29.5 29.8334 30.14 29.8334 31.73V35.77C29.8334 37.36 30.4734 38 32.0634 38H36.1034C37.6934 38 38.3334 37.36 38.3334 35.77Z"
        fill="#3B82F6"
      />
      <path
        d="M26.8334 24.52V19.98C26.8334 18.57 26.1934 18 24.6034 18H20.5634C18.9734 18 18.3334 18.57 18.3334 19.98V24.51C18.3334 25.93 18.9734 26.49 20.5634 26.49H24.6034C26.1934 26.5 26.8334 25.93 26.8334 24.52Z"
        fill="#3B82F6"
      />
      <path
        d="M26.8334 35.77V31.73C26.8334 30.14 26.1934 29.5 24.6034 29.5H20.5634C18.9734 29.5 18.3334 30.14 18.3334 31.73V35.77C18.3334 37.36 18.9734 38 20.5634 38H24.6034C26.1934 38 26.8334 37.36 26.8334 35.77Z"
        fill="#3B82F6"
      />
    </svg>
  );
};

export default ProjectIcon;
