import { css } from "@emotion/react";

export const styles = () => ({
  upload: css`
    .ant-upload {
      width: 100%;
    }
  `,
  uploadBox: css`
    border: 1px dashed #d1d5db;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    height: 100px;
    gap: 4px;
    cursor: pointer;
  `,
  fileContainer: css`
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
  `,
  image: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `
});
