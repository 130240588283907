import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Paginated, ProjectFields } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<ProjectFields[]>>(
    "/cms/fields",
    {
      params: queryParams
    }
  );

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<ProjectFields>(`/cms/fields/${id}`);

  return data;
};

const update = async (id: number, field: ProjectFields) => {
  const { data } = await ssoClient.patch(`/cms/fields/${id}`, field);

  return data;
};

const deleteDomain = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/fields/${id}`);

  return data;
};

const create = async (fields: ProjectFields) => {
  const { data } = await ssoClient.post(`/cms/fields`, fields);

  return data;
};

const projectDomains = {
  getAll,
  getById,
  update,
  create,
  deleteDomain
};

export const projectDomainQueries = createQueryKeys("projectDomains", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  })
});

export default projectDomains;
