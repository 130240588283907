import { css } from "@emotion/react";

export const formStyles = () => ({
  row: {
    padding: "16px 0"
  },

  password_block: {
    maxWidth: 300
  },
  form_item: css`
    width: 100%;
    margin-bottom: 0;
  `,
  password_input: {
    marginBottom: 0
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#171cb3",
    color: "white"
  }
});
