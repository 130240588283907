export type Settings = Record<SettingsNamespace.codename, SettingValue>;

export type SettingValue = {
  value: string;
  value_type: SettingsNamespace.value_type;
};

export type SettingsPayload = {
  codename: SettingsNamespace.codename;
} & SettingValue;

export namespace SettingsNamespace {
  export enum codename {
    ACCOUNT_INACTIVITY_DELETION_DAYS = "account_inactivity_deletion_days",
    ACCOUNT_DELETION_WARNING_DAYS = "account_deletion_warning_days",
    SUPPORT_EMAIL = "support_email",
    EMAIL_DEFAULT_FROM = "email_default_from",
    EMAIL_USE_TLS = "email_use_tls",
    EMAIL_USE_SSL = "email_use_ssl",
    EMAIL_HOST = "email_host",
    EMAIL_PORT = "email_port",
    EMAIL_USERNAME = "email_username",
    EMAIL_PASSWORD = "email_password"
  }

  export enum value_type {
    NUMBER = "number",
    STRING = "string",
    BOOLEAN = "boolean"
  }
}
