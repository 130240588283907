import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, Card, Flex, Menu } from "antd";
import ProLayout, { PageContainer } from "@ant-design/pro-layout";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { isFirstLoginKey, queries, removeLocalStorageTokens } from "api";
import { Logo } from "assets/svg";
import { LoadingScreen } from "components/LoadingScreen";
import { TourGuide } from "components/TourGuide";
import { proLayoutTheme } from "theme";
import { UserRole } from "types";
import { contentTypeCategories, menuIcons, showErrorMessage } from "utils";
import { UserProfile } from "./UserProfile";
import { styles } from "./styles";
import { useActivePathname } from "./useActivePathname";

const {
  ContentTypes,
  Media,
  Locales,
  Records,
  Settings,
  Users,
  Notifications,
  Projects,
  Statistics,
  Polls,
  Voting,
  Logout,
  User
} = menuIcons;

export const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const activeKeys = useActivePathname();

  const searchParam = queryString.parse(search);

  const { data: sidebarComponents = [] } = useQuery({
    ...queries.content.pinned(),
    onError: (error) => showErrorMessage(error)
  });

  const onLogout = () => {
    removeLocalStorageTokens();
    navigate("/authentication/login");
  };

  const { data, isLoading } = useQuery({
    ...queries.profile.profile(),
    retry: false,
    onError: () => onLogout()
  });

  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(isFirstLoginKey) !== "false") {
      setIsTourOpen(true);
      localStorage.setItem(isFirstLoginKey, "false");
    }
  }, []);

  const onTourClose = () => {
    localStorage.setItem(isFirstLoginKey, "false");
    setIsTourOpen(false);
  };

  const onTourOpen = () => {
    setIsTourOpen(true);
    navigate("statistics");
  };
  const defaultMenuItems = useMemo(
    () => [
      {
        icon: Statistics,
        key: "statistics",
        label: <Link to="/statistics">Panou de statistică</Link>
      },
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              icon: Users,
              key: "users",
              label: <Link to="/users/citizens">Management utilizatori</Link>
            }
          ]
        : []),
      {
        icon: Voting,
        key: "voting",
        label: <Link to="/voting">Management votări</Link>
      },

      {
        icon: Projects,
        key: "projects",
        label: <Link to="/projects">Proiecte</Link>
      },
      {
        key: "polls",
        icon: Polls,
        label: <Link to="/polls">Sondaje</Link>
      },
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              key: "content-types",
              icon: ContentTypes,
              label: "Tipuri de conținut",
              onClick: () => navigate("/content-types"),
              onTitleClick: () => navigate("/content-types"),
              children: contentTypeCategories.map(({ label, value }) => ({
                key: value,
                label: (
                  <Link
                    to={queryString.stringifyUrl({
                      url: "/content-types",
                      query: {
                        type: value,
                        ...(pathname === "/content-types" && {
                          status: searchParam.status
                        })
                      }
                    })}
                  >
                    {label}
                  </Link>
                )
              }))
            }
          ]
        : []),

      {
        key: "content",
        icon: Records,
        label: "Conținut",
        onTitleClick: () => navigate("/content"),
        children: sidebarComponents?.map(({ id, name, description }) => ({
          key: String(id),
          label: <Link to={`/content?type=${id}`}>{name || description}</Link>
        }))
      },

      {
        icon: Media,
        key: "media",
        label: <Link to="/media">Media</Link>
      },
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              icon: Locales,
              key: "locales",
              label: <Link to="/locales">Limbi</Link>
            },

            {
              icon: Settings,
              key: "settings",
              label: <Link to="/settings">Setări</Link>
            }
          ]
        : []),
      {
        icon: Notifications,
        key: "notifications",
        label: <Link to="/notifications">Notificări</Link>
      },
      {
        icon: User,
        key: "profile",
        label: <Link to="/profile">Profil</Link>
      }
    ],
    [sidebarComponents, navigate, pathname, searchParam, data?.role]
  );

  const cssStyles = styles();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ProLayout
      title="Smart Edineț"
      logo={<Logo />}
      menuContentRender={() => (
        <Menu
          items={defaultMenuItems}
          defaultOpenKeys={activeKeys}
          defaultSelectedKeys={activeKeys}
          mode="inline"
          inlineIndent={24}
          css={cssStyles.siderbar}
        />
      )}
      collapsedButtonRender={false}
      siderWidth={290}
      fixSiderbar
      layout="side"
      splitMenus={false}
      navTheme="light"
      contentWidth="Fluid"
      location={{
        pathname
      }}
      token={proLayoutTheme}
      avatarProps={{
        render: () => (
          <Menu
            items={[
              {
                icon: Logout,
                key: "logout",
                label: "Ieșire din cont",
                onClick: onLogout
              }
            ]}
            mode="inline"
            inlineIndent={24}
            css={cssStyles.siderbar}
          />
        )
      }}
    >
      <PageContainer
        title={false}
        style={{
          backgroundColor: "#f7f7f7"
        }}
      >
        <Card
          styles={cssStyles.pageHeaderCardBody}
          css={cssStyles.pageHeaderCard}
        >
          <Flex gap={8}>
            <Button type="primary" onClick={onTourOpen}>
              Începeți ghidul
            </Button>
            <UserProfile />
          </Flex>
        </Card>
        <div css={cssStyles.content}>{children}</div>
      </PageContainer>
      <TourGuide open={isTourOpen} onClose={onTourClose} />
    </ProLayout>
  );
};
