import { Key, useEffect, useState } from "react";
import { Button, Flex, Input, Radio, Row, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import api, { queries } from "api";
import {
  DeleteButton,
  DeleteConfirmModal,
  TableOrderingSelect
} from "components";
import {
  getProjectCreateYears,
  sortByProjectStatusOptions,
  sortProjectDirectionOptions,
  sortProjectsByDomains
} from "features/projects/utils/utils";
import { ProjectStatus } from "types";
import { resetPagination, showErrorMessage, useTablePagination } from "utils";
import { YEAR_FORMAT } from "utils/dateFormats";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { DomainModal } from "../DomainModal";
import { getProjectsTableColumns } from "../tableColumns";

type Props = {
  initialStatus: ProjectStatus;
};

export const ProjectsTable = ({ initialStatus }: Props) => {
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useState("-updated_at");
  const [status, setStatus] = useState<ProjectStatus>(initialStatus);
  const [domain, setDomain] = useState("");
  const [createDate, setCreateDate] = useState("");

  const [projectDates, setProjectDates] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const [isDomainModalVisible, setIsDomainModalVisible] = useState(false);

  useEffect(() => {
    setSearch("");
    setStatus(initialStatus);
    setDomain("");
    setCreateDate("");
  }, [initialStatus]);

  const pagination = useTablePagination();

  const queryParams = {
    ordering,
    page: pagination.current,
    per_page: pagination.pageSize,
    field: domain,
    year: createDate,
    search,
    status
  };

  const isDraft = initialStatus === ProjectStatus.DRAFT;

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.projects.all(stringifiedParams)
  });

  const { data: projectDomainList } = useQuery({
    ...queries.projectDomains.all()
  });

  const projectDomains = sortProjectsByDomains(
    projectDomainList?.results ?? []
  );

  const dateFilters = getProjectCreateYears(projectDates ?? []);

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const onStatusChange = (value: ProjectStatus) => {
    setStatus(value);
    setSelectedRowKeys([]);
    resetPagination(pagination);
  };

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: (ids: Key[]) => api.projects.bulkDelete(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.projects.all());
      setSelectedRowKeys([]);
    },
    onError: showErrorMessage
  });

  const onSelectRowChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange
  };

  const onBulkDelete = () => {
    const ids = rowSelection?.selectedRowKeys.map((key) => key);

    const onOk = () => bulkDelete(ids);
    DeleteConfirmModal(onOk, true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if (data?.results && !projectDates.length) {
      const dates = data?.results?.map((project) =>
        moment(project?.created_at).format(YEAR_FORMAT)
      );
      setProjectDates(dates ?? []);
    }
  }, [data, projectDates]);

  return (
    <>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          className="mw-300"
          placeholder="Caută proiect..."
          size="large"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex
          gap={8}
          align="center"
          justify="center"
          id="projects-table-filters"
        >
          {hasSelected && (
            <DeleteButton
              loading={isBulkDeleteLoading}
              onClick={onBulkDelete}
            />
          )}

          {!isDraft && (
            <Radio.Group
              defaultValue={status}
              options={sortByProjectStatusOptions.slice(1)}
              buttonStyle="solid"
              size="large"
              optionType="button"
              onChange={(value) => onStatusChange(value.target.value)}
            />
          )}

          <TableOrderingSelect
            defaultValue={dateFilters?.[0]?.value}
            options={dateFilters}
            onChange={(value) => setCreateDate(value)}
          />

          <TableOrderingSelect
            defaultValue={projectDomains?.[0]?.value}
            options={projectDomains}
            onChange={(value) => setDomain(value)}
          />

          <TableOrderingSelect
            defaultValue={sortProjectDirectionOptions?.[0]?.value}
            options={sortProjectDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />

          <Button size="large" onClick={() => setIsDomainModalVisible(true)}>
            <PlusCircleOutlined /> Adauga un domeniu
          </Button>
        </Flex>
      </Row>

      <Table
        rowKey="id"
        loading={isLoading}
        columns={getProjectsTableColumns(isDraft)}
        dataSource={data?.results ?? []}
        bordered
        rowSelection={rowSelection}
        pagination={{ ...pagination, total: data?.count }}
      />

      <DomainModal
        open={isDomainModalVisible}
        onCancel={() => setIsDomainModalVisible(false)}
      />
    </>
  );
};
