import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Typography
} from "antd";
import { useMutation } from "@tanstack/react-query";
import api from "api";
import { CheckPassword } from "features/authentication/components";
import { formStyles } from "features/profile/styles";
import { PasswordData } from "types";
import { showErrorMessage } from "utils";

export const PasswordForm = () => {
  const [form] = Form.useForm();

  const cssStyles = formStyles();

  const { mutate: updatePassword, isLoading } = useMutation({
    mutationFn: (values: PasswordData) => api.profile.updatePassword(values),
    onSuccess: () => {
      notification.success({
        message: "Parola a fost actualizată cu succes",
        duration: 3
      });
      form.resetFields();
    },
    onError: showErrorMessage
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      updatePassword(values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <Card>
        <Flex>
          <Flex flex={1} vertical>
            <Typography.Text strong>Parola</Typography.Text>
            <Typography.Text>Actualizați parola.</Typography.Text>
          </Flex>
          <Flex flex={1} vertical>
            <Col xs={24} lg={14}>
              <Flex vertical gap={16}>
                <Form.Item<PasswordData>
                  name={"current_password"}
                  label="Parola Curenta"
                  rules={[
                    {
                      required: true,
                      message: "Introduceți parola curentă"
                    }
                  ]}
                  css={cssStyles.form_item}
                >
                  <Input.Password
                    placeholder="Parola Curenta"
                    autoComplete="off"
                    size="large"
                  />
                </Form.Item>
                <Form.Item<PasswordData>
                  name="new_password"
                  label="Parola Nouă"
                  css={cssStyles.form_item}
                  rules={[
                    {
                      required: true,
                      message: "Introduceți parola nouă"
                    }
                  ]}
                >
                  <Input.Password placeholder="Parola Nouă" size="large" />
                </Form.Item>
                <Form.Item<PasswordData>
                  name="confirm_password"
                  label="Repetați Parola Nouă"
                  rules={[
                    { required: true, message: "Introduceți parola nouă" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Parolele nu se potrivesc.")
                        );
                      }
                    })
                  ]}
                  css={cssStyles.form_item}
                >
                  <Input.Password
                    placeholder="Repetați Parola Nouă"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.new_password !== curValues.new_password
                  }
                >
                  {({ getFieldValue }) => {
                    const newPassword = getFieldValue("new_password");
                    return newPassword ? (
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.new_password !== curValues.new_password
                        }
                      >
                        <CheckPassword value={newPassword} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Flex>
            </Col>
          </Flex>
        </Flex>
        <Flex justify="flex-end" gap={8}>
          <Button
            size="large"
            onClick={() => {
              form.resetFields();
            }}
          >
            Anulează
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
            onClick={onSubmit}
          >
            Salvează
          </Button>
        </Flex>
      </Card>
    </Form>
  );
};
