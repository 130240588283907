import { css } from "@emotion/react";

export const styles = () => ({
  card: css`
    margin-top: 12px;
    .ant-card-body {
      padding: 8px 12px;
    }
  `,
  wrapper: css`
    margin-top: 60px;
  `,
  trashIcon: css`
    color: red;
    cursor: pointer;
  `,
  inputWrapper: css`
    margin-top: 12px;
  `,
  editButton: css`
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  `
});
