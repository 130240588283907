import {
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Form,
  Select,
  Typography
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { RangePickerProps } from "antd/es/date-picker";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import { queries } from "api";
import { VotingProject } from "assets/svg";
import { LoadingScreen } from "components/LoadingScreen";
import { CreateVotePayload, ProjectEntity, ProjectStatus } from "types";

const { RangePicker } = DatePicker;

type Props = {
  form: FormInstance<CreateVotePayload>;
};

export const CreateForm = ({ form }: Props) => {
  const { data, isLoading } = useQuery({
    ...queries.projects.all({
      status: ProjectStatus.DRAFT
    })
  });

  const selectedProjects = Form.useWatch("projects", form);

  const projectOptions = data?.results
    ?.filter(
      (project: ProjectEntity) =>
        !selectedProjects?.find(
          (selectedProject) => selectedProject?.id === project?.id
        )
    )
    .map((project: ProjectEntity) => ({
      label: project?.title,
      value: project?.id
    }));

  const onSelectChange = (value: number) => {
    const project = data?.results?.find(
      (project: ProjectEntity) => project?.id === value
    );

    if (project) {
      form.setFieldsValue({
        projects: [
          ...selectedProjects,
          { id: project?.id, title: project?.title }
        ]
      });
    }
  };

  const onDelete = (id?: number) => {
    form.setFieldsValue({
      projects: selectedProjects.filter((project) => project.id !== id)
    });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && dayjs(current).startOf("day") < dayjs().startOf("day");
  };

  const onSearch = (value: string, option: any) => {
    return option.label.toLowerCase().includes(value.toLowerCase());
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Card
      style={{
        maxWidth: 500
      }}
    >
      <Form
        form={form}
        requiredMark={false}
        initialValues={{
          projects: []
        }}
      >
        <Flex vertical>
          <Flex gap={16} align="center">
            <VotingProject />
            <Typography.Title level={5}>
              Proiecte pentru votare
            </Typography.Title>
          </Flex>
          <Divider />
          <Flex gap={12} vertical>
            <Form.Item
              name="projects"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                options={projectOptions}
                placeholder="Selectează din listă"
                onChange={onSelectChange}
                size="large"
                showSearch
                filterOption={onSearch}
                autoClearSearchValue
                labelRender={() => (
                  <Typography.Text
                    strong
                    style={{
                      color: "#9CA3AF"
                    }}
                  >
                    Selectează din listă
                  </Typography.Text>
                )}
              />
            </Form.Item>
            {selectedProjects?.map((project) => (
              <Card
                key={project.id}
                styles={{
                  body: {
                    padding: "8px 12px"
                  }
                }}
              >
                <Flex align="center" justify="space-between">
                  <Typography.Text>{project.title}</Typography.Text>
                  <Button
                    danger
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => onDelete(project?.id)}
                  />
                </Flex>
              </Card>
            ))}
          </Flex>

          <Divider />
          <Form.Item
            name="period"
            rules={[
              {
                required: true
              }
            ]}
          >
            <RangePicker
              size="large"
              disabledDate={disabledDate}
              variant="filled"
              style={{
                width: "100%"
              }}
            />
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};
