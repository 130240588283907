import {
  Card,
  Flex,
  List,
  Modal,
  ModalProps,
  Pagination,
  Spin,
  Typography
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { QuestionEntity } from "types";
import { useTablePagination } from "utils";
import { styles } from "../TextAnswers/styles";

const MAX_PER_PAGE = 4;

type Props = {
  question?: QuestionEntity;
} & ModalProps;

export const TextsModal = ({ question, open, onCancel }: Props) => {
  const pagination = useTablePagination();

  const queryParams = {
    question: question?.id,
    page: pagination?.current,
    per_page: MAX_PER_PAGE
  };

  const { data, isLoading } = useQuery({
    ...queries.polls.textResultsById(queryParams)
  });

  const cssStyles = styles();

  return (
    <Modal
      destroyOnClose
      centered
      open={open}
      onCancel={onCancel}
      footer={
        <Pagination
          {...pagination}
          pageSize={MAX_PER_PAGE}
          total={data?.count}
        />
      }
      styles={{
        content: {
          minWidth: 700
        },
        body: {
          minHeight: 500
        }
      }}
    >
      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin />
        </Flex>
      ) : (
        <Flex vertical gap={24}>
          <Flex justify="space-between" align="center">
            <Typography.Text
              strong
              style={{
                fontSize: 18
              }}
            >
              {question?.text}
            </Typography.Text>
          </Flex>

          <List
            dataSource={data?.results}
            renderItem={(item) => (
              <Card css={cssStyles.modalCard}>
                <Flex align="center">{item?.text}</Flex>
              </Card>
            )}
          />
        </Flex>
      )}
    </Modal>
  );
};
