import { Key } from "react";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Paginated, ProjectChart, ProjectEntity } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<ProjectEntity[]>>(
    "/cms/projects",
    {
      params: queryParams
    }
  );

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<ProjectEntity>(`/cms/projects/${id}`);

  return data;
};

const getChart = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<ProjectChart[]>("/cms/projects/chart", {
    params: queryParams
  });

  return data;
};

const getStats = async () => {
  const { data } = await ssoClient.get(`/cms/projects/stats`);

  return data;
};

const update = async (id: number, project: ProjectEntity) => {
  const { data } = await ssoClient.patch(`/cms/projects/${id}`, project);

  return data;
};

const deleteProject = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/projects/${id}`);

  return data;
};

const bulkDelete = async (id: Key[]) => {
  const { data } = await ssoClient.delete(`/cms/projects/bulk-delete`, {
    data: { ids: id }
  });

  return data;
};

const projects = {
  getAll,
  getById,
  getChart,
  getStats,
  update,
  deleteProject,
  bulkDelete
};

export const projectQueries = createQueryKeys("projects", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  getChart: (params = undefined) => ({
    queryKey: [{ list: "chart", ...params }],
    queryFn: () => getChart(params)
  }),

  getStats: () => ({
    queryKey: ["stats"],
    queryFn: getStats
  })
});

export default projects;
