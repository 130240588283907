import { Link } from "react-router-dom";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { AuthLayout, FormWrapper, PageTag } from "../components";

export const SuccessSetPasswordPage = () => {
  return (
    <AuthLayout>
      <FormWrapper
        title="Parola a fost resetată cu succes"
        subTitle="Parola resetată cu succes, încercați să vă autentificați cu o nouă parolă."
        topStep={<PageTag icon={<CheckOutlined />} status="success" />}
      >
        <Link to="/authentication/login">
          <Button
            type="primary"
            block
            size="large"
            style={{
              padding: "12px 16px",
              height: "48px"
            }}
          >
            Înapoi la Autentificare
          </Button>
        </Link>
      </FormWrapper>
    </AuthLayout>
  );
};
