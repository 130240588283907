import { useState } from "react";
import { TablePaginationConfig } from "antd";

export const DEFAULT_PER_PAGE = 10;

export const useTablePagination = (): TablePaginationConfig => {
  const [pageSize, setPageSize] = useState([1, DEFAULT_PER_PAGE]);

  return {
    showSizeChanger: false,
    hideOnSinglePage: true,
    current: pageSize[0],
    pageSize: pageSize[1],
    onChange: (current: number, pageSize: number) => {
      setPageSize([current, pageSize]);
    },
    position: ["bottomLeft"]
  };
};

export const resetPagination = (pagination: TablePaginationConfig) => {
  pagination.onChange?.(1, DEFAULT_PER_PAGE);
};
