import { theme } from "antd";

import { styles } from "./styles";

type Status = "primary" | "success";

interface PageTagProps {
  icon?: React.ReactNode;
  status?: Status;
}

export const PageTag = ({ icon, status = "primary" }: PageTagProps) => {
  const { token } = theme.useToken();

  const statusColor: Record<
    Status,
    { color: string; bg: string; borderColor: string }
  > = {
    primary: {
      color: token.colorPrimary,
      bg: token.colorPrimaryBorder,
      borderColor: token.colorPrimaryBgHover
    },
    success: {
      color: token.colorSuccessActive,
      bg: token.colorSuccessBg,
      borderColor: token.colorSuccessBorder
    }
  };

  const cssStyle = styles(token);

  const statusType = statusColor[status];

  return (
    <div
      css={cssStyle.pageTag}
      style={{
        color: statusType.color,
        backgroundColor: statusType.bg,
        borderColor: statusType.borderColor
      }}
    >
      {icon}
    </div>
  );
};
