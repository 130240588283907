import { Link } from "react-router-dom";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { statusDisplayText } from "features/projects/utils/utils";
import { ProjectEntity } from "types";
import { DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

export const getProjectsTableColumns = (isDraft: boolean) => {
  const columns: ColumnProps<ProjectEntity>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Titlu",
      key: "title",
      render: ({ id, title }) => (
        <Link to={`/projects/view/${id}`}>{title}</Link>
      )
    },
    ...(!isDraft
      ? [
          {
            title: "Domeniu",
            key: "field",
            dataIndex: ["field", "name"]
          },
          {
            title: "Nume Prenume",
            key: "status",
            render: ({ first_name, last_name }) => `${first_name} ${last_name}`
          },
          {
            title: "Email",
            key: "email",
            dataIndex: "email"
          },
          {
            title: "Budget",
            key: "budget",
            render: ({ budget }) => `${budget} MDL`
          }
        ]
      : []),
    {
      title: "Actualizat la",
      key: "updated_at",
      render: ({ updated_at }) =>
        updated_at ? moment(updated_at).format(DAY_MONTH_YEAR_HOUR_MINUTE) : "-"
    },
    ...(isDraft
      ? [
          {
            title: "Statut",
            key: "status",
            render: ({ status }) => statusDisplayText[status]
          }
        ]
      : [])
  ];

  return columns;
};
