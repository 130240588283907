import { ThemeConfig } from "antd";
import { ProLayoutProps } from "@ant-design/pro-layout/es/ProLayout";

export const defaultTheme: ThemeConfig = {
  token: {
    fontFamily: '"Inter", sans-serif',
    colorPrimary: "#3B82F6",
    colorInfo: "#171cb3",
    colorSuccess: "#1c9451",
    colorWarning: "#e8822e",
    colorError: "#ee3633",
    colorTextBase: "#232323",
    colorSuccessActive: "#0ED065",
    colorSuccessBg: "#AFFAD0",
    colorSuccessBorder: "#E3FFEE",
    fontSize: 14,
    sizeStep: 4,
    borderRadius: 8,
    boxShadow:
      "0px 3px 6px 0px rgba(0, 0, 0, 0.05);\n0px 10px 10px 0px rgba(0, 0, 0, 0.04);\n0px 23px 14px 0px rgba(0, 0, 0, 0.03);\n0px 41px 16px 0px rgba(0, 0, 0, 0.01);\n0px 64px 18px 0px rgba(0, 0, 0, 0);",
    boxShadowSecondary:
      "\n0px 5px 10px 0px rgba(0, 0, 0, 0.1);\n0px 19px 19px 0px rgba(0, 0, 0, 0.09);\n0px 42px 25px 0px rgba(0, 0, 0, 0.05);\n0px 75px 30px 0px rgba(0, 0, 0, 0.01);\n0px 117px 33px 0px rgba(0, 0, 0, 0);\n"
  },
  components: {
    Form: {
      itemMarginBottom: 18,
      labelFontSize: 14,
      labelColor: "#232323",
      colorBorder: "#d7dce0"
    },
    Pagination: {
      itemSize: 40
    },
    Select: {
      optionSelectedBg: "#eef4f9"
    },
    Table: {
      borderColor: "#d7dce0",
      headerBg: "#eaedef"
    }
  }
};

export const proLayoutTheme: ProLayoutProps["token"] = {
  header: {
    colorBgHeader: "#fff"
  },
  bgLayout: "#fff",
  sider: {
    colorBgMenuItemSelected: "#eef4f9",
    colorTextMenuSelected: defaultTheme.token?.colorPrimary,
    colorTextMenu: defaultTheme.token?.colorTextBase
  }
};
