import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, message, Space, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import api, { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { styles } from "features/projects/Layouts/ActiveLayout/Header/styles";
import { CreateVotePayload, ProjectEntity, VotingProjects } from "types";
import { showErrorMessage } from "utils";
import { DATE_FORMAT, MONTH_YEAR } from "utils/dateFormats";
import { CreateForm } from "../components";

export const CreateVotingPage = () => {
  const [form] = Form.useForm();

  const { id } = useParams();
  const cssStyles = styles();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const mutationSucess = () => {
    queryClient.invalidateQueries(queries.voting.all());
    navigate("/voting");
  };

  const { mutate: editMutation, isLoading: editLoading } = useMutation({
    mutationFn: (data: CreateVotePayload) =>
      api.voting.update(Number(id), data),
    onSuccess: (data) => {
      message.success("Votarea a fost editata cu succes!");
      queryClient.invalidateQueries(queries.voting.byId(Number(data?.id)));
      mutationSucess();
    },
    onError: showErrorMessage
  });

  const { mutate: create, isLoading } = useMutation({
    mutationFn: (data: CreateVotePayload) => api.voting.create(data),
    onSuccess: () => {
      message.success("Votarea a fost creată cu succes!");
      queryClient.invalidateQueries(queries.voting.all());
      navigate("/voting");
    },
    onError: showErrorMessage
  });

  const { data, isLoading: fetchingDataLoading } = useQuery({
    ...queries.voting.byId(id),
    enabled: !!id
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      const projects = values?.projects?.map(
        (project: ProjectEntity) => project?.id
      );

      const payload = {
        start_date: dayjs(values?.period?.[0]).format(DATE_FORMAT),
        end_date: dayjs(values?.period?.[1]).format(DATE_FORMAT),
        projects
      };

      id ? editMutation(payload) : create(payload);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const selectedTime = Form.useWatch("period", form);

  useEffect(() => {
    if (id && data) {
      form.setFieldsValue({
        period: [dayjs(data?.start_date), dayjs(data?.end_date)],
        projects: data?.projects?.map((project: VotingProjects) => ({
          id: project?.id,
          title: project?.title
        }))
      });
    }
  }, [data, id, form]);

  if (id && fetchingDataLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Card styles={cssStyles.card} css={cssStyles.card} className="mb-32">
        <Col>
          <Typography.Title level={5}>
            Votare{" "}
            {selectedTime && (
              <>
                {dayjs(selectedTime?.[1]).format(MONTH_YEAR)} -
                {dayjs(selectedTime?.[0]).format(MONTH_YEAR)}{" "}
              </>
            )}
          </Typography.Title>
        </Col>

        <Col>
          <Space>
            <Button onClick={() => navigate("/voting")} size="large">
              Anulare
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={onSubmit}
              size="large"
              loading={isLoading || editLoading}
            >
              {id ? "Editare" : "Creare"}
            </Button>
          </Space>
        </Col>
      </Card>
      <CreateForm form={form} />
    </>
  );
};
