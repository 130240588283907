import { Card, Flex, Typography, Grid } from "antd";
import { TrandingDown, TrandingUp } from "assets/svg";
import { styles } from "./styles";

type Props = {
  id: string;
  title: string;
  total: number;
  icon: React.ReactNode;
  difference: number;
};

const { useBreakpoint } = Grid;

const { Text, Title } = Typography;

export const StatisticCard = ({
  id,
  title,
  total,
  icon,
  difference
}: Props) => {
  const cssStyles = styles();

  const isPositive = difference >= 0;

  const { md, xl } = useBreakpoint();

  const laptop = md && !xl;

  return (
    <Card css={cssStyles.card} styles={cssStyles.cardBody} id={id}>
      <Flex
        gap={16}
        vertical
        justify="space-between"
        style={{
          height: "100%"
        }}
      >
        <Flex justify="space-between" gap={8}>
          <Flex vertical gap={8}>
            <Text css={cssStyles.titleText}>{title}</Text>
            <Title level={4}>{total}</Title>
          </Flex>
          <Flex align="center">{icon}</Flex>
        </Flex>
        <Flex
          align="center"
          gap={4}
          vertical={laptop ? true : false}
          style={{
            textAlign: laptop ? "center" : "left"
          }}
        >
          {total > 0 ? (
            <>
              {isPositive ? <TrandingUp /> : <TrandingDown />}
              <Text
                style={{
                  wordBreak: "keep-all"
                }}
                css={
                  isPositive
                    ? cssStyles.descriptionTextPositive
                    : cssStyles.descriptionTextNegative
                }
              >
                {Math.floor(Math.abs(difference))}%
              </Text>
              <Text>
                {isPositive
                  ? "mai mult decât luna anterioară"
                  : "mai puțin decât luna anterioară"}
              </Text>
            </>
          ) : (
            "Nu sunt date disponibile"
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
