import { useEffect, useMemo, useState } from "react";
import { Progress, ProgressProps, Space, Typography, theme } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { styles } from "./styles";

interface CheckPasswordProps {
  value?: string;
}

const MINIMUM_LENGTH = /^.{8,}$/;
const UPPER = /(?=.*[A-Z]).*/;
const LOWER = /(?=.*[a-z]).*/;
const NUMBER = /(?=.*[0-9]).*/;
const SYMBOL = /(?=.*[@$!%*?&]).*/;

const icon = (checked: boolean) =>
  checked ? <CheckOutlined /> : <CloseOutlined />;

const ValidationType = ({
  checked,
  children
}: React.PropsWithChildren<{ checked: boolean }>) => {
  const { token } = theme.useToken();

  return (
    <Space
      size={2}
      style={{ color: checked ? token.colorSuccess : token.colorTextDisabled }}
    >
      {icon(checked)}{" "}
      <Typography.Text strong style={{ color: "inherit" }}>
        {children}
      </Typography.Text>
    </Space>
  );
};

export const CheckPassword = ({ value }: CheckPasswordProps) => {
  const { token } = theme.useToken();

  const [match, setMatch] = useState({
    length: false,
    upper: false,
    lower: false,
    number: false,
    symbol: false
  });

  useEffect(() => {
    setMatch({
      length: !!value?.match(MINIMUM_LENGTH),
      upper: !!value?.match(UPPER),
      lower: !!value?.match(LOWER),
      number: !!value?.match(NUMBER),
      symbol: !!value?.match(SYMBOL)
    });
  }, [value]);

  const percent = useMemo(() => {
    const matchKeys = Object.values(match);

    const result = (matchKeys.filter((v) => v).length * 100) / matchKeys.length;

    return Math.round(result);
  }, [match]);

  const statusInfo = useMemo(() => {
    const data: {
      text: string;
      status: ProgressProps["status"];
      color: string;
    } = {
      text: "Parolă slaba",
      status: "exception",
      color: token.colorError
    };

    if (percent <= 40) {
      data.text = "Parolă slaba";
      data.status = "exception";
      data.color = token.colorError;
    } else if (percent < 100) {
      data.text = "Parolă medie";
      data.status = "normal";
      data.color = token.colorPrimary;
    } else if (percent === 100) {
      data.text = "Parolă puternică";
      data.status = "success";
      data.color = token.colorSuccess;
    }

    return data;
  }, [percent, token]);

  const cssStyle = styles(token);

  return (
    <Space direction="vertical" css={cssStyle.fullWidth}>
      <Progress
        css={cssStyle.validationPassword.progress}
        percent={percent}
        status={statusInfo.status}
        steps={Object.keys(match).length}
        showInfo={false}
        strokeColor={statusInfo.color}
      />

      <Typography.Text>
        Nivel: <Typography.Text strong>{statusInfo.text}</Typography.Text>
      </Typography.Text>

      <Typography.Text strong>Parola trebuie să conțină:</Typography.Text>

      <ValidationType checked={match.length}>Minim 8 caractere</ValidationType>

      <ValidationType checked={match.lower}>
        Cel puțin o literă mică.
      </ValidationType>

      <ValidationType checked={match.upper}>
        Cel puțin o litera mare.
      </ValidationType>

      <ValidationType checked={match.number}>Cel puțin o cifra.</ValidationType>

      <ValidationType checked={match.symbol}>
        Caractere speciale (!@#).
      </ValidationType>
    </Space>
  );
};
