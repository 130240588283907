const TrandingUp = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 9.5L12.3 13.7L10.7 11.3L7.5 14.5"
        stroke="#0ED065"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 9.5H16.5V11.5"
        stroke="#0ED065"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#0ED065"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TrandingDown = () => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1666 14.5L12.9666 10.3L11.3666 12.7L8.16663 9.5"
        stroke="#F03D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1666 14.5H17.1666V12.5"
        stroke="#F03D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66663 22H15.6666C20.6666 22 22.6666 20 22.6666 15V9C22.6666 4 20.6666 2 15.6666 2H9.66663C4.66663 2 2.66663 4 2.66663 9V15C2.66663 20 4.66663 22 9.66663 22Z"
        stroke="#F03D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { TrandingUp, TrandingDown };
