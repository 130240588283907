import { css } from "@emotion/react";

export const styles = () => ({
  progress: css`
    margin-bottom: 4px;

    .ant-progress-text {
      display: none !important;
    }

    .ant-progress-outer {
      padding: 0 !important;
    }
  `,
  tag: {
    margin: 0
  }
});
