import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Flex, Form, message, Space, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import api, { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { ContentPublishDate } from "features/content/components";
import { File, ProjectEntity, ProjectStatus } from "types";
import { showErrorMessage } from "utils";
import { DATE_FORMAT } from "utils/dateFormats";
import { styles } from "../Layouts/ActiveLayout/Header/styles";
import { CreateProjectForm } from "../components/CreateProjectForm";
import { statusDisplayText } from "../utils";

export const EditProject = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const cssStyles = styles();

  const { data, isLoading } = useQuery({
    ...queries.projects.byId(Number(id)),
    enabled: !!id
  });

  const [publishDate, setPublishDate] = useState<Dayjs | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const navigate = useNavigate();

  const { mutate, isLoading: editLoading } = useMutation({
    mutationFn: (data: ProjectEntity) => api.projects.update(Number(id), data),
    onSuccess: (data) => {
      message.success("Proiectul a fost actualizat cu succes!");
      queryClient.invalidateQueries(queries.projects.byId(Number(id)));
      queryClient.invalidateQueries(queries.projects.all());
      navigate(`/projects/view/${data.id}`);
    },
    onError: showErrorMessage
  });

  const onChangePublishDate = (date: Dayjs | null) => {
    setPublishDate(date);
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      const field = values?.field?.value ?? values?.field;

      const attachments = files?.map((file) => {
        return {
          file_name: file?.name ?? file?.file_name,
          file_size: file?.size ?? file?.file_size,
          url: file.url
        };
      });

      const payload = {
        ...values,
        start_date: dayjs(values?.period[0]).format(DATE_FORMAT),
        end_date: dayjs(values?.period[1]).format(DATE_FORMAT),
        attachments,
        field
      };

      mutate(payload);
    } catch (error) {
      message.error(
        "Este necesară completarea tuturor formularelor în toate limbile"
      );
    }
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        field: { value: data?.field.id, label: data?.field.name },
        period: data?.start_date
          ? [dayjs(data?.start_date), dayjs(data?.end_date)]
          : null
      });

      setFiles(data?.attachments ?? []);
    }
  }, [data, form, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Card styles={cssStyles.card} css={cssStyles.card} className="mb-32">
        <Space>
          <Flex align="center" gap={8}>
            <Typography.Text strong>Status:</Typography.Text>
            {statusDisplayText[data?.status ?? ProjectStatus.DRAFT]}
          </Flex>
          <ContentPublishDate
            publishDate={publishDate}
            onChange={onChangePublishDate}
          />
        </Space>

        <Space>
          <Button onClick={() => navigate("/projects")} size="large">
            Anulare
          </Button>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            onClick={onSubmit}
            loading={editLoading}
          >
            Salveaza
          </Button>
        </Space>
      </Card>

      <CreateProjectForm
        form={form}
        files={files}
        setFiles={setFiles}
        data={data}
      />
    </>
  );
};
