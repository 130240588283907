import React from "react";
import { NamePath } from "antd/lib/form/interface";

import { FormInstance } from "rc-field-form/es/interface"; // Importing from rc-form-field because of Form.Item children renderer typing

import { makeNamePath } from "components/FormBuilder/utils/form";

import FormFields from "../FormFields";
import { FormField } from "../interface";
import { createFieldClassName } from "../utils";

export const renderFormItem = (
  item: FormField,
  namePrefix: NamePath,
  form: FormInstance,
  parentName?: NamePath
) => {
  const fieldClassName = createFieldClassName(item);

  // Take form item component by type and fill with data
  const FormItemField = FormFields?.hasOwnProperty(item?.type)
    ? FormFields[item?.type]
    : FormFields?.Default;

  const itemData = {
    ...item,
    name: makeNamePath(namePrefix, item.name)
  };

  return (
    <FormItemField
      data={itemData}
      form={form}
      className={fieldClassName}
      parentName={parentName}
    />
  );
};
