import { useEffect } from "react";
import { Form, Input, Modal, ModalProps, Select } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import { roleDisplayText } from "features/users/utils";
import { UserEntity, UserRole } from "types";
import { showErrorMessage } from "utils";

type Props = {
  id: number;
  onClose: () => void;
} & ModalProps;

const { Option } = Select;

const selectOptions = Object.entries(roleDisplayText)
  .slice(1)
  .map(([key, value]) => ({
    value: key,
    label: value
  }));

export const EditModal = ({ id, onClose, open }: Props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    ...queries.users.byId(id)
  });

  const { mutateAsync: editUser } = useMutation({
    mutationFn: (userData: UserEntity) => api.users.update(id, userData),
    onSuccess: (data) => {
      queryClient.invalidateQueries(queries.users.byId(data?.id));
      queryClient.invalidateQueries(queries.users.all());
      onClose();
    },
    onError: (error) => showErrorMessage(error)
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      editUser(values);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        role: data?.role
      });
    }
  }, [id, data, form]);

  return (
    <Modal
      title={`Editare utilizator ${data?.first_name} ${data?.last_name}`}
      open={open}
      centered
      okText="Salvează"
      cancelText="Anulează"
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item<UserEntity>
          label="Nume"
          name="first_name"
          rules={[{ required: true, message: "Introduceți numele" }]}
        >
          <Input placeholder="Introduceți numele" />
        </Form.Item>

        <Form.Item<UserEntity>
          label="Prenume"
          name="last_name"
          rules={[{ required: true, message: "Introduceți prenumele" }]}
        >
          <Input placeholder="Introduceți prenumele" />
        </Form.Item>

        <Form.Item<UserEntity>
          label="Email"
          name="email"
          rules={[
            {
              type: "email"
            },
            {
              required: true,
              message: "Introduceți email"
            }
          ]}
        >
          <Input placeholder="Introduceți email" />
        </Form.Item>

        <Form.Item<UserEntity>
          label="Rol"
          name="role"
          rules={[{ required: true, message: "Selectați rolul" }]}
        >
          <Select
            value={roleDisplayText[data?.role as UserRole]}
            onChange={(value) => form.setFieldValue("role", value)}
          >
            {selectOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
