import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Button, MenuProps } from "antd";

import { Add } from "assets/svg";
import { cssDropdown } from "style";
import { ContentTypeTuple } from "types";

interface Props {
  contentTypes: ContentTypeTuple[];
  contentType: string | null;
}

/**
 * AddContent is used to show a list of content types and to create a new
 * content with one of them.
 * @param contentTypes
 */
export const AddContent = ({ contentTypes, contentType }: Props) => {
  const navigate = useNavigate();

  const items: MenuProps["items"] =
    contentTypes.length > 0
      ? contentTypes.map((contentType: ContentTypeTuple) => ({
          key: contentType.key || contentType.title,
          label: (
            <Link to={`/content/create/${contentType.key}`}>
              {contentType.title}
            </Link>
          )
        }))
      : [{ key: "1", disabled: true, label: "No content" }];

  return !contentType ? (
    <Dropdown
      menu={{
        items,
        style: cssDropdown
      }}
      trigger={["click"]}
    >
      <Button type="primary" icon={<Add />} size="large">
        Add content
      </Button>
    </Dropdown>
  ) : (
    <Button
      type="primary"
      icon={<Add />}
      size="large"
      onClick={() => {
        navigate(`/content/create/${contentType}`);
      }}
    >
      Add content
    </Button>
  );
};
