import { Link } from "react-router-dom";
import { Dropdown, Button, MenuProps } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";

import Status from "components/Status";
import { TableContentType } from "./utils";

export const getTableColumns = (
  getActionColumnMenu: (data: TableContentType) => MenuProps["items"]
): Array<ColumnProps<TableContentType>> => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a?.name?.length || 0) - (b?.name?.length || 0),
      render: (name: string, item: TableContentType) => {
        return (
          <Link
            to={{
              pathname: `/content/edit/${item.contentType.id}/${item.id}/`
            }}
            state={{ name, item }}
          >
            {name}
          </Link>
        );
      }
    },
    {
      title: "Content Type",
      dataIndex: "Content Type",
      key: "contentType",
      render: (_, item: TableContentType) => item.contentType.name
    },
    {
      title: "Updated",
      dataIndex: "updatedDate",
      key: "updatedDate"
    },
    {
      title: "Published",
      dataIndex: "publishedDate",
      key: "publishedDate"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Status status={status} />
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      align: "right",
      render: (_, item) => (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button icon={<EllipsisOutlined />} type="text" />
        </Dropdown>
      )
    }
  ];
};
