import { Link } from "react-router-dom";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { IdeaEntity } from "types";
import { DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";

export const getIdeasTableColumns = () => {
  const columns: ColumnProps<IdeaEntity>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Titlu",
      key: "title",
      render: ({ id, title }) => <Link to={`/idea/view/${id}`}>{title}</Link>
    },
    {
      title: "Domeniu",
      key: "field",
      dataIndex: ["field", "name"]
    },
    {
      title: "Nume Prenume",
      key: "status",
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email"
    },
    {
      title: "Trimis la",
      key: "updated_at",
      render: ({ updated_at }) =>
        updated_at ? moment(updated_at).format(DAY_MONTH_YEAR_HOUR_MINUTE) : "-"
    }
  ];

  return columns;
};
