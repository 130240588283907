import { Col, Flex, Row, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { ProjectChart, StatisticCard } from "../components";
import { StatisticCardInfo } from "../utils";

export const Statistics = () => {
  const { data: projectStats, isLoading: projectStatsLoading } = useQuery({
    ...queries.projects.getStats()
  });

  const { data: userStats, isLoading: userStatsLoading } = useQuery({
    ...queries.users.getStats()
  });

  const { data: ideaStats, isLoading: ideaStatsLoading } = useQuery({
    ...queries.ideas.getStats()
  });

  const statsData = [projectStats, userStats, ideaStats];

  const isLoading = [
    projectStatsLoading,
    userStatsLoading,
    ideaStatsLoading
  ].some((loading) => loading);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Flex gap={16} vertical>
      <Flex vertical gap={32}>
        <Row className="mb-20" align="middle">
          <Col xs={24} lg={8}>
            <Typography.Title level={2}>Panou de statistică</Typography.Title>
          </Col>
        </Row>
        <Flex gap={16}>
          {Object.keys(StatisticCardInfo).map((item, index) => (
            <StatisticCard
              key={item}
              id={StatisticCardInfo[item].id}
              title={StatisticCardInfo[item].title}
              total={statsData[index]?.total}
              icon={StatisticCardInfo[item].icon}
              difference={statsData[index]?.difference}
            />
          ))}
        </Flex>
      </Flex>
      <ProjectChart />
    </Flex>
  );
};
