import { css } from "@emotion/react";

export const styles = () => ({
  siderbar: {
    ".ant-menu-item, .ant-menu-submenu": {
      marginBlock: 16,
      color: "#9CA3AF"
    },
    ".ant-menu-submenu-title": {
      color: "#9CA3AF"
    },
    ".ant-menu-title-content": {
      color: "#9CA3AF"
    },
    ".ant-menu-submenu-arrow": {
      color: "#9CA3AF"
    },
    ".ant-menu-item-icon": {
      width: 24,
      height: 24
    },
    ".ant-menu-item-selected": {
      color: "#000000"
    },
    ".ant-menu-item-selected .ant-menu-title-content": {
      color: "#000000",
      fontWeight: "bold"
    }
  },
  pageHeaderCard: css`
    border: 0;
    border-radius: 0px;
    border-bottom: 1px solid #e5e7eb;
  `,
  pageHeaderCardBody: {
    body: {
      padding: "16px 32px",
      height: 72,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center"
    }
  },
  content: css`
    padding-inline: 32px;
    padding-block: 48px;
    min-height: calc(100vh - 72px);
  `
});
