import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Typography, Input } from "antd";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { queries } from "api";
import { FilterState, Filters, PageSettings } from "utils";

import { FormsDataTable } from "./FormsDataTable";

const DEFAULT_PAGE_SIZE = 20;

export const FormsData = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);

  const responsesQuery = useQuery(
    queries.forms.responses(id, {
      page: params.page ?? 1,
      page_size: params.pageSize ?? DEFAULT_PAGE_SIZE,
      search: params.searchTerm ?? undefined
    })
  );

  const handleChangeFilters = useCallback(
    (type: keyof FilterState, value: string | number | PageSettings) => {
      const isPageSettingsFilter = type === Filters.PAGE_SETTINGS;

      const { current: page, size: pageSize } = isPageSettingsFilter
        ? (value as PageSettings)
        : {
            current: params.page ?? 1,
            size: params.pageSize ?? DEFAULT_PAGE_SIZE
          };

      const newParams = {
        ...params,
        ...(!isPageSettingsFilter && { [type]: value }),
        page: isPageSettingsFilter ? page : 1,
        pageSize: pageSize !== DEFAULT_PAGE_SIZE ? pageSize : undefined
      };

      const newParamsFiltered = newParams
        ? Object.keys(newParams).reduce((queryParams, key) => {
            const value = newParams[key];
            if (value !== undefined && value !== null && value !== "") {
              queryParams[key] = value;
            }
            return queryParams;
          }, {})
        : {};

      const url = queryString.stringifyUrl({
        url: `/forms/${id}/data`,
        query: newParamsFiltered
      });
      navigate(url);
    },
    [params, id, navigate]
  );

  const paginationConfig = useMemo(() => {
    return {
      current: Number(params.page ?? 1),
      pageSize: Number(params.pageSize ?? DEFAULT_PAGE_SIZE),
      total: Number(responsesQuery.data?.count),
      showSizeChanger: true,
      onChange: (page: number, pageSize: number) => {
        handleChangeFilters(Filters.PAGE_SETTINGS, {
          current:
            !params.pageSize || Number(params.pageSize) === Number(pageSize)
              ? page
              : 1,
          size: pageSize
        });
      }
    };
  }, [
    handleChangeFilters,
    params.page,
    params.pageSize,
    responsesQuery.data?.count
  ]);

  return (
    <>
      <Row className="mb-20">
        <Col xs={24} lg={8}>
          <div className="d-flex align-items-center gap-8">
            <Typography.Title
              className="m-0 text-nowrap"
              level={5}
            >{`Forms responses ${
              responsesQuery?.data?.count
                ? `(${responsesQuery.data.count})`
                : "(0)"
            }`}</Typography.Title>

            <Input.Search
              className="mw-300"
              onSearch={(value) => handleChangeFilters(Filters.SEARCH, value)}
              placeholder="Search"
              size="large"
            />
          </div>
        </Col>
      </Row>

      <FormsDataTable
        loading={responsesQuery.isLoading}
        data={responsesQuery.data?.results ?? []}
        paginationConfig={paginationConfig}
      />
    </>
  );
};
