import { css } from "@emotion/react";

export const styles = () => ({
  tag: {
    border: "1px solid #E5E7EB",
    borderRadius: 16,
    paddingInline: 10,
    fontSize: 12,
    color: "#030712",
    fontWeight: 600,
    minHeight: 32,
    width: "fit-content"
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: "50%"
  },
  progress: css`
    margin-bottom: 4px;

    .ant-progress-text {
      display: none !important;
    }

    .ant-progress-outer {
      padding: 0 !important;
    }
  `
});
