import { Card, FormInstance, Input, Select } from "antd";
import {
  MultiLanguageForm,
  SharedFormItemProps
} from "components/MultiLanguageForm";
import { sortByTypeOptions } from "features/notifications/utils";

type Props = {
  form: FormInstance;
};

const formItems: SharedFormItemProps[] = [
  {
    name: "subject",
    label: "Titlu",
    rules: [{ required: true }],
    component: <Input />,
    shared: false
  },
  {
    name: "body",
    label: "Conținut",
    rules: [{ required: true }],
    type: "Editor",
    shared: false
  },
  {
    name: "type",
    label: "Tip notificare",
    rules: [{ required: true }],
    component: <Select options={sortByTypeOptions.slice(1)} />,
    shared: true
  }
];

export const NotificationForm = ({ form }: Props) => {
  return (
    <Card
      style={{
        maxWidth: 500
      }}
    >
      <MultiLanguageForm form={form} formItems={formItems} />
    </Card>
  );
};
