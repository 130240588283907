import { Link } from "react-router-dom";
import { Button, Dropdown, MenuProps, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import moment from "moment";
import { getActiveVoting } from "features/polls/utils";
import { VotingEntity } from "types";
import { DAY_MONTH_YEAR_HOUR_MINUTE, MONTH_YEAR } from "utils/dateFormats";

export const getVotingTableColumns = (
  getActionColumnMenu: (content: VotingEntity) => MenuProps["items"]
) => {
  const columns: ColumnProps<VotingEntity>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Titlu",
      key: "title",
      render: ({ start_date, end_date, id }) => (
        <Link to={`results/${id}`}>
          <Typography.Title
            level={5}
            style={{
              color: "blue"
            }}
          >
            Votare {dayjs(start_date).format(MONTH_YEAR)} -{" "}
            {dayjs(end_date).format(MONTH_YEAR)}
          </Typography.Title>
        </Link>
      )
    },
    {
      title: "Creat la",
      key: "created_at",
      render: ({ created_at }) =>
        created_at ? moment(created_at).format(DAY_MONTH_YEAR_HOUR_MINUTE) : "-"
    },
    {
      title: "Votare activa",
      key: "is_active",
      render: ({ start_date, end_date }) => {
        const isActive = getActiveVoting(start_date, end_date);

        return (
          <Typography.Text type={isActive ? "success" : "danger"}>
            {isActive ? "Da" : "Nu"}
          </Typography.Text>
        );
      }
    },
    {
      title: "",
      dataIndex: "",
      align: "right",
      key: "actions",
      render: (_, item) => (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          menu={{ items: getActionColumnMenu(item) }}
        >
          <Button icon={<EllipsisOutlined />} type="text" />
        </Dropdown>
      )
    }
  ];

  return columns;
};
