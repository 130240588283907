import { css } from "@emotion/react";
import { ProjectStatus } from "types";

const statusColorMap = {
  [ProjectStatus.ACTIVE]: "#FCBF04",
  [ProjectStatus.ARCHIVED]: "#0ED065",
  [ProjectStatus.DRAFT]: "transparent"
};

export const getColorFromStatus = (
  status: ProjectStatus = ProjectStatus.DRAFT
) => {
  return statusColorMap[status] ?? "transparent";
};

export const styles = (status?: ProjectStatus) => ({
  card: {
    body: {
      padding: 16
    }
  },
  font18: css`
    font-size: 18px !important;
    font-weight: 500 !important;
  `,
  font14: css`
    font-size: 14px !important;
    font-weight: 600 !important;
  `,
  arrowButton: css`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  tag: {
    border: "1px solid #E5E7EB",
    borderRadius: 16,
    paddingInline: 10,
    fontSize: 12,
    color: "#030712",
    fontWeight: 600,
    minHeight: 32,
    width: "fit-content"
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: getColorFromStatus(status)
  },
  progress: css`
    margin-bottom: 4px;

    .ant-progress-text {
      display: none !important;
    }

    .ant-progress-outer {
      padding: 0 !important;
    }
  `
});
