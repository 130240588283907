import { useRef } from "react";
import {
  Carousel as AntdCarousel,
  Button,
  Divider,
  Flex,
  Grid,
  Typography
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { CarouselRef } from "antd/lib/carousel";
import { styles } from "features/projects/Layouts/ExtraContent/styles";
import { ProjectEntity } from "types";
import { CarouselImage } from "../CarouselImage";
import { carouselConfig, MAX_SLIDER_IMAGES } from "../ViewDetails/utils";

type Props = {
  data?: ProjectEntity["attachments"];
};

const { useBreakpoint } = Grid;

export const Carousel = ({ data }: Props) => {
  const attachmentsLength = data?.length ?? 0;
  const { xxl } = useBreakpoint();
  const cssStyles = styles();

  const carouselRef = useRef<CarouselRef>(null);

  const nextSlide = () => {
    carouselRef.current?.next();
  };

  const prevSlide = () => {
    carouselRef.current?.prev();
  };

  return (
    <Flex vertical gap={8}>
      <Flex align="center" justify="space-between">
        <Typography.Title css={cssStyles.font18}>
          Imagini carousel
        </Typography.Title>
        {attachmentsLength >= MAX_SLIDER_IMAGES && (
          <Flex gap={8}>
            <Button
              size="large"
              onClick={() => prevSlide()}
              css={cssStyles.arrowButton}
            >
              <LeftOutlined />
            </Button>

            <Button
              size="large"
              onClick={() => nextSlide()}
              css={cssStyles.arrowButton}
            >
              <RightOutlined />
            </Button>
          </Flex>
        )}
      </Flex>

      {attachmentsLength >= MAX_SLIDER_IMAGES ? (
        <div style={{ overflow: "hidden", width: "100%" }}>
          <AntdCarousel
            {...carouselConfig}
            slidesToShow={xxl ? 6 : 4}
            ref={carouselRef}
          >
            {data?.map((image) => (
              <CarouselImage key={image?.file_name} image={image?.url} />
            ))}
          </AntdCarousel>
        </div>
      ) : (
        <Flex
          gap={12}
          style={{
            overflow: "hidden"
          }}
        >
          {data?.map((image) => (
            <CarouselImage key={image?.name} image={image?.url} />
          ))}
        </Flex>
      )}
      <Divider />
    </Flex>
  );
};
