import { Select, SelectProps } from "antd";

type Props = {
  placeholder?: string;
} & SelectProps;

export const TableOrderingSelect = ({
  placeholder = "Sortează după",
  ...props
}: Props) => {
  return (
    <Select
      size="large"
      placeholder={placeholder}
      popupMatchSelectWidth={false}
      placement="bottomRight"
      {...props}
    />
  );
};
