export const menuIcons: Record<string, JSX.Element> = {
  ContentTypes: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03003 8.5H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03003 15.5H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.51001 21.9898V2.00977"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.51 21.9898V2.00977"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Records: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.5H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15.5H22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Media: (
    <svg viewBox="0 0 512 512" width="1em" height="1em">
      <path
        fill="currentColor"
        d="M494.4,99.6c-3.8-3.8-7.9-6.9-12.4-9.5V60c0-16-6.2-31.1-17.6-42.4C453.1,6.2,438,0.1,422,0.1c0,0,0,0,0,0L89,0
      C55.9,0,29,26.8,29,59.9v30.7C11.6,101.2,0,120.3,0,142v310c0,33.1,26.9,60,60,60h392c33.1,0,60-26.9,60-60V142.1
      C512,126,505.8,111,494.4,99.6z M89,39.9L422,40c5.3,0,10.4,2.1,14.1,5.9c3.8,3.8,5.9,8.8,5.9,14.1v22.1L69,82V59.9
      C69,48.9,78,39.9,89,39.9L89,39.9z M256.3,253.9l44,57.1h-83.7L256.3,253.9z M167.8,311H94.1l66.7-97.4l40,50L167.8,311z M472,452
      c0,11-9,20-20,20H60c-11,0-20-9-20-20V351h432V452z M350.7,311l-96-124.9l-30.1,43.2l-66.4-83L45.6,311H40V142c0-11,9-20.1,20-20.1
      l392,0.1h0c5.3,0,10.4,2.2,14.1,5.9c3.8,3.8,5.9,8.8,5.9,14.1V311H350.7z M306,431H206v-40h100V431z M408,198c0,16.6-13.4,30-30,30
      s-30-13.4-30-30s13.4-30,30-30S408,181.4,408,198z"
      />
    </svg>
  ),
  Locales: (
    <svg viewBox="0 0 512 512" width="1em" height="1em">
      <path
        fill="currentColor"
        d="M256,512c-42.4,0-209.5-132.2-209.5-302.5C46.5,93.8,140.3,0,256,0s209.5,93.8,209.5,209.5C465.5,379.8,298.4,512,256,512z
	 M256,46.5c-90,0-162.9,72.9-162.9,162.9c0,142,135.2,243.4,162.9,256c27.7-11.9,162.9-113.3,162.9-256
	C418.9,119.5,346,46.5,256,46.5z M256,325.8c-64.3,0-116.4-52.1-116.4-116.4S191.7,93.1,256,93.1s116.4,52.1,116.4,116.4
	S320.3,325.8,256,325.8z M256,139.6c-38.6,0-69.8,31.3-69.8,69.8s31.3,69.8,69.8,69.8s69.8-31.3,69.8-69.8S294.6,139.6,256,139.6z"
      />
    </svg>
  ),
  Settings: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 1024 1024"
      height="1em"
      width="1em"
    >
      <path
        d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 
        443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0
        0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32
        32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6
        57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0
        35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05
        32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1
        61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7
        63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0
        1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4
        33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1
        25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1
        14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176
        78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6
        111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624
        502c0 29.9-11.7 58-32.8 79.2z"
      />
    </svg>
  ),
  Forms: (
    <svg width="1em" height="1em" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M15.891,-0.000 L2.109,-0.000 C0.946,-0.000 -0.000,0.946 -0.000,2.109 L-0.000,15.891 C-0.000,17.054 0.946,18.000 2.109,18.000 L15.891,18.000 C17.054,18.000 18.000,17.054 18.000,15.891 L18.000,2.109 C18.000,0.946 17.054,-0.000 15.891,-0.000 ZM16.594,15.891 C16.594,16.278 16.278,16.594 15.891,16.594 L2.109,16.594 C1.722,16.594 1.406,16.278 1.406,15.891 L1.406,8.578 L16.594,8.578 L16.594,15.891 ZM1.406,7.172 L1.406,2.109 C1.406,1.722 1.722,1.406 2.109,1.406 L15.891,1.406 C16.278,1.406 16.594,1.722 16.594,2.109 L16.594,7.172 L1.406,7.172 ZM14.185,2.807 L15.178,3.803 L12.838,6.137 L10.475,3.806 L11.463,2.805 L12.833,4.156 L14.185,2.807 ZM14.730,11.742 L3.270,11.742 L3.270,10.336 L14.730,10.336 L14.730,11.742 ZM14.730,14.906 L3.270,14.906 L3.270,13.500 L14.730,13.500 L14.730,14.906 Z"
      ></path>
    </svg>
  ),
  Users: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Notifications: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0156 6.43994V9.76994"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M12.0355 2C8.35552 2 5.37552 4.98 5.37552 8.66V10.76C5.37552 11.44 5.09552 12.46 4.74552 13.04L3.47552 15.16C2.69552 16.47 3.23552 17.93 4.67552 18.41C9.45552 20 14.6255 20 19.4055 18.41C20.7555 17.96 21.3355 16.38 20.6055 15.16L19.3355 13.04C18.9855 12.46 18.7055 11.43 18.7055 10.76V8.66C18.6955 5 15.6955 2 12.0355 2Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M15.3455 18.8198C15.3455 20.6498 13.8455 22.1498 12.0155 22.1498C11.1055 22.1498 10.2655 21.7698 9.66555 21.1698C9.06555 20.5698 8.68555 19.7298 8.68555 18.8198"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
      />
    </svg>
  ),
  Projects: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 18V7C3.5 3 4.5 2 8.5 2H15.5C19.5 2 20.5 3 20.5 7V17C20.5 17.14 20.5 17.28 20.49 17.42"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.35 15H20.5V18.5C20.5 20.43 18.93 22 17 22H7C5.07 22 3.5 20.43 3.5 18.5V17.85C3.5 16.28 4.78 15 6.35 15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7H16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Statistics: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18V15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.07 2.82009L3.14002 8.37009C2.36002 8.99009 1.86002 10.3001 2.03002 11.2801L3.36002 19.2401C3.60002 20.6601 4.96002 21.8101 6.40002 21.8101H17.6C19.03 21.8101 20.4 20.6501 20.64 19.2401L21.97 11.2801C22.13 10.3001 21.63 8.99009 20.86 8.37009L13.93 2.83009C12.86 1.97009 11.13 1.97009 10.07 2.82009Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Polls: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66553 13.7999L11.2755 15.0499C11.4855 15.2599 11.9555 15.3598 12.2655 15.3598H14.2455C14.8655 15.3598 15.5455 14.8899 15.7055 14.2699L16.9555 10.4798C17.2155 9.74981 16.7455 9.12984 15.9655 9.12984H13.8855C13.5755 9.12984 13.3155 8.86984 13.3655 8.50984L13.6255 6.84981C13.7255 6.37981 13.4155 5.85979 12.9455 5.70979C12.5255 5.54979 12.0055 5.75978 11.8055 6.06978L9.67554 9.23982"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
      />
      <path
        d="M7.01562 13.7998V8.70972C7.01562 7.97972 7.32562 7.71973 8.05562 7.71973H8.57562C9.30562 7.71973 9.61563 7.97972 9.61563 8.70972V13.7998C9.61563 14.5298 9.30562 14.7898 8.57562 14.7898H8.05562C7.32562 14.7898 7.01562 14.5298 7.01562 13.7998Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0156 18.8597H17.2556C16.4556 18.8597 15.6956 19.1697 15.1356 19.7297L13.4256 21.4198C12.6456 22.1898 11.3756 22.1898 10.5956 21.4198L8.88562 19.7297C8.32562 19.1697 7.55563 18.8597 6.76562 18.8597H6.01562C4.35563 18.8597 3.01562 17.5298 3.01562 15.8898V4.97974C3.01562 3.33974 4.35563 2.00977 6.01562 2.00977H18.0156C19.6756 2.00977 21.0156 3.33974 21.0156 4.97974V15.8898C21.0156 17.5198 19.6756 18.8597 18.0156 18.8597Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Voting: (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.56 10.7401L20.2 9.16006C19.94 8.86006 19.73 8.30006 19.73 7.90006V6.20006C19.73 5.14006 18.86 4.27006 17.8 4.27006H16.1C15.71 4.27006 15.14 4.06006 14.84 3.80006L13.26 2.44006C12.57 1.85006 11.44 1.85006 10.74 2.44006L9.17 3.81006C8.87 4.06006 8.3 4.27006 7.91 4.27006H6.18C5.12 4.27006 4.25 5.14006 4.25 6.20006V7.91006C4.25 8.30006 4.04 8.86006 3.79 9.16006L2.44 10.7501C1.86 11.4401 1.86 12.5601 2.44 13.2501L3.79 14.8401C4.04 15.1401 4.25 15.7001 4.25 16.0901V17.8001C4.25 18.8601 5.12 19.7301 6.18 19.7301H7.91C8.3 19.7301 8.87 19.9401 9.17 20.2001L10.75 21.5601C11.44 22.1501 12.57 22.1501 13.27 21.5601L14.85 20.2001C15.15 19.9401 15.71 19.7301 16.11 19.7301H17.81C18.87 19.7301 19.74 18.8601 19.74 17.8001V16.1001C19.74 15.7101 19.95 15.1401 20.21 14.8401L21.57 13.2601C22.15 12.5701 22.15 11.4301 21.56 10.7401ZM16.68 12.0001L15.51 15.5601C15.36 16.1501 14.73 16.6301 14.09 16.6301H12.24C11.92 16.6301 11.47 16.5201 11.27 16.3201L9.8 15.1701C9.77 15.8101 9.48001 16.0801 8.77001 16.0801H8.29C7.55 16.0801 7.25 15.7901 7.25 15.0901V10.3101C7.25 9.61006 7.55 9.32006 8.29 9.32006H8.78C9.52 9.32006 9.82 9.61006 9.82 10.3101V10.6701L11.76 7.79006C11.96 7.48006 12.47 7.26006 12.9 7.43006C13.37 7.59006 13.67 8.11006 13.57 8.57006L13.33 10.1301C13.31 10.2701 13.34 10.4001 13.43 10.5001C13.51 10.5901 13.63 10.6501 13.76 10.6501H15.71C16.09 10.6501 16.41 10.8001 16.6 11.0701C16.77 11.3301 16.8 11.6601 16.68 12.0001Z"
        fill="currentColor"
      />
    </svg>
  ),
  Logout: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12H3.62"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  User: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
