import { useState } from "react";
import { Image, Modal, UploadFile } from "antd";
import { videoFormats } from "features/projects/utils";

type Props = {
  image: UploadFile["url"];
};

export const CarouselImage = ({ image }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isVideo = videoFormats.some((format) => image?.includes(format));

  const onPlayButtonClick = () => {
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {isVideo ? (
        <div
          style={{
            position: "relative",
            width: 176,
            height: 120,
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <button
            onClick={onPlayButtonClick}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              border: "none",
              borderRadius: "50%",
              padding: "12px",
              cursor: "pointer",
              color: "#fff",
              fontSize: "24px"
            }}
          >
            ▶️
          </button>
        </div>
      ) : (
        <Image
          src={image}
          alt="carousel"
          width={176}
          height={120}
          style={{
            objectFit: "cover",
            paddingRight: 10
          }}
        />
      )}

      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={onModalClose}
        width={950}
      >
        <video width="900px" height="650px" controls autoPlay>
          <source src={image} type="video/mp4" />
        </video>
      </Modal>
    </>
  );
};
