import { Dispatch } from "react";
import { Card, DatePicker, Divider, Form, FormInstance, Input } from "antd";

import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import {
  MultiLanguageForm,
  SharedFormItemProps
} from "components/MultiLanguageForm";
import { UploadComponent } from "features/projects/components";
import { File, PollEntity } from "types";
import { FORAMT_DAY_MONTH_YEAR_WITH_SLASH } from "utils/dateFormats";
import { QuestionList } from "./QuestionList/QuestionList";

type Props = {
  form: FormInstance<PollEntity>;
  data?: PollEntity;
  files: File[];
  setFiles: Dispatch<React.SetStateAction<File[]>>;
};

const { RangePicker } = DatePicker;

export const PollForm = ({ data, form, files, setFiles }: Props) => {
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && dayjs(current).startOf("day") < dayjs().startOf("day");
  };

  const formItems: SharedFormItemProps[] = [
    {
      name: "title",
      id: "poll-title",
      label: "Titlu",
      rules: [{ required: true }],
      component: <Input />,
      shared: false
    },
    {
      name: "description",
      label: "Descriere",
      id: "poll-description",
      rules: [{ required: true }],
      component: <Input.TextArea rows={7} />,
      shared: false
    },
    {
      name: "",
      label: "",
      rules: [{ required: false }],
      shared: true,
      component: <Divider />
    },
    {
      name: "period",
      id: "poll-period",
      label: "Perioada sondaj",
      rules: [{ required: true }],
      component: (
        <RangePicker
          disabledDate={disabledDate}
          format={FORAMT_DAY_MONTH_YEAR_WITH_SLASH}
        />
      ),
      shared: true
    },
    {
      name: "questions",
      id: "poll-questions",
      label: "Întrebări sondaj",
      rules: [{ required: false }],
      needsLangKey: true,
      renderComponentWithKey: (langKey) => <QuestionList langKey={langKey} />,
      type: "List",
      shared: false
    }
  ];

  return (
    <Card
      style={{
        maxWidth: 500
      }}
    >
      <MultiLanguageForm
        form={form}
        formItems={formItems}
        initialValues={data ?? { questions: [{}] }}
      >
        <Divider />
        <Form.Item name="image" label="Imagine">
          <UploadComponent
            files={files}
            setFormData={setFiles}
            multiple={false}
          />
        </Form.Item>
        <Divider />
      </MultiLanguageForm>
    </Card>
  );
};
