import { PropsWithChildren, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Result, Row, Tabs, Typography } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { TabsProps } from "antd/lib";
import { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { UserRole } from "types";
import { InviteModal } from "../InviteModal";
import { styles } from "./styles";

const tabItems: TabsProps["items"] = [
  {
    id: "citizens",
    key: "/users/citizens",
    label: "Cetățeni"
  },
  {
    id: "employees",
    key: "/users/employees",
    label: "Angajați Primărie"
  }
];

export const Wrapper = ({ children }: PropsWithChildren) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const cssStyle = styles();

  const { data: profileInfo, isLoading: profileLoading } = useQuery({
    ...queries.profile.profile()
  });

  const activeTabElement =
    tabItems.find((item) => item.key === location.pathname)?.key ??
    tabItems[0].key;

  const [activeTab, setActiveTab] = useState<string | undefined>(
    activeTabElement
  );

  useEffect(() => {
    setActiveTab(activeTabElement);
  }, [location.pathname, activeTabElement]);

  if (profileLoading) {
    return <LoadingScreen />;
  }

  if (profileInfo?.role !== UserRole.SUPERUSER) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Nu aveți permisiunea de a accesa această pagină."
        extra={
          <Link to="/statistics">
            <Button type="primary">Inapoi</Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Col xs={24} lg={8}>
          <Typography.Title level={2}>Management utilizatori</Typography.Title>
        </Col>
      </Row>

      <Tabs
        size="large"
        onChange={(key) => {
          navigate(key);
          setActiveTab(key);
        }}
        activeKey={activeTab}
        items={tabItems}
        css={cssStyle.tabs}
        tabBarExtraContent={
          activeTab?.includes("employees") && (
            <Button
              id="add-employee-button"
              size="large"
              type="primary"
              onClick={() => setIsModalOpen(true)}
            >
              <PlusCircleFilled />
              Adaugă un angajat nou
            </Button>
          )
        }
      />
      {children}
      {isModalOpen && (
        <InviteModal onClose={() => setIsModalOpen(false)} open={isModalOpen} />
      )}
    </>
  );
};
