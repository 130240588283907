import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Input,
  Radio,
  Row,
  Space,
  Table,
  Typography
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { Add } from "assets/svg";
import { TableOrderingSelect } from "components";

import {
  NotificationEntity,
  NotificationStatus,
  NotificationType
} from "types";
import { resetPagination, useTablePagination } from "utils";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { getTableColumns, NotificationViewDrawer } from "../components";
import {
  sortByTypeOptions,
  sortDirectionOptions,
  sortByStatusOptions
} from "../utils";

export const NotificationList = () => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<NotificationStatus>();
  const [type, setType] = useState<NotificationType>();
  const [ordering, setOrdering] = useState("-id");

  const [isOpenViewDrawer, setIsOpenViewDrawer] = useState(false);
  const [currentNotification, setCurrentNotification] =
    useState<NotificationEntity>();

  const pagination = useTablePagination();
  const navigate = useNavigate();

  const queryParams = {
    page: pagination.current,
    per_page: pagination.pageSize,
    ordering,
    search,
    status,
    type
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.notifications.all(stringifiedParams)
  });

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
  };

  const onStatusChange = (value: NotificationStatus) => {
    setStatus(value);
    resetPagination(pagination);
  };

  const onTypeChange = (value: NotificationType) => {
    setType(value);
    resetPagination(pagination);
  };

  const onRowClick = (record: NotificationEntity) => {
    return {
      onClick: () => {
        setIsOpenViewDrawer(true);
        setCurrentNotification(record);
      }
    };
  };

  const onViewDrawerClose = () => {
    setIsOpenViewDrawer(false);
    setCurrentNotification(undefined);
  };

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Flex gap={4} align="baseline">
          <Typography.Title level={2}>Notificari</Typography.Title>
          <Typography.Title level={5}>({data?.count ?? 0})</Typography.Title>
        </Flex>
        <Space>
          <Radio.Group
            defaultValue=""
            options={sortByStatusOptions}
            buttonStyle="solid"
            optionType="button"
            size="large"
            onChange={(value) => onStatusChange(value.target.value)}
          />

          <Button
            type="primary"
            size="large"
            icon={<Add />}
            onClick={() => {
              navigate(`/notifications/add`);
            }}
          >
            Creează o notificare
          </Button>
        </Space>
      </Row>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          size="large"
          className="mw-300"
          placeholder="Search"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex gap={8} align="center" justify="center">
          <TableOrderingSelect
            defaultValue={sortByTypeOptions?.[0]?.value}
            options={sortByTypeOptions}
            onChange={(value) => onTypeChange(value)}
          />

          <TableOrderingSelect
            defaultValue={sortDirectionOptions?.[1]?.value}
            options={sortDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />
        </Flex>
      </Row>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={getTableColumns()}
        bordered
        dataSource={data?.results ?? []}
        onRow={(record) => onRowClick(record)}
        pagination={{
          ...pagination,
          total: data?.count,
          hideOnSinglePage: true
        }}
      />
      <NotificationViewDrawer
        isOpen={isOpenViewDrawer}
        onClose={onViewDrawerClose}
        notification={currentNotification?.id}
      />
    </>
  );
};
