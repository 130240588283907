import { css } from "@emotion/react";

export const styles = () => ({
  card: {
    width: "100%",
    body: {
      padding: 12
    }
  },
  modalCard: css`
    margin-top: 12px;
    min-height: 120px;
    .ant-card-body {
      padding: 12px;
    }
  `
});
