import { useState } from "react";
import { Button, Card, Empty, Flex } from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { QuestionEntity, TextAnswersEntity } from "types";
import { TextsModal } from "../TextsModal";
import { styles } from "./styles";

type Props = {
  question?: QuestionEntity;
};

const MAX_PER_PAGE = 2;

export const TextAnswers = ({ question }: Props) => {
  const cssStyles = styles();

  const [showMoreModal, setShowMoreModal] = useState(false);

  const { data, isLoading } = useQuery({
    ...queries.polls.textResultsById({ question: question?.id })
  });

  const dataLength = data?.count;
  const hasMoreThenTwo = dataLength && dataLength > MAX_PER_PAGE;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {dataLength ? (
        <Flex vertical gap={8} align="start">
          {data?.results
            .slice(0, hasMoreThenTwo ? MAX_PER_PAGE : data?.results.length)
            .map((item: TextAnswersEntity) => (
              <Card styles={cssStyles.card} key={item?.id} css={cssStyles.card}>
                {item?.text}
              </Card>
            ))}
          {hasMoreThenTwo && (
            <Button onClick={() => setShowMoreModal(!showMoreModal)}>
              Vezi toate raspunsurile
            </Button>
          )}
          {showMoreModal && (
            <TextsModal
              question={question}
              open={showMoreModal}
              onCancel={() => setShowMoreModal(!showMoreModal)}
            />
          )}
        </Flex>
      ) : (
        <Empty description="La moment nu sunt raspunsuri" />
      )}
    </>
  );
};
