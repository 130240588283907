import { Key, useState } from "react";
import { Flex, Input, message, Row, Switch, Table, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MenuProps } from "antd/lib";
import api, { queries } from "api";
import {
  DeleteButton,
  DeleteConfirmModal,
  TableOrderingSelect
} from "components";
import { sortDirectionOptions } from "features/users/utils";
import { UserEntity, UserRole } from "types";
import { resetPagination, showErrorMessage, useTablePagination } from "utils";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { EditModal } from "../EditModal";
import { getTableColumns } from "../tableColumns";

type Props = {
  role: UserRole;
};

export const UsersTable = ({ role }: Props) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<boolean>();
  const [ordering, setOrdering] = useState("-updated_at");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<number>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const queryClient = useQueryClient();

  const pagination = useTablePagination();

  const queryParams = {
    is_active: status,
    ordering,
    page: pagination.current,
    per_page: pagination.pageSize,
    role,
    search
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.users.all(stringifiedParams)
  });

  const onMutationSuccess = () => {
    queryClient.invalidateQueries(queries.users.all());
  };

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: (ids: Key[]) => api.users.bulkDelete(ids),
    onSuccess: () => {
      onMutationSuccess();
      setSelectedRowKeys([]);
    },
    onError: showErrorMessage
  });

  const { mutate: deleteOne } = useMutation({
    mutationFn: (id: number) => api.users.deleteUser(id),
    onSuccess: onMutationSuccess,
    onError: showErrorMessage
  });

  const { mutate: activate } = useMutation({
    mutationFn: (id: number) => api.users.activate(id),
    onSuccess: (data) => {
      message.success("Utilizatorul a fost activat cu succes");
      onMutationSuccess();
      queryClient.invalidateQueries(queries.users.byId(Number(data?.id)));
    },
    onError: showErrorMessage
  });

  const { mutate: deactivate } = useMutation({
    mutationFn: (id: number) => api.users.deactivate(id),
    onSuccess: (data) => {
      message.success("Utilizatorul a fost dezactivat cu succes");
      onMutationSuccess();
      queryClient.invalidateQueries(queries.users.byId(Number(data?.id)));
    },
    onError: showErrorMessage
  });

  const onSelectRowChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange
  };

  const onBulkDelete = () => {
    const ids = rowSelection?.selectedRowKeys.map((key) => key);

    const onOk = () => bulkDelete(ids);
    DeleteConfirmModal(onOk, true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSwitch = (context: UserEntity) => {
    context.is_active ? deactivate(context.id) : activate(context.id);
  };

  const getActionColumnMenu = (context: UserEntity): MenuProps["items"] => [
    {
      key: "edit",
      label: "Editează",
      onClick: () => {
        setCurrentUserId(context.id);
        setIsEditModalOpen(true);
      }
    },
    {
      key: "delete",
      label: "Șterge",
      onClick: () => {
        const onOk = () => deleteOne(context.id);
        DeleteConfirmModal(onOk);
      }
    }
  ];

  const onFilterSwitch = (checked: boolean) => {
    setStatus(checked);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
  };

  return (
    <>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          className="mw-300"
          placeholder="Caută utilizator..."
          size="large"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex gap={8} align="center" justify="center">
          <Flex gap={8}>
            <Typography.Text strong>Statut</Typography.Text>
            <Switch onChange={(checked) => onFilterSwitch(checked)} />
          </Flex>
          {hasSelected && (
            <DeleteButton
              loading={isBulkDeleteLoading}
              onClick={onBulkDelete}
            />
          )}
          <TableOrderingSelect
            defaultValue={sortDirectionOptions?.[0]?.value}
            options={sortDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />
        </Flex>
      </Row>

      <Table
        rowKey="id"
        loading={isLoading}
        columns={getTableColumns(getActionColumnMenu, onSwitch, role)}
        dataSource={data?.results ?? []}
        bordered
        rowSelection={rowSelection}
        pagination={{
          ...pagination,
          total: data?.count,
          showSizeChanger: true
        }}
      />

      {currentUserId && (
        <EditModal
          id={currentUserId}
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  );
};
