import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Radio,
  Button,
  Typography,
  Row,
  Col,
  Input,
  Space,
  Result
} from "antd";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import { queries, removeLocalStorageTokens } from "api";
import { Add } from "assets/svg";
import { LoadingScreen } from "components/LoadingScreen";
import {
  ContentTypesModalForm,
  ContentTypesTable
} from "features/content-types/components";
import { UserRole } from "types";
import { filterByStatus } from "utils";

/**
 * ContentTypesPage is used to show the list of created content types
 */
export const ContentTypesPage = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const params = queryString.parse(location.search);

  // Fetch content type list
  const {
    data: contentTypes,
    refetch: refetchContentTypes,
    isLoading
  } = useQuery({
    ...queries.contentType.all({
      ...params,
      ...(!!params.type && { category: params.type })
    })
  });

  const handleChangeFilters = useCallback(
    (key: string) => (value: string) => {
      const newFilters = {
        ...params,
        ...(!!value && { [key]: value })
      };

      if (value === "all" || value === undefined) {
        delete newFilters[key];
      }

      const url = queryString.stringifyUrl({
        url: "/content-types",
        query: newFilters
      });

      navigate(url);
    },
    [params, navigate]
  );

  useEffect(() => {
    handleChangeFilters("type")(params.type as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: profileInfo, isLoading: profileLoading } = useQuery({
    ...queries.profile.profile(),
    retry: false,
    onError: () => {
      removeLocalStorageTokens();
      navigate("/authentication/login");
    }
  });

  if (profileLoading) {
    return <LoadingScreen />;
  }

  if (profileInfo?.role !== UserRole.SUPERUSER) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Nu aveți permisiunea de a accesa această pagină."
        extra={
          <Link to="/statistics">
            <Button type="primary">Inapoi</Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Col xs={24} lg={8}>
          <div className="d-flex align-items-center gap-8">
            <Typography.Title
              className="mb-0 text-nowrap"
              level={5}
            >{`Content Types (${contentTypes?.length || "0"})
            `}</Typography.Title>

            <Input.Search
              onChange={(e) => handleChangeFilters("search")(e.target.value)}
              placeholder="Search"
              className="mw-300"
              size="large"
            />
          </div>
        </Col>
        <Col>
          <Space>
            <Radio.Group
              defaultValue={params.status || "all"}
              options={filterByStatus}
              onChange={(e) => handleChangeFilters("status")(e.target.value)}
              buttonStyle="solid"
              optionType="button"
              size="large"
            />
            <Button
              type="primary"
              size="large"
              icon={<Add />}
              onClick={() => setModalOpen(true)}
            >
              Add content type
            </Button>
          </Space>
        </Col>
      </Row>

      <ContentTypesTable
        loading={isLoading}
        contentTypes={contentTypes || []}
        fetchContentTypes={refetchContentTypes}
      />
      <ContentTypesModalForm
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};
