import { Key, useState } from "react";
import { Flex, Input, Row, Table } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import {
  DeleteButton,
  DeleteConfirmModal,
  TableOrderingSelect
} from "components";
import { sortProjectDirectionOptions } from "features/projects/utils/utils";
import { resetPagination, showErrorMessage, useTablePagination } from "utils";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { getIdeasTableColumns } from "../tableColumns";

export const IdeasTable = () => {
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useState("-updated_at");

  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const pagination = useTablePagination();

  const queryParams = {
    ordering,
    page: pagination.current,
    per_page: pagination.pageSize,
    search
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.ideas.all(stringifiedParams)
  });

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: (ids: Key[]) => api.ideas.bulkDelete(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.ideas.all());
      setSelectedRowKeys([]);
    },
    onError: showErrorMessage
  });

  const onSelectRowChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange
  };

  const onBulkDelete = () => {
    const ids = rowSelection?.selectedRowKeys.map((key) => key);

    const onOk = () => bulkDelete(ids);
    DeleteConfirmModal(onOk, true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          className="mw-300"
          placeholder="Caută idei..."
          size="large"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex gap={8} align="center" justify="center">
          {hasSelected && (
            <DeleteButton
              loading={isBulkDeleteLoading}
              onClick={onBulkDelete}
            />
          )}
          <TableOrderingSelect
            defaultValue={sortProjectDirectionOptions?.[0]?.value}
            options={sortProjectDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />
        </Flex>
      </Row>

      <Table
        rowKey="id"
        loading={isLoading}
        columns={getIdeasTableColumns()}
        rowSelection={rowSelection}
        dataSource={data?.results ?? []}
        bordered
        pagination={{ ...pagination, total: data?.count }}
      />
    </>
  );
};
