import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Tour, TourProps, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";
import { UserRole } from "types";

const nextButtonProps = {
  children: "Următorul"
};

const prevButtonProps = {
  children: "Anterior"
};

const getElement = (id: string) => document.querySelector(id) as HTMLElement;
const getElementById = (id: string) =>
  document.getElementById(id) as HTMLElement;

export const TourGuide = (props: TourProps) => {
  const { data } = useQuery({
    ...queries.profile.profile(),
    retry: false
  });

  const navigate = useNavigate();

  const steps: TourProps["steps"] = useMemo(
    () => [
      {
        title: "Panou de statistică",
        description:
          "Pagina Statistici oferă informații despre, date despre vizitatori si alte date statistice.",
        target: () => getElement("[data-menu-id$='-statistics']"),
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Numărul total de utilizatori activi",
        description: "Aici veți găsi numărul total de utilizatori activi.",
        target: () => getElementById("statistic-users"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Numărul de proiecte depuse",
        description:
          "Aici veți găsi numărul de proiecte depuse în prezent in fiecare lună.",
        target: () => getElementById("statistic-projects"),

        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/users/citizens");
          }
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      },
      //users
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              title: "Management utilizatori",
              description:
                "Aici veți găsi numărul de utilizatori activi și inactivi.",
              target: () => getElement("[data-menu-id$='-users']"),
              nextButtonProps: {
                ...nextButtonProps
              },
              prevButtonProps: {
                ...prevButtonProps,
                onClick: () => {
                  navigate("/statistics");
                }
              }
            },
            {
              title: "Cetățeni",
              description:
                "În acest tabel veți găsi informații despre cetățenii înregistrați",
              target: () => getElement("[data-node-key='/users/citizens']"),
              prevButtonProps: {
                ...prevButtonProps
              },
              nextButtonProps: {
                ...nextButtonProps,
                onClick: () => {
                  navigate("/users/employees");
                }
              }
            },
            {
              title: "Angajați",
              description: (
                <Typography.Text>
                  În acest tabel veți găsi informații despre angajații
                  înregistrați.
                </Typography.Text>
              ),
              target: () => getElement("[data-node-key='/users/employees']"),
              prevButtonProps: {
                ...prevButtonProps
              },
              nextButtonProps: {
                ...nextButtonProps
              }
            },
            {
              title: "Adaugă angajat",
              description: (
                <Typography.Text>
                  Prin apăsarea butonului <b>Adaugă angajat</b> veți putea
                  adăuga un nou angajat.
                </Typography.Text>
              ),
              target: () => getElementById("add-employee-button"),
              prevButtonProps: {
                ...prevButtonProps
              },
              nextButtonProps: {
                ...nextButtonProps,
                onClick: () => {
                  navigate("/voting");
                }
              },
              disabledInteraction: true
            }
          ]
        : []),

      //voting
      {
        title: "Votare",
        description: (
          <Typography.Text>
            Pagina Votare oferă informații despre votările active și propuse.
          </Typography.Text>
        ),
        target: () => getElement("[data-menu-id$='-voting']"),
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/users/employees");
          }
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Votare",
        description: (
          <Typography.Text>
            Prin apăsarea butonului <b>Creează votare</b> veți putea adăuga o
            nouă votare.
          </Typography.Text>
        ),
        target: () => getElementById("add-voting-button"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/projects?tab=active");
          }
        },
        disabledInteraction: true
      },
      //projects
      {
        title: "Proiecte",
        description:
          "Pagina Proiecte oferă informații despre proiectele active, propuse și ideile propuse.",

        target: () => getElement("[data-menu-id$='-projects']"),
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/voting");
          }
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Taburi",
        description: "Taburi pentru proiecte active, propuse și idei propuse.",
        target: () => getElementById("projects-tabs"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Filtrare",
        description: (
          <Typography.Text>
            Filtrare după domeniu, data și statusul proiectului.
          </Typography.Text>
        ),
        target: () => getElementById("projects-table-filters"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/polls");
          }
        },
        disabledInteraction: true
      },
      //surveys
      {
        title: "Sondaje",
        description:
          "Pagina Sondaje oferă informații despre sondajele active și propuse",
        target: () => getElement("[data-menu-id$='-polls']"),
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/projects?tab=active");
          }
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Adaugă sondaj",
        description: (
          <Typography.Text>
            Prin apăsarea butonului <b>Creează un sondaj nou</b> veți putea
            adăuga un nou sondaj.
          </Typography.Text>
        ),
        target: () => getElementById("add-new-poll"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/polls/add");
          }
        }
      },
      //poll create
      {
        title: "Titlu sondaj",
        description: "Introduceți un titlu pentru sondaj.",
        target: () => getElementById("poll-title"),
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/polls");
          }
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Descriere sondaj",
        description: "Introduceți o description pentru sondaj.",
        target: () => getElementById("poll-description"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Perioada sondaj",
        description: "Selectați perioada pentru sondaj.",
        target: () => getElementById("poll-period"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps
        }
      },
      {
        title: "Întrebări sondaj",
        description: "Adăugați întrebări pentru sondaj.",
        target: () => getElementById("poll-questions"),
        prevButtonProps: {
          ...prevButtonProps
        },
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/content-types");
          }
        }
      },
      //content-types
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              title: "Tipuri de conținut",
              description:
                "Pagina Tipuri de conținut oferă informații despre tipurile de conținut disponibile.",
              target: () => getElement("[data-menu-id$='-content-types']"),
              nextButtonProps: {
                ...nextButtonProps,
                onClick: () => {
                  navigate("/content");
                }
              },
              prevButtonProps: {
                ...prevButtonProps,
                onClick: () => {
                  navigate("/polls/add");
                }
              }
            }
          ]
        : []),
      //content
      {
        title: "Conținut",
        description:
          "Pagina Conținut oferă informații despre conținutul disponibil.",
        target: () => getElement("[data-menu-id$='-content']"),
        nextButtonProps: {
          ...nextButtonProps
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      },
      {
        title: "Cautare si filtrare",
        description:
          "Căutare și filtrare după tipul de conținut, data și statusul conținutului.",
        target: () => getElementById("content-table-filters"),
        nextButtonProps: {
          ...nextButtonProps
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      },
      {
        title: "Tabel conținut",
        description:
          "Tabelul conținutului oferă informații despre conținutul disponibil.",
        target: () => getElementById("content-table"),
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/media");
          }
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      },
      //media
      {
        title: "Media",
        description: "Pagina Media oferă informații despre media disponibilă.",
        target: () => getElement("[data-menu-id$='-media']"),
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate(
              data?.role === UserRole.SUPERUSER ? "/settings" : "/notifications"
            );
          }
        },
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/content");
          }
        }
      },
      //settings
      ...(data?.role === UserRole.SUPERUSER
        ? [
            {
              title: "Setări",
              description:
                "Pagina Setări oferă informații despre setările disponibile.",
              target: () => getElement("[data-menu-id$='-settings']"),
              nextButtonProps: {
                ...nextButtonProps
              },
              prevButtonProps: {
                ...prevButtonProps
              }
            },
            {
              title: "Setări",
              description:
                "Pagina Setări oferă informații despre setările disponibile.",
              target: () => getElementById("settings-form"),
              nextButtonProps: {
                ...nextButtonProps,
                onClick: () => {
                  navigate("/notifications");
                }
              },
              prevButtonProps: {
                ...prevButtonProps
              }
            }
          ]
        : []),

      //notifications
      {
        title: "Notificări",
        description:
          "Pagina Notificări oferă informații despre notificările disponibile.",
        target: () => getElement("[data-menu-id$='-notifications']"),
        nextButtonProps: {
          ...nextButtonProps,
          onClick: () => {
            navigate("/profile");
          }
        },
        prevButtonProps: {
          ...prevButtonProps,
          onClick: () => {
            navigate("/settings");
          }
        }
      },
      //profile
      {
        title: "Profil",
        description:
          "Pagina Profil oferă informații despre profilul utilizatorului.",
        target: () => getElement("[data-menu-id$='-profile']"),
        nextButtonProps: {
          ...nextButtonProps
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      },
      {
        title: "Detalii profil",
        description:
          "Pagina Profil oferă informații despre profilul utilizatorului.",
        target: () => getElementById("profile-form"),
        nextButtonProps: {
          children: "Gata"
        },
        prevButtonProps: {
          ...prevButtonProps
        }
      }
    ],
    [data?.role, navigate]
  );

  return <Tour steps={steps} {...props} />;
};
