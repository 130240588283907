import { UploadFile } from "antd";
import { ProjectFields } from "./projectDomains";

export type LocationFields = {
  lat: number;
  lng: number;
};

export enum ProjectStatus {
  DRAFT = "draft",
  ACTIVE = "active",
  ARCHIVED = "archived"
}

export type ProjectChart = {
  month: string;
  total: number;
};

export type FinancialResources = {
  name: string;
  amount: number;
};

export type ProjectEntity = {
  id: number;
  title: string;
  title_ro: string;
  title_en: string;
  title_ru: string;
  first_name: string;
  last_name: string;
  field: ProjectFields;
  email: string;
  phone: string;
  address: string;
  location: LocationFields;
  description: string;
  description_en: string;
  description_ro: string;
  description_ru: string;
  organization: string;
  status: ProjectStatus;
  budget: string;
  created_at: string;
  attachments: File[];
  updated_at: string;
  start_date: string;
  end_date: string;
  financial_resources: FinancialResources[];
  total_votes: number;
  voting_total_votes: number;
};

type Photo = Omit<UploadFile, "uid">;

type UploadedFile = {
  file_size?: number;
  file_name?: string;
  url?: string;
};

export type File = Photo & UploadedFile;
