import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  notification,
  Row,
  Spin,
  Typography
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import { NAME_CHARACTERS, PHONE_REGEX } from "app-constants";
import { formStyles } from "features/profile/styles";
import { UploadComponent } from "features/projects/components";
import { File, UserEntity } from "types";
import { getUserInitials, showErrorMessage } from "utils";

export const ProfileForm = () => {
  const [file, setFile] = useState<File[]>([]);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const cssStyles = formStyles();

  const formItemBaseProps = {
    rules: [{ required: true }],
    css: cssStyles.form_item
  };

  const { data, isLoading } = useQuery({
    ...queries.profile.profile()
  });

  const initialValues = {
    first_name: data?.first_name,
    last_name: data?.last_name,
    email: data?.email,
    obscureFieldName: data?.email,
    phone: data?.phone,
    photo: data?.photo,
    address: data?.address
  };

  useEffect(() => {
    setFile(data?.photo ? [data?.photo] : []);
  }, [data]);

  const { mutate: updateProfile, isLoading: updateProfileLoading } =
    useMutation({
      mutationFn: (values: UserEntity) => api.profile.update(values),
      onSuccess: () => {
        notification.success({
          message: "Profilul a fost actualizat cu succes",
          duration: 3
        });
        queryClient.invalidateQueries(queries.profile.profile());
      },
      onError: showErrorMessage
    });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      updateProfile({
        ...values,
        photo: {
          url: file[0]?.url,
          file_name: file[0]?.name ?? file[0]?.file_name,
          file_size: file[0]?.size ?? file[0]?.file_size
        }
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      autoComplete="nope"
    >
      <Card>
        <Flex>
          <Flex flex={1} vertical>
            <Typography.Text strong>Date personale</Typography.Text>
            <Typography.Text>
              Actualizați poza și informația personală.
            </Typography.Text>
          </Flex>
          <Flex flex={1} vertical>
            <Row css={cssStyles.row}>
              <Col xs={24}>
                <Flex gap={16}>
                  <Form.Item<UserEntity>
                    name="first_name"
                    label="Nume"
                    css={cssStyles.form_item}
                    rules={[
                      { required: true },
                      {
                        pattern: NAME_CHARACTERS,
                        message: "Introduceți doar litere"
                      }
                    ]}
                  >
                    <Input placeholder="Introduceti numele" size="large" />
                  </Form.Item>
                  <Form.Item<UserEntity>
                    name="last_name"
                    label="Prenume"
                    css={cssStyles.form_item}
                    rules={[
                      { required: true },
                      {
                        pattern: NAME_CHARACTERS,
                        message: "Introduceți doar litere"
                      }
                    ]}
                  >
                    <Input placeholder="Introduceti prenumele" size="large" />
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
            <Row css={cssStyles.row}>
              <Col xs={24}>
                <Flex gap={16}>
                  <Form.Item<UserEntity>
                    name="obscureFieldName"
                    label="Posta electronica"
                    {...formItemBaseProps}
                  >
                    <Input
                      size="large"
                      disabled
                      autoComplete="off"
                      readOnly
                      data-lpignore="true"
                    />
                  </Form.Item>
                  <Form.Item<UserEntity>
                    name="phone"
                    label="Telefon"
                    {...formItemBaseProps}
                    rules={[
                      { required: true },
                      {
                        pattern: PHONE_REGEX,
                        message: "Introduceți un număr de telefon valid"
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Introduceti nr de telefon"
                    />
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
            <Row css={cssStyles.row}>
              <Col xs={24}>
                <Form.Item<UserEntity>
                  name="address"
                  label="Adresa"
                  css={cssStyles.form_item}
                >
                  <Input placeholder="Introduce-ti adresa" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row css={cssStyles.row}>
              <Col xs={24} lg={4}>
                <Flex align="center" vertical gap="12px">
                  <Avatar
                    size={70}
                    css={cssStyles.avatar}
                    src={file?.[0]?.url ?? undefined}
                  >
                    {getUserInitials(data?.first_name, data?.last_name)}
                  </Avatar>

                  {!!file?.length && (
                    <Button color="blue" onClick={() => setFile([])}>
                      Sterge
                    </Button>
                  )}
                </Flex>
              </Col>
              <Col xs={24} lg={20}>
                <UploadComponent
                  files={file}
                  setFormData={setFile}
                  multiple={false}
                  avatar
                />
              </Col>
            </Row>
          </Flex>
        </Flex>
        <Flex justify="flex-end" gap={8}>
          <Button
            size="large"
            onClick={() => {
              form.resetFields();
            }}
          >
            Anulează
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={updateProfileLoading}
            onClick={onSubmit}
          >
            Salvează
          </Button>
        </Flex>
      </Card>
    </Form>
  );
};
