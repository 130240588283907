import React from "react";
import { FileOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";

import { Media } from "components/FormBuilder/FormFields/interface";
import { checkImageURL } from ".";

export const getTableColumns = () => {
  const columns: ColumnProps<Media>[] = [
    {
      title: "Preview",
      dataIndex: "url",
      render: (_, item) => {
        const isImage = checkImageURL(item.url);

        return (
          <div className="media-list__preview">
            {isImage ? (
              <img src={item.url} alt={item.name} style={{ maxWidth: 100 }} />
            ) : (
              <FileOutlined />
            )}
          </div>
        );
      }
    },
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Type",
      dataIndex: "content_type"
    },
    {
      title: "Updated",
      dataIndex: "updatedDate"
    },
    {
      title: "Status",
      dataIndex: "status"
    }
  ];

  return columns;
};
