import { Key, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Input, message, Row, Table, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import moment from "moment";
import api, { queries } from "api";
import { Add } from "assets/svg";
import {
  DeleteButton,
  DeleteConfirmModal,
  TableOrderingSelect
} from "components";
import { PollEntity } from "types";
import { resetPagination, showErrorMessage, useTablePagination } from "utils";
import { YEAR_FORMAT } from "utils/dateFormats";
import { stringifyAndParseQueryParams } from "utils/queryParams";
import { getTableColumns } from "../components";
import { getPollsCreateYears, sortPollsDirectionOptions } from "../utils";

export const PollsPage = () => {
  const [search, setSearch] = useState("");
  const [ordering, setOrdering] = useState("-id");
  const [pollsDate, setPollsDate] = useState<string[]>([]);
  const [createDate, setCreateDate] = useState("");

  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const pagination = useTablePagination();
  const navigate = useNavigate();

  const queryParams = {
    page: pagination.current,
    per_page: pagination.pageSize,
    year: createDate,
    ordering,
    search
  };

  const stringifiedParams = stringifyAndParseQueryParams(queryParams);

  const { data, isLoading } = useQuery({
    ...queries.polls.all(stringifiedParams)
  });

  const dateFilters = getPollsCreateYears(pollsDate ?? []);

  const onSearch = (value: string) => {
    setSearch(value);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const onChangeDate = (value: string) => {
    setCreateDate(value);
    resetPagination(pagination);
    setSelectedRowKeys([]);
  };

  const onMutationSuccess = () => {
    queryClient.invalidateQueries(queries.polls.all());
  };

  const { mutate: deleteOne } = useMutation({
    mutationFn: (id: number) => api.polls.deletePoll(id),
    onSuccess: onMutationSuccess,
    onError: showErrorMessage
  });

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: (ids: Key[]) => api.polls.bulkDelete(ids),
    onSuccess: () => {
      onMutationSuccess();
      setSelectedRowKeys([]);
    },
    onError: showErrorMessage
  });

  const onSelectRowChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange
  };

  const onBulkDelete = () => {
    const ids = rowSelection?.selectedRowKeys.map((key) => key);

    const onOk = () => bulkDelete(ids);
    DeleteConfirmModal(onOk, true);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const getActionColumnMenu = (context: PollEntity): MenuProps["items"] => [
    {
      key: "edit",
      label: "Editează",
      onClick: () => {
        navigate(`/polls/edit/${context.id}`);
      }
    },
    {
      key: "delete",
      label: "Șterge",
      onClick: () => {
        const onOk = () => deleteOne(context.id);
        DeleteConfirmModal(onOk);
      }
    }
  ];

  const currentDate = dayjs();
  const isActivePoll = data?.results?.some(
    (voting) =>
      currentDate.isAfter(voting.start_date) &&
      currentDate.isBefore(voting.end_date)
  );

  const onAddClick = () => {
    isActivePoll
      ? message.error("Doar un sondaj activ poate fi creat")
      : navigate(`/polls/add`);
  };

  const { mutate: editActive } = useMutation({
    mutationFn: (context: PollEntity) => api.polls.update(context?.id, context),
    onSuccess: (data) => {
      message.success("Sondajul a fost actualizat");
      queryClient.invalidateQueries(queries.polls.byId(Number(data?.id)));
      onMutationSuccess();
    },
    onError: showErrorMessage
  });

  const onSwitch = (context: PollEntity) => {
    context.is_active
      ? editActive({ ...context, is_active: false })
      : editActive({ ...context, is_active: true });
  };

  useEffect(() => {
    if (data?.results && !pollsDate?.length) {
      const dates = data?.results?.map((poll) =>
        moment(poll?.start_date).format(YEAR_FORMAT)
      );
      setPollsDate(dates ?? []);
    }
  }, [data, pollsDate]);

  return (
    <>
      <Row className="mb-20" align="middle" justify="space-between">
        <Flex gap={4} align="baseline">
          <Typography.Title level={2}>Management sondaje</Typography.Title>
          <Typography.Title level={5}>({data?.count ?? 0})</Typography.Title>
        </Flex>
        <Button
          type="primary"
          icon={<Add />}
          onClick={onAddClick}
          id="add-new-poll"
        >
          Creează un sondaj nou
        </Button>
      </Row>
      <Row align="middle" justify="space-between" className="mb-20">
        <Input.Search
          allowClear
          className="mw-300"
          size="large"
          placeholder="Cautare dupa titlu"
          onChange={(e) => onSearch(e.target.value)}
        />

        <Flex gap={8} align="center" justify="center">
          {hasSelected && (
            <DeleteButton
              loading={isBulkDeleteLoading}
              onClick={onBulkDelete}
            />
          )}
          <TableOrderingSelect
            defaultValue={dateFilters?.[0]?.value}
            options={dateFilters}
            onChange={(value) => onChangeDate(value)}
          />

          <TableOrderingSelect
            defaultValue={sortPollsDirectionOptions?.[1]?.value}
            options={sortPollsDirectionOptions}
            onChange={(value) => setOrdering(value)}
          />
        </Flex>
      </Row>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={getTableColumns(getActionColumnMenu, onSwitch)}
        bordered
        rowSelection={rowSelection}
        dataSource={data?.results ?? []}
        pagination={{
          ...pagination,
          total: data?.count,
          hideOnSinglePage: true
        }}
      />
    </>
  );
};
