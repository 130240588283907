import { Flex } from "antd";
import { BackButton } from "components/BackButton";
import { DraftLayout, ExtraContent } from "features/projects/Layouts";
import { ActiveLayout, Header } from "features/projects/Layouts/ActiveLayout";
import { ProjectDetails } from "features/projects/types";
import { ProjectStatus } from "types";

export const ViewDetails = ({ data }: ProjectDetails) => {
  const isDraft = data?.status === ProjectStatus.DRAFT;

  return (
    <Flex vertical gap={8}>
      <BackButton path="/projects" tab={isDraft ? "proposed" : "active"} />

      {!isDraft && <Header data={data} />}

      <Flex justify="space-between" gap="24px">
        <Flex
          vertical
          style={{
            width: "70%"
          }}
        >
          {isDraft ? <DraftLayout data={data} /> : <ActiveLayout data={data} />}
        </Flex>

        <ExtraContent isDraft={isDraft} data={data} />
      </Flex>
    </Flex>
  );
};
