import { createQueryKeys } from "@lukemorales/query-key-factory";
import { PasswordData, Settings, UserEntity } from "types";
import { ssoClient } from "./axios";

const getProfile = async () => {
  const response = await ssoClient.get<UserEntity>("/profile");

  return response.data;
};

const update = async (profile: UserEntity) => {
  const { data } = await ssoClient.patch<Settings>("/profile", profile);

  return data;
};

const updatePassword = async (password: PasswordData) => {
  const { data } = await ssoClient.patch("/profile/change-password", password);

  return data;
};

const profile = {
  getProfile,
  update,
  updatePassword
};

export const profileQueries = createQueryKeys("profile", {
  profile: () => ({
    queryKey: ["profile"],
    queryFn: () => getProfile()
  })
});

export default profile;
