import { Form, FormInstance, Tabs } from "antd";
import Editor from "components/Editor";
import { styles } from "features/users/components/Wrapper/styles";
import { SharedFormItemProps } from "./types";

export const languages = {
  ro: "Română",
  en: "Engleză",
  ru: "Rusă"
};

type Props = {
  form: FormInstance;
  formItems: SharedFormItemProps[];
  children?: React.ReactNode;
  initialValues?: any;
};

const EditorWrapper = ({ data, field, langKey, onChange }) => {
  const fieldData = data[`${field}_${langKey}`];

  const onEditorChange = (newData) => {
    onChange(newData);
  };

  return <Editor key={fieldData} data={fieldData} onChange={onEditorChange} />;
};

const RenderItem = (item: SharedFormItemProps, key: string) => {
  return (
    <>
      {item.shared || !item.needsLangKey
        ? item.component
        : item?.renderComponentWithKey?.(key)}
    </>
  );
};

export const MultiLanguageForm = ({
  form,
  formItems,
  children,
  initialValues = ""
}: Props) => {
  const cssStyles = styles();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      style={{
        maxWidth: 500
      }}
    >
      <Tabs
        size="large"
        defaultActiveKey="ro"
        items={Object.entries(languages).map(([key, label]) => ({
          key,
          label,
          children: (
            <div key={key}>
              {formItems.map((item) => {
                const itemKey = item.shared ? item.name : `${item.name}_${key}`;

                const ruleMessage = `Vă rugăm introduceți ${item.label}`;

                return (
                  <div key={item.id ?? itemKey} id={item.id ?? itemKey}>
                    <Form.Item
                      key={item.name}
                      name={itemKey}
                      label={item.shared ? item.label : `${item.label} ${key}`}
                      rules={[{ ...item.rules[0], message: ruleMessage }]}
                      normalize={(value: any) => {
                        if (item.type === "Editor") {
                          if (value && typeof value === "object") {
                            return value?.editor?.getData();
                          }
                        }

                        return value;
                      }}
                    >
                      {item.type === "Editor" ? (
                        <EditorWrapper
                          data={initialValues}
                          field={item.name}
                          langKey={key}
                          onChange={(newData) =>
                            form.setFieldsValue({
                              [itemKey]: newData?.editor?.getData()
                            })
                          }
                        />
                      ) : (
                        RenderItem(item, key)
                      )}
                    </Form.Item>
                  </div>
                );
              })}
            </div>
          ),
          forceRender: true
        }))}
        css={cssStyles.tabs}
      />

      {children}
    </Form>
  );
};
