import { SelectProps } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const sortPollsDirectionOptions: SelectProps["options"] = [
  { label: "Ascendent", value: "id" },
  { label: "Descendent", value: "-id" }
];

export const SurveyTypeOptions: SelectProps["options"] = [
  { label: "Text", value: "text" },
  { label: "Răspuns unic", value: "select" },
  { label: "Răspuns multiplu", value: "multiple_select" }
];

export const getActiveVoting = (startDate: string, endDate: string) => {
  const currentDate = dayjs();

  const isActiveVoting =
    dayjs(currentDate).isBetween(startDate, endDate, null, "[]") ?? false;

  return isActiveVoting;
};

export const getPollsCreateYears = (polls: string[]) => {
  const years = polls?.filter(
    (value, index, self) => self.indexOf(value) === index
  );

  return [
    { label: "Toti anii", value: "" },
    ...(years ?? []).map((year) => ({
      label: year,
      value: year
    }))
  ];
};
