import { createQueryKeys } from "@lukemorales/query-key-factory";
import { AxiosResponse } from "axios";

import { CreateEditForm, Form, FormResponse, Paginated } from "types";
import { cmsClient } from "./axios";

const create = async (data: CreateEditForm) => {
  const response = await cmsClient.post<CreateEditForm, AxiosResponse<Form>>(
    "/forms/",
    data
  );
  return response.data;
};

const getAll = async () => {
  const response = await cmsClient.get<Form[]>("/forms/");
  return response.data;
};

const getById = async (id: string) => {
  const response = await cmsClient.get<Form>(`/forms/${id}`);
  return response.data;
};

const update = async (id: string, data: CreateEditForm) => {
  const response = await cmsClient.put<CreateEditForm, AxiosResponse<Form>>(
    `/forms/${id}`,
    data
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await cmsClient.delete<void>(`/forms/${id}`);
  return response.data;
};

const getFormResponses = async (
  id: string,
  params: { [key: string]: string }
) => {
  const { data } = await cmsClient.get<Paginated<FormResponse[]>>(
    `/forms/${id}/responses`,
    { params }
  );

  return data;
};

const forms = {
  create,
  getAll,
  getById,
  update,
  remove,
  getFormResponses
};

export const formsQueries = createQueryKeys("forms", {
  all: () => ({
    queryKey: ["list"],
    queryFn: () => getAll()
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  responses: (id, params?) => ({
    queryKey: [{ id, ...params }],
    queryFn: () => {
      return getFormResponses(id, params);
    }
  })
});

export default forms;
