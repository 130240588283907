import { AppProps, ConfigProviderProps } from "antd";
import { SerializedStyles } from "@emotion/react";
import { UserEntity } from "./users";

export interface Tokens {
  access: string | null;
  refresh: string | null;
}

export interface ApiConfig {
  baseUrl?: string;
  tokens?: Tokens;
  appToken?: string;
}

export interface ApplicationInstance {
  id: number;
  name: string;
  type: string;
  is_public?: boolean;
  description?: string;
  endpoint_url?: string;
  is_installed?: boolean;
  is_multiple?: boolean;
  date_created?: string;
  date_updated?: string;
  roles?: string[] | null;
}

export interface Application {
  id: number;
  name: string;
  is_public: boolean;
  settings?: Record<string, unknown> | null;
  instance_type: ApplicationInstance;
  token: string;
}

export interface Organization {
  id: number;
  name: string;
  photo: string;
  domain_name: string;
  date_created: string;
  settings?: Record<string, unknown> | null;
}

export interface AvailableApplication {
  id: number;
  name: string;
  token: string;
}

export interface SessionData<T = unknown> {
  id: number;
  user: UserEntity;
  role: string;
  organization: Organization;
  available_applications: { [key: string]: AvailableApplication };
  available_organizations: Organization[];
  pinned_applications: Application[];
  current_application: {
    application_role: T;
    type: string;
    name: string;
    token: string;
    settings: Record<string, string>;
  } | null;
}

export interface Session {
  data: SessionData | null;
  tokens: Tokens;
}

export interface SessionContextState {
  /**
   * The session object.
   *
   * If is null, then we are not within a session,
   * otherwise we are and can use the tokens to make a request.
   *
   * The validity of the tokens are confirmed by the request you make.
   * If the API requires new tokens, then use the correspoing API to get
   * new tokens and then set them in session via `setTokens` function.
   */
  session: Session | null;
  /**
   * Object that stores api configs for different environements.
   */
  apiConfig: any | null;
  /**
   * Function to change tokens within a session.
   */
  setTokens: (tokens: Tokens) => Promise<Error | null>;
  /**
   * This will set session object to null.
   */
  removeSession: () => void;
}

export interface ServiceConfig {
  /**
   * The base url of the service.
   */
  baseUrl: string;

  /**
   * The Saas application token
   */
  token?: string;
}

/**
 * Additional options for the bootstrap
 */
export interface BootstrapOptions {
  /**
   * Some applications doesn't need the organization provider
   * For these cases, the hasOrganization option will check
   * if the application need the organization.
   */
  hasOrganization: boolean;
  onDataReceived?: (data: unknown, config: BootstrapConfig) => void;
}

/**
 * Service types in the platform
 *
 * Using string values for better error mesages.
 */
export enum ServiceTypes {
  SSO = "SSO",
  CMS = "CMS",
  CRM = "CRM",
  HRM = "HRM",
  PLANNING = "PLANNING",
  ORGANIZATION = "ORGANIZATION",
  MONITORING = "MONITORING",
  ROCKET_APPS_DESIGNER = "ROCKET_APPS_DESIGNER",
  ROCKET_APPS_EXECUTION = "ROCKET_APPS_EXECUTION",
  SMART_PROCESS = "SMART_PROCESS",
  CALENDAR = "CALENDAR",
  MAIL = "MAIL",
  CONNECT = "CONNECT",
  AUTH = "AUTH",
  MEDIA = "MEDIA",
  NOTIFICATIONS = "NOTIFICATIONS",
  ECOMMERCE = "ECOMMERCE"
}

export type BootstrapConfig = {
  [Property in keyof typeof ServiceTypes]?: ServiceConfig;
};

export interface BootstrapProps {
  config: BootstrapConfig;
  onInvalidSession?: (error?: Error | null, status?: StatusTypes) => void;
  onValidSession?: (session?: Session | null, status?: StatusTypes) => void;
  options?: BootstrapOptions;
  appConfig?: AppProps;
  themeConfig?: ConfigProviderProps;
  globalStyle?: SerializedStyles;
}

export enum StatusTypes {
  PENDING,
  RESOLVED,
  REJECTED
}

export interface BootstrapState {
  status: StatusTypes;
  session: Session | null;
  error: Error | null;
}

export interface Login {
  password: string;
  email: string;
  scope?: string;
}

export type SetPassword = Login & { confirm_password: string };

export interface Reset {
  email: string;
  otp_code: string;
  code_type: string;
}
