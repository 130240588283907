import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Form, Input, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";

import authorization from "api/authorization";
import { Login } from "types";
import { showErrorMessage } from "utils";

import { AuthLayout, FormWrapper } from "../components";
import { OtpCheckPage } from "./OtpCheckPage";

export const LoginPage = () => {
  const [form] = Form.useForm();
  const [showOTP, setShowOTP] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: authorization.login,
    onError: showErrorMessage,
    onSuccess: () => {
      setShowOTP(true);
    }
  });

  const onSubmit = (values: Login) => {
    sessionStorage.setItem("email", values.email);
    sessionStorage.setItem("password", values.password);
    mutate({ ...values, scope: "cms" });
  };

  return (
    <AuthLayout>
      {!showOTP ? (
        <FormWrapper
          form={form}
          onFinish={onSubmit}
          title="Logare"
          subTitle="Introdu adresa de e-mail și parola pentru a te conecta la contul tău."
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input
              type="email"
              placeholder="Introdu adresa de e-mail"
              suffix={<MailOutlined />}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="introduce-ti parola" size="large" />
          </Form.Item>

          <Flex vertical gap={24} align="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              block
              size="large"
            >
              Logare
            </Button>

            <Typography.Text strong>
              Ai uitat parola{" "}
              <Link to="/authentication/reset-password">Reseteaza parola</Link>
            </Typography.Text>
          </Flex>
        </FormWrapper>
      ) : (
        <OtpCheckPage type="login" />
      )}
    </AuthLayout>
  );
};
