import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, message, Space } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import api, { queries } from "api";

import { LoadingScreen } from "components/LoadingScreen";
import { styles } from "features/projects/Layouts/ActiveLayout/Header/styles";
import { File, PollEntity } from "types";
import { showErrorMessage } from "utils";
import { DATE_FORMAT } from "utils/dateFormats";
import { PollForm } from "../components/PollForm";

export const CreateEditPoll = () => {
  const { id } = useParams();
  const [files, setFiles] = useState<File[]>([]);

  const cssStyles = styles();

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    ...queries.polls.byId(Number(id)),
    enabled: !!Number(id)
  });

  const { mutate, isLoading: createLoading } = useMutation({
    mutationFn: (data: PollEntity) => api.polls.create(data),
    onSuccess: () => {
      message.success("Sondajul a fost creat cu succes");
      queryClient.invalidateQueries(queries.polls.all());
      navigate("/polls");
    },
    onError: showErrorMessage
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation({
    mutationFn: (data: PollEntity) => api.polls.update(Number(id), data),
    onSuccess: () => {
      message.success("Sondajul a fost actualizat cu succes!");
      queryClient.invalidateQueries(queries.polls.all());
      queryClient.invalidateQueries(queries.polls.byId(Number(id)).queryKey);
      navigate("/polls");
    },
    onError: showErrorMessage
  });

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();

      const questions = values?.questions?.map((question) => ({
        ...question,
        text: question?.text_ro,
        choices: question?.choices?.map((choice) => ({
          ...choice,
          text: choice?.text_ro
        }))
      }));

      const payload = {
        ...values,
        title: values?.title_ro,
        description: values?.description_ro,
        start_date: dayjs(values?.period[0]).format(DATE_FORMAT),
        end_date: dayjs(values?.period[1]).format(DATE_FORMAT),
        questions,
        image: {
          url: files[0]?.url,
          file_name: files[0]?.name ?? files[0]?.file_name,
          file_size: files[0]?.size ?? files[0]?.file_size
        }
      };

      id ? editMutate(payload) : mutate(payload);
    } catch (error) {
      message.error(
        "Este necesară completarea tuturor formularelor în toate limbile"
      );
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        period: [dayjs(data?.start_date), dayjs(data?.end_date)]
      });

      setFiles(data?.image ? [data?.image] : []);
    }
  }, [data, form]);

  if (id && isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Card className="mb-32" styles={cssStyles.card} css={cssStyles.card}>
        <Space>
          <Button onClick={() => navigate("/polls")}>Anulare</Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={onSubmit}
            loading={createLoading || editLoading}
          >
            {id ? "Actualizează" : "Creează"}
          </Button>
        </Space>
      </Card>
      <PollForm form={form} data={data} setFiles={setFiles} files={files} />
    </>
  );
};
