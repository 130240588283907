const VotingProject = () => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 21.6C0.5 17.0892 0.500686 13.7462 0.783566 11.1353C1.06543 8.53368 1.62265 6.71822 2.6963 5.24047C3.40562 4.26418 4.26418 3.40562 5.24047 2.6963C6.71822 1.62265 8.53368 1.06543 11.1353 0.783566C13.7462 0.500686 17.0892 0.5 21.6 0.5H26.4C30.9108 0.5 34.2538 0.500686 36.8647 0.783566C39.4663 1.06543 41.2818 1.62265 42.7595 2.6963C43.7358 3.40562 44.5944 4.26418 45.3037 5.24047C46.3773 6.71822 46.9346 8.53368 47.2164 11.1353C47.4993 13.7462 47.5 17.0892 47.5 21.6V26.4C47.5 30.9108 47.4993 34.2538 47.2164 36.8647C46.9346 39.4663 46.3773 41.2818 45.3037 42.7595C44.5944 43.7358 43.7358 44.5944 42.7595 45.3037C41.2818 46.3773 39.4663 46.9346 36.8647 47.2164C34.2538 47.4993 30.9108 47.5 26.4 47.5H21.6C17.0892 47.5 13.7462 47.4993 11.1353 47.2164C8.53368 46.9346 6.71822 46.3773 5.24047 45.3037C4.26418 44.5944 3.40562 43.7358 2.6963 42.7595C1.62265 41.2818 1.06543 39.4663 0.783566 36.8647C0.500686 34.2538 0.5 30.9108 0.5 26.4V21.6Z"
      fill="white"
    />
    <path
      d="M0.5 21.6C0.5 17.0892 0.500686 13.7462 0.783566 11.1353C1.06543 8.53368 1.62265 6.71822 2.6963 5.24047C3.40562 4.26418 4.26418 3.40562 5.24047 2.6963C6.71822 1.62265 8.53368 1.06543 11.1353 0.783566C13.7462 0.500686 17.0892 0.5 21.6 0.5H26.4C30.9108 0.5 34.2538 0.500686 36.8647 0.783566C39.4663 1.06543 41.2818 1.62265 42.7595 2.6963C43.7358 3.40562 44.5944 4.26418 45.3037 5.24047C46.3773 6.71822 46.9346 8.53368 47.2164 11.1353C47.4993 13.7462 47.5 17.0892 47.5 21.6V26.4C47.5 30.9108 47.4993 34.2538 47.2164 36.8647C46.9346 39.4663 46.3773 41.2818 45.3037 42.7595C44.5944 43.7358 43.7358 44.5944 42.7595 45.3037C41.2818 46.3773 39.4663 46.9346 36.8647 47.2164C34.2538 47.4993 30.9108 47.5 26.4 47.5H21.6C17.0892 47.5 13.7462 47.4993 11.1353 47.2164C8.53368 46.9346 6.71822 46.3773 5.24047 45.3037C4.26418 44.5944 3.40562 43.7358 2.6963 42.7595C1.62265 41.2818 1.06543 39.4663 0.783566 36.8647C0.500686 34.2538 0.5 30.9108 0.5 26.4V21.6Z"
      stroke="#E8EAED"
    />
    <path
      d="M23 31.9V16.1C23 14.6 22.36 14 20.77 14H16.73C15.14 14 14.5 14.6 14.5 16.1V31.9C14.5 33.4 15.14 34 16.73 34H20.77C22.36 34 23 33.4 23 31.9Z"
      fill="#030712"
    />
    <path
      d="M33.5 31.64V27.36C33.5 26.06 32.5 25 31.27 25H27.23C26 25 25 26.06 25 27.36V31.64C25 32.94 26 34 27.23 34H31.27C32.5 34 33.5 32.94 33.5 31.64Z"
      fill="#030712"
    />
    <path
      d="M33.5 20.64V16.36C33.5 15.06 32.5 14 31.27 14H27.23C26 14 25 15.06 25 16.36V20.64C25 21.94 26 23 27.23 23H31.27C32.5 23 33.5 21.94 33.5 20.64Z"
      fill="#030712"
    />
  </svg>
);

export default VotingProject;
