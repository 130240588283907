import { Form, FormProps, Space, Typography, theme } from "antd";

import { styles } from "./styles";

interface FormWrapperProps extends Omit<FormProps, "title"> {
  topStep?: React.ReactNode;
  bottomAction?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;

  children?: React.ReactNode;
}

export const FormWrapper = ({
  topStep,
  bottomAction,
  title,
  subTitle,
  children,
  ...props
}: FormWrapperProps) => {
  const { token } = theme.useToken();
  const cssStyle = styles(token);

  return (
    <Space direction="vertical" size={32} css={cssStyle.fullWidth}>
      {topStep}

      <Form layout="vertical" {...props}>
        <Space direction="vertical" size={32} css={cssStyle.fullWidth}>
          <div>
            {title && <Typography.Title level={2}>{title}</Typography.Title>}
            {subTitle && <Typography.Text>{subTitle}</Typography.Text>}
          </div>

          <div>{children}</div>
        </Space>

        {bottomAction}
      </Form>
    </Space>
  );
};
