import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Flex,
  message,
  Popconfirm,
  Space,
  Typography
} from "antd";
import { LinkOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import api from "api";
import { ProjectDetails } from "features/projects/types";
import { statusDisplayText } from "features/projects/utils";
import { ProjectStatus } from "types";
import { showErrorMessage } from "utils";
import { DAY_MONTH_YEAR_HOUR_MINUTE } from "utils/dateFormats";
import { styles } from "./styles";

export const Header = ({ data }: ProjectDetails) => {
  const navigate = useNavigate();

  const cssStyles = styles();

  const { mutate } = useMutation({
    mutationFn: () => api.projects.deleteProject(Number(data?.id)),
    onSuccess: () => {
      message.success("Proiectul a fost șters cu succes");
      navigate("/projects");
    },
    onError: showErrorMessage
  });

  return (
    <Card styles={cssStyles.card} css={cssStyles.card}>
      <Space>
        <Flex align="center" gap={8}>
          Status:
          <Typography.Text strong>
            {statusDisplayText[data?.status ?? ProjectStatus.DRAFT]}
          </Typography.Text>
        </Flex>
        Data publicarii:
        <Typography.Text strong>
          {data?.updated_at
            ? moment(data.updated_at).format(DAY_MONTH_YEAR_HOUR_MINUTE)
            : "-"}
        </Typography.Text>
      </Space>

      <Space>
        <Button
          onClick={() => navigate(`/projects/edit/${data?.id}`)}
          size="large"
        >
          Modifică
        </Button>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_BUDGETING_URL}/projects/${data?.id}`,
              "_blank"
            )
          }
        >
          Vezi pe website <LinkOutlined />
        </Button>

        <Popconfirm
          title="Ești sigur că vrei să ștergi acest proiect?"
          okText="Da"
          onConfirm={() => mutate()}
          placement="bottom"
          cancelText="Nu"
        >
          <Button type="default" danger size="large">
            <Flex align="center" gap={8}>
              <MinusCircleOutlined />
              Sterge acest proiect
            </Flex>
          </Button>
        </Popconfirm>
      </Space>
    </Card>
  );
};
