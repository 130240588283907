import { Dispatch, useState } from "react";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  Select,
  theme,
  Typography
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { RangePickerProps } from "antd/es/date-picker";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import L, { LatLngTuple } from "leaflet";
import { queries } from "api";
import {
  MultiLanguageForm,
  SharedFormItemProps
} from "components/MultiLanguageForm";
import { sortProjectsByDomains } from "features/projects/utils";
import { File, ProjectEntity } from "types";
import { FORAMT_DAY_MONTH_YEAR_WITH_SLASH } from "utils/dateFormats";
import { DomainModal } from "../DomainModal";
import { UploadComponent } from "../UploadComponent";
import { FinancialResources } from "./FinancialResources";

type Props = {
  data?: ProjectEntity;
  form: FormInstance<ProjectEntity>;
  files: File[];
  setFiles: Dispatch<React.SetStateAction<File[]>>;
};

const { Option } = Select;
const { RangePicker } = DatePicker;

type PositionInput = LatLngTuple | LatLngTuple[] | undefined;

const normalizePositions = (position: PositionInput): LatLngTuple[] =>
  Array.isArray(position?.[0])
    ? (position as LatLngTuple[])
    : [position as LatLngTuple];

export const CreateProjectForm = ({ data, files, form, setFiles }: Props) => {
  const { token } = theme.useToken();
  const [isDomainModalVisible, setIsDomainModalVisible] = useState(false);

  const { data: projectDomainList } = useQuery({
    ...queries.projectDomains.all()
  });

  const projectDomains = sortProjectsByDomains(
    projectDomainList?.results ?? []
  );

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && dayjs(current).startOf("day") < dayjs().startOf("day");
  };

  const formItems: SharedFormItemProps[] = [
    {
      name: "title",
      label: "Titlu",
      rules: [{ required: true }],
      component: <Input />,
      shared: false
    },
    {
      name: "field",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            width: "500px"
          }}
        >
          Domeniu
          <Button
            type="link"
            onClick={() => setIsDomainModalVisible(true)}
            style={{
              padding: 0
            }}
          >
            <Typography.Text
              style={{
                color: token.colorPrimary
              }}
            >
              Modifică domenii
            </Typography.Text>
          </Button>
        </Flex>
      ),
      rules: [{ required: true }],
      component: (
        <Select onChange={(value) => form.setFieldValue("field", value)}>
          {projectDomains?.slice(1).map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ),
      shared: true
    },
    {
      name: "period",
      label: "Perioada proiect",
      rules: [{ required: true }],
      component: (
        <RangePicker
          format={FORAMT_DAY_MONTH_YEAR_WITH_SLASH}
          disabledDate={disabledDate}
        />
      ),
      shared: true
    },
    {
      name: "budget",
      label: "Bugdet",
      rules: [{ required: true }],
      component: <Input suffix="MDL" type="number" />,
      shared: true
    },
    {
      name: "description",
      label: "Descriere",
      rules: [{ required: true }],
      type: "Editor",
      shared: false
    },
    {
      name: "address",
      label: "Adresa",
      rules: [{ required: true }],
      component: <Input />,
      shared: true
    },
    {
      name: "financial_resources",
      label: "Resurse financiare",
      rules: [{ required: false }],
      needsLangKey: true,
      renderComponentWithKey: (langKey) => (
        <FinancialResources langKey={langKey} />
      ),
      type: "List",
      shared: false
    }
  ];

  const position: LatLngTuple | LatLngTuple[] | undefined = data?.location?.lat
    ? [data.location.lat, data.location.lng]
    : [48.167941, 27.297034];

  return (
    <Card
      style={{
        maxWidth: 500
      }}
    >
      <MultiLanguageForm form={form} formItems={formItems} initialValues={data}>
        <Form.Item name="attachments" label="Imagini carousel">
          <UploadComponent files={files} setFormData={setFiles} />
        </Form.Item>

        <Form.Item name="location" label="Locația pe mapă">
          <Flex
            style={{
              padding: 16,
              height: 300
            }}
          >
            <MapContainer
              zoomControl={false}
              center={position}
              zoom={14}
              style={{
                borderRadius: 20,
                width: "100%",
                height: "100%"
              }}
            >
              <TileLayer
                attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <Marker
                icon={L.icon({
                  iconUrl: "/assets/map/marker.svg",
                  iconSize: [48, 48]
                })}
                position={normalizePositions(position)[0]}
                draggable
                eventHandlers={{
                  moveend: (event) => {
                    const { lat, lng } = event.target.getLatLng();
                    form.setFieldValue("location", {
                      lat,
                      lng
                    });
                  }
                }}
              />

              <ZoomControl position="bottomright" />
            </MapContainer>
          </Flex>
        </Form.Item>
      </MultiLanguageForm>
      <DomainModal
        open={isDomainModalVisible}
        onCancel={() => setIsDomainModalVisible(false)}
      />
    </Card>
  );
};
