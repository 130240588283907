import { Key } from "react";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Paginated, PollEntity, TextAnswersEntity } from "types";
import { ssoClient } from "./axios";

const getAll = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<PollEntity[]>>(
    "/cms/surveys",
    {
      params: queryParams
    }
  );

  return data;
};

const getById = async (id: number) => {
  const { data } = await ssoClient.get<PollEntity>(`/cms/surveys/${id}`);

  return data;
};

const create = async (pollData: PollEntity) => {
  const { data } = await ssoClient.post(`/cms/surveys`, pollData);

  return data;
};

const update = async (id: number, pollData: PollEntity) => {
  const { data } = await ssoClient.patch(`/cms/surveys/${id}`, pollData);

  return data;
};

const deletePoll = async (id: number) => {
  const { data } = await ssoClient.delete(`/cms/surveys/${id}`);

  return data;
};

const getResultsById = async (id: number) => {
  const { data } = await ssoClient.get<PollEntity>(
    `/cms/surveys/${id}/results`
  );

  return data;
};

const getResultsTextsById = async (queryParams: URLSearchParams) => {
  const { data } = await ssoClient.get<Paginated<TextAnswersEntity[]>>(
    "/surveys/text-answers",
    {
      params: queryParams
    }
  );

  return data;
};

const bulkDelete = async (id: Key[]) => {
  const { data } = await ssoClient.delete(`/cms/surveys/bulk-delete`, {
    data: { ids: id }
  });

  return data;
};

const polls = {
  getAll,
  getById,
  update,
  create,
  deletePoll,
  getResultsById,
  getResultsTextsById,
  bulkDelete
};

export const pollQueries = createQueryKeys("polls", {
  all: (params = undefined) => ({
    queryKey: [{ list: "list", ...params }],
    queryFn: () => getAll(params)
  }),
  byId: (id) => ({
    queryKey: [id],
    queryFn: () => getById(id)
  }),
  resultsById: (id) => ({
    queryKey: [id, "results"],
    queryFn: () => getResultsById(id)
  }),
  textResultsById: (params = undefined) => ({
    queryKey: [{ list: "text-results", ...params }],
    queryFn: () => getResultsTextsById(params)
  })
});

export default polls;
