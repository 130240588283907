import { Flex } from "antd";
import { BackButton } from "components/BackButton";
import { DraftLayout } from "features/projects/Layouts";
import { IdeaDetails } from "../types";

export const ViewIdeaDetails = ({ data }: IdeaDetails) => {
  return (
    <Flex
      gap={8}
      vertical
      style={{
        width: "70%"
      }}
    >
      <BackButton path="/projects" tab="proposed-ideas" />

      <DraftLayout data={data} />
    </Flex>
  );
};
