import { createQueryKeys } from "@lukemorales/query-key-factory";

import { Settings, SettingsPayload } from "types";
import { ssoClient } from "./axios";

const getAll = async () => {
  const { data } = await ssoClient.get<Settings>("/cms/settings/all");

  return data;
};

const bulkUpdate = async (settings: SettingsPayload[]) => {
  const { data } = await ssoClient.patch("/cms/settings/bulk", settings);

  return data;
};

const settings = {
  getAll,
  bulkUpdate
};

export const settingsQueries = createQueryKeys("settings", {
  all: () => ({
    queryKey: ["list"],
    queryFn: () => getAll()
  })
});

export default settings;
