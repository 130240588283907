import { Link } from "react-router-dom";
import { Button, Divider, Flex, Form, message, Result, Typography } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api, { queries } from "api";
import { LoadingScreen } from "components/LoadingScreen";
import { Settings, SettingsNamespace, UserRole } from "types";
import { SettingsForm } from "../components";

export const SettingsPage = () => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: api.settings.bulkUpdate,

    onSuccess: () => {
      message.success("Setările au fost actualizate cu succes!");
      queryClient.invalidateQueries(queries.settings.all());
    }
  });

  const onSubmit = () => {
    const values: Settings = form.getFieldsValue(true);

    const payload = Object.keys(values).map((codename) => {
      let value = values[codename];
      if (typeof value === "boolean") {
        value = value ? "True" : "False";
      }

      return {
        codename: codename as SettingsNamespace.codename,
        value: value,
        value_type: values[codename].value_type
      };
    });

    mutate(payload);
  };

  const onCancel = () => {
    form.resetFields();
  };

  const { data: profileInfo, isLoading: profileLoading } = useQuery({
    ...queries.profile.profile()
  });

  if (profileLoading) {
    return <LoadingScreen />;
  }

  if (profileInfo?.role !== UserRole.SUPERUSER) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Nu aveți permisiunea de a accesa această pagină."
        extra={
          <Link to="/statistics">
            <Button type="primary">Inapoi</Button>
          </Link>
        }
      />
    );
  }

  return (
    <Flex vertical gap={8}>
      <Flex justify="space-between" align="center">
        <Typography.Title level={2}>Setări CMS</Typography.Title>

        <Flex gap={8}>
          <Button onClick={onCancel} size="large">
            Anulează
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            onClick={onSubmit}
            size="large"
          >
            Salvează modificările
          </Button>
        </Flex>
      </Flex>

      <Divider />

      <SettingsForm form={form} />
    </Flex>
  );
};
