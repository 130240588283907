import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Typography } from "antd";
import { MailFilled } from "@ant-design/icons";

import { useMutation } from "@tanstack/react-query";
import { Button } from "antd/lib";
import api, { setLocalStorageTokens } from "api";
import authorization from "api/authorization";
import { showErrorMessage } from "utils";
import { FormWrapper, OTPInput, PageTag } from "../components";

type Props = {
  type?: "login" | "reset";
};

const payloadMap = {
  login: (otp_code: string, email: string, password?: string) => ({
    otp_code,
    email,
    password,
    scope: "cms",
    code_type: "login"
  }),
  reset: (otp_code: string, email: string) => ({
    otp_code,
    code_type: "reset_password",
    email
  })
};

const typeMap = {
  login: {
    confirm: authorization.verifyOTPCode,
    resend: authorization.login
  },
  reset: {
    confirm: authorization.verifyOTPCode,
    resend: authorization.resetPassword
  }
};

const confirmType = (type: string) => typeMap[type].confirm;
const resendType = (type: string) => typeMap[type].resend;

const getPayload = (
  type: string,
  otp_code: string,
  email?: string,
  password?: string
) => {
  const payloadFunction = payloadMap[type];

  if (!payloadFunction) {
    throw new Error(`Invalid type: ${type}`);
  }

  return payloadFunction(otp_code, email, password);
};

export const OtpCheckPage = ({ type = "login" }: Props) => {
  const [countdown, setCountdown] = useState(60);

  const navigate = useNavigate();

  const mutationSuccess = async () => {
    if (type === "login") {
      const response = await api.authorization.confirmLogin({
        email,
        password
      });
      setLocalStorageTokens(response?.access ?? "", response?.refresh ?? "");
      navigate(0);
    } else {
      navigate("/authentication/reset-password-confirm");
    }
  };

  const { mutate: confirmMutate } = useMutation({
    mutationFn: confirmType(type),
    onError: showErrorMessage,
    onSuccess: mutationSuccess
  });

  const { mutate: resendMutate } = useMutation({
    mutationFn: resendType(type),
    onError: showErrorMessage
  });

  const email = sessionStorage.getItem("email") ?? "";
  const password = sessionStorage.getItem("password") ?? "";

  const onResendCode = () => {
    const payload = getPayload(type, "", email, password);
    resendMutate(payload);

    if (countdown === 0) {
      setCountdown(60);
    }
  };

  const onFormSubmit = (value: string) => {
    const payload = getPayload(type, value, email, password);
    confirmMutate(payload);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  return (
    <FormWrapper
      title="Verifica-ti email-ul"
      subTitle={
        <>
          Codul de resetare a parolei a fost trimis la adresa de e-mail{" "}
          <Typography.Text strong>{email}</Typography.Text>.
        </>
      }
      topStep={<PageTag icon={<MailFilled />} />}
      bottomAction={
        <Typography.Text strong>
          Nu ai primit emailul? Vă rugăm să verificați folderul de spam sau{" "}
          <Link to="" onClick={onResendCode}>
            <Button
              type="link"
              disabled={countdown > 0}
              style={{
                padding: 0,
                fontWeight: "bold"
              }}
            >
              încearcă din nou {countdown > 0 && `(${countdown})`}
            </Button>
          </Link>
          .
        </Typography.Text>
      }
    >
      <Form.Item label="Codul de verificare" name="otp_code">
        <OTPInput onChange={onFormSubmit} />
      </Form.Item>
    </FormWrapper>
  );
};
