import React from "react";
import { Form, message, Tabs } from "antd";
import classnames from "classnames";
import { Tab } from "rc-tabs/es/interface";
import { CollapseTriggers } from "../../CollapseTriggers";
import FormFields from "../../FormFields";
import { FormData, FormTabs, FormField, FormSchema } from "../../interface";
import { createFieldClassName } from "../../utils";
import { normalizeFormValues, isFormTabsArray } from "./utils";
import "./FormProperties.css";

interface FormSidebarProps {
  className?: string;
  formId?: string;
  data?: FormData | null;
  items: FormSchema;
  onSubmit?: (values: FormData) => void;
  onValuesChange?: (changedValues: FormData, values: FormData) => void;
}

const FormProperties: React.FC<FormSidebarProps> = ({
  className,
  formId,
  data,
  items = [],
  onSubmit,
  onValuesChange
}) => {
  const [form] = Form.useForm();

  const onSubmitForm = async () => {
    try {
      const values = await form.validateFields();

      if (onSubmit) {
        const normalizedValues = normalizeFormValues(values, items);

        onSubmit(normalizedValues);
      }
    } catch (err) {
      message.error(
        "Este necesară completarea tuturor formularelor în toate limbile"
      );
    }
  };

  // Render from item by type
  const renderFormItem = (item: FormField) => {
    const key = `key-${item.name}`;
    const fieldClassName = createFieldClassName(item);
    const defaultValue =
      data && data?.hasOwnProperty(item.name) ? data[item.name] : undefined; // undefined is for placeholder

    // Take form item component by type and fill with data
    const FormItemField = FormFields?.hasOwnProperty(item?.type)
      ? FormFields[item?.type]
      : FormFields.Default;

    const itemData = {
      ...item,
      initialValue: defaultValue
    };

    return (
      <FormItemField
        key={key}
        data={itemData}
        form={form}
        className={fieldClassName}
        parentName={itemData.name}
      />
    );
  };

  const renderFormTab = (item: FormTabs): Tab => {
    const itemForm = [...item.form];
    return {
      key: item.id,
      label: item.label,
      forceRender: true,
      children: itemForm.map((formItem: FormField) => renderFormItem(formItem))
    };
  };

  return (
    <section className={classnames("form-generator__properties", className)}>
      {/* Toolbar */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CollapseTriggers />
      </div>
      <Form
        id={formId}
        form={form}
        layout="vertical"
        onSubmitCapture={onSubmitForm}
        onValuesChange={onValuesChange}
      >
        {isFormTabsArray(items) ? (
          <Tabs
            defaultActiveKey="generic"
            items={items.map((item: FormTabs) => renderFormTab(item))}
          />
        ) : (
          items.map((item: FormField) => renderFormItem(item))
        )}
      </Form>
    </section>
  );
};

export default FormProperties;
