const UserIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666687 16C0.666687 7.16344 7.83013 0 16.6667 0H40.6667C49.5032 0 56.6667 7.16344 56.6667 16V40C56.6667 48.8366 49.5032 56 40.6667 56H16.6667C7.83013 56 0.666687 48.8366 0.666687 40V16Z"
        fill="#ECFDF5"
      />
      <path
        d="M25.6667 18C23.0467 18 20.9167 20.13 20.9167 22.75C20.9167 25.32 22.9267 27.4 25.5467 27.49C25.6267 27.48 25.7067 27.48 25.7667 27.49C25.7867 27.49 25.7967 27.49 25.8167 27.49C25.8267 27.49 25.8267 27.49 25.8367 27.49C28.3967 27.4 30.4067 25.32 30.4167 22.75C30.4167 20.13 28.2867 18 25.6667 18Z"
        fill="#10B981"
      />
      <path
        d="M30.7467 30.15C27.9567 28.29 23.4067 28.29 20.5967 30.15C19.3267 31 18.6267 32.15 18.6267 33.38C18.6267 34.61 19.3267 35.75 20.5867 36.59C21.9867 37.53 23.8267 38 25.6667 38C27.5067 38 29.3467 37.53 30.7467 36.59C32.0067 35.74 32.7067 34.6 32.7067 33.36C32.6967 32.13 32.0067 30.99 30.7467 30.15Z"
        fill="#10B981"
      />
      <path
        d="M36.6567 23.34C36.8167 25.28 35.4367 26.98 33.5267 27.21C33.5167 27.21 33.5167 27.21 33.5067 27.21H33.4767C33.4167 27.21 33.3567 27.21 33.3067 27.23C32.3367 27.28 31.4467 26.97 30.7767 26.4C31.8067 25.48 32.3967 24.1 32.2767 22.6C32.2067 21.79 31.9267 21.05 31.5067 20.42C31.8867 20.23 32.3267 20.11 32.7767 20.07C34.7367 19.9 36.4867 21.36 36.6567 23.34Z"
        fill="#10B981"
      />
      <path
        d="M38.6567 32.59C38.5767 33.56 37.9567 34.4 36.9167 34.97C35.9167 35.52 34.6567 35.78 33.4067 35.75C34.1267 35.1 34.5467 34.29 34.6267 33.43C34.7267 32.19 34.1367 31 32.9567 30.05C32.2867 29.52 31.5067 29.1 30.6567 28.79C32.8667 28.15 35.6467 28.58 37.3567 29.96C38.2767 30.7 38.7467 31.63 38.6567 32.59Z"
        fill="#10B981"
      />
    </svg>
  );
};

export default UserIcon;
