import { css } from "@emotion/react";

export const styles = () => ({
  tabs: css`
    .ant-tabs-nav {
      &::before {
        border-bottom: none;
      }
    }
  `
});
