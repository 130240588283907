import {
  Card,
  Checkbox,
  Flex,
  Form,
  FormInstance,
  Input,
  Space,
  Spin
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { queries } from "api";

type Props = {
  form: FormInstance;
};

export const SettingsForm = ({ form }: Props) => {
  const { data, isLoading } = useQuery({
    ...queries.settings.all()
  });

  return (
    <Form layout="vertical" form={form} id="settings-form">
      {isLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Card
          style={{
            maxWidth: 500
          }}
        >
          <Flex
            vertical
            style={{
              maxWidth: 450
            }}
          >
            <Space>
              <Form.Item
                label="Email Host"
                name="email_host"
                initialValue={data?.email_host?.value}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email Port"
                name="email_port"
                initialValue={data?.email_port?.value}
              >
                <Input />
              </Form.Item>
            </Space>

            <Space>
              <Form.Item
                label="Email From Address"
                name="email_default_from"
                initialValue={data?.email_default_from?.value}
              >
                <Input />
              </Form.Item>
            </Space>

            <Space>
              <Form.Item
                label="Email Username"
                name="email_username"
                initialValue={data?.email_username?.value}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email Password"
                name="email_password"
                initialValue={data?.email_password?.value}
              >
                <Input.Password />
              </Form.Item>
            </Space>

            <Space>
              <Form.Item
                label="Email use SSL"
                name="email_use_ssl"
                valuePropName="checked"
                initialValue={data?.email_use_ssl?.value === "True"}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                label="Email Use TLS"
                name="email_use_tls"
                valuePropName="checked"
                initialValue={data?.email_use_tls?.value === "True"}
              >
                <Checkbox />
              </Form.Item>
            </Space>

            <Form.Item
              label="Support Email"
              name="support_email"
              initialValue={data?.support_email?.value}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Account Inactivity Deletion Days"
              name="account_inactivity_deletion_days"
              initialValue={data?.account_inactivity_deletion_days?.value}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Account Deletion Warning Days"
              name="account_deletion_warning_days"
              initialValue={data?.account_deletion_warning_days?.value}
            >
              <Input />
            </Form.Item>
          </Flex>
        </Card>
      )}
    </Form>
  );
};
