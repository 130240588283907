import { Link } from "react-router-dom";
import { Col, Flex, Row, Space, Typography, theme } from "antd";

import authBackground from "assets/images/AuthBackground.png";
import logo from "assets/images/SmartEdinetLogo.png";
import { styles } from "./styles";

interface AuthLayoutProps extends React.PropsWithChildren {}

const year = new Date().getFullYear();

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { token } = theme.useToken();

  const cssStyle = styles(token);

  return (
    <Row css={cssStyle.base}>
      <Col span={12}>
        <Flex
          css={cssStyle.containerWrapper}
          justify="center"
          align="center"
          vertical
        >
          <Flex justify="center" align="center" css={cssStyle.fullWidth}>
            <div css={cssStyle.header}>
              <Link to="/">
                <Space align="center">
                  <img src={logo} alt="logo" />
                  <Typography.Title level={3} css={cssStyle.m0}>
                    Smart Edineț
                  </Typography.Title>
                </Space>
              </Link>
            </div>
          </Flex>
          <Flex flex={1} justify="center" align="center">
            <div css={cssStyle.contentWrapper}>{children}</div>
          </Flex>
          © {year} Smart Edineț. All rights reserved.
        </Flex>
      </Col>
      <Col span={12}>
        <img src={authBackground} alt="auth-background" css={cssStyle.img} />
      </Col>
    </Row>
  );
};
