import React, { useContext } from "react";
import { Button, Form, Space } from "antd";
import { DeleteFilled, HolderOutlined } from "@ant-design/icons";
import { NamePath } from "antd/lib/form/interface";

import { ContentTypesContext } from "components/FormBuilder/contexts";
import { useDnDItem } from "components/FormBuilder/hooks/useDndItem";
import { makeNamePath } from "components/FormBuilder/utils/form";

import ComponentFieldCollapse from "../../ComponentFieldCollapse";
import type { Identifier } from "dnd-core";

export interface DnDListItemProps {
  name: NamePath;

  index: number;
  /**
   * Item type in case of nested lists
   */
  itemType: Identifier;

  /**
   * While the user is still dragging change the position visually
   */
  onMovePreview: (dragIndex: number, hoverIndex: number) => void;
  /**
   * After the user is finished dragging (on drop) save the state
   */
  onMoveFinish: () => void;
  onRemove: () => void;
  parentName?: NamePath;
}

interface FieldValue {
  content_type_id: string;
  data: any;
}

const DnDListItem: React.FC<DnDListItemProps> = ({
  name,
  itemType,
  index,
  onRemove,
  onMovePreview,
  onMoveFinish,
  parentName
}) => {
  const contentTypeContext = useContext(ContentTypesContext);

  const {
    itemRef: ref,
    preview,
    drop,
    drag,
    isDragging
  } = useDnDItem({
    index,
    itemType,
    onMoveFinish,
    onMovePreview
  });

  preview(drop(ref));

  const opacity = isDragging ? 0.3 : 1;
  return (
    <div ref={ref} style={{ opacity }}>
      <Form.Item
        noStyle
        dependencies={[name]}
        // eslint-disable-next-line react/no-children-prop
        children={(form) => {
          const mappingName =
            Array.isArray(parentName) && Array.isArray(name)
              ? [...parentName, ...name.slice(1)]
              : name;

          const value: FieldValue = form.getFieldValue(mappingName);

          const matchedContentType = contentTypeContext?.contentTypes.find(
            (contentType) => contentType?.id === value?.content_type_id
          );

          if (!matchedContentType)
            return (
              <h4>
                The content type for the Component field with{" "}
                <code>{name}</code> could not be found
              </h4>
            );

          return (
            <ComponentFieldCollapse
              className="mb-15"
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div style={{ cursor: "move" }} ref={drag}>
                    <Space align="center">
                      <HolderOutlined />
                      <div>{matchedContentType.name}</div>
                    </Space>
                  </div>
                </div>
              }
              initialValue={undefined}
              name={makeNamePath(index, "data")}
              subFormFields={matchedContentType.mapping}
              panelExtra={
                <Button
                  size="small"
                  type="text"
                  icon={<DeleteFilled />}
                  onClick={onRemove}
                />
              }
              parentName={mappingName}
              items={undefined}
            />
          );
        }}
      />
    </div>
  );
};

export default DnDListItem;
