import React, { useContext } from "react";
import {
  CKEditor,
  CKEditorEventHandlerProp,
  CKEditorProps
} from "ckeditor4-react";

import { UPLOAD_ENDPOINT } from "../FormBuilder/FormGenerator/FormMediaUpload";
import { APIContext, FormGeneratorContext } from "../FormBuilder/context";
import { contentTypeFormat, ebsFormPlugin, CKEDITOR as CKEDITORTYPE } from ".";

type EditorCKEditorProps = CKEditorProps<CKEditorEventHandlerProp>;

export interface EditorProps
  extends Omit<Partial<EditorCKEditorProps>, "initData"> {
  data?: string;
}

const Editor: React.FC<EditorProps> = ({ ...props }) => {
  const { ckeditorFilePath } = useContext(FormGeneratorContext);
  const apiConfig = useContext(APIContext);

  const onFileUploadRequest = (
    evt: CKEDITORTYPE.eventInfo,
    CKEDITOR: typeof CKEDITORTYPE
  ) => {
    const filebrowserFn = evt.editor._.filebrowserFn;
    const xhr = (evt.data.fileLoader.xhr = new XMLHttpRequest());
    xhr.open("POST", evt.data.fileLoader.uploadUrl);
    const formdata = new FormData();
    formdata.append("file", evt.data.requestData.upload.file);
    xhr.send(formdata);
    xhr.onload = () => {
      const url = JSON.parse(xhr.response)?.url;
      CKEDITOR && CKEDITOR.tools.callFunction(filebrowserFn, url);
    };
    evt.stop();
  };

  const setAcceptUploadFile = (evt: CKEDITORTYPE.eventInfo) => {
    const name = evt.data.name;
    const type = Object.values(contentTypeFormat).find(({ plugins }) =>
      plugins.includes(name)
    )?.accept;
    const lastShow = evt.data.definition.onShow;

    evt.data.definition.onShow = function (...e: any[]) {
      // Added custom plugin --------------->
      ebsFormPlugin(evt, apiConfig);
      // Added custom plugin ---------------<
      lastShow?.apply(this, e);
      this.getButton("ok")
        .getInputElement()
        .setStyle("backgroundColor", "var(--antd-wave-shadow-color)");
      this.getButton("ok")
        .getInputElement()
        .setStyle("border", "1px solid var(--antd-wave-shadow-color)");
      this.foreach((element: any) => {
        if (element.type === "file") {
          type && element.getInputElement().setAttribute("accept", type);
        } else if (element.type === "button") {
          element.getInputElement().setAttribute("target", "_blank");
        }
      });
    };
  };

  return (
    <CKEditor
      onBeforeLoad={(CKEDITOR: typeof CKEDITORTYPE) => {
        CKEDITOR.disableAutoInline = true;
        CKEDITOR.on("instanceReady", (evt) =>
          evt.editor.on("fileUploadRequest", (evt) =>
            onFileUploadRequest(evt, CKEDITOR)
          )
        );
        CKEDITOR.on("dialogDefinition", setAcceptUploadFile);
      }}
      initData={props.data}
      onDestroy={() => {
        window?.CKEDITOR?.removeAllListeners();
      }}
      config={{
        filebrowserImageUploadUrl: UPLOAD_ENDPOINT,
        filebrowserUploadUrl: UPLOAD_ENDPOINT,
        filebrowserImageBrowseUrl:
          ckeditorFilePath && `${ckeditorFilePath}?content_type=image`,
        filebrowserBrowseUrl: ckeditorFilePath
      }}
      {...props}
    />
  );
};

export default Editor;
