import { GlobalToken } from "antd";
import { css } from "@emotion/react";

export const styles = (token: GlobalToken) => ({
  base: css`
    height: 100vh;
    > * {
      height: 100%;
    }
  `,

  img: css`
    width: 100%;
    height: 100%;
    object-fit: fill;
  `,

  contentWrapper: css`
    width: 350px;
  `,

  containerWrapper: css`
    height: 100%;
    padding: 50px 12px;
  `,

  otpInput: css`
    .ant-otp {
      width: 100%;
      .ant-input {
        height: 80px;
        font-size: 48px;
      }
    }
  `,

  validationPassword: {
    progress: css`
      width: 100%;
      .ant-progress {
        &-steps {
          &-item {
            flex: 1;
          }
        }
      }
    `
  },

  fullWidth: css`
    width: 100%;
  `,

  pageTag: css`
    width: 62px;
    height: 62px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-width: 7px;
    border-style: solid;
  `,

  header: css`
    max-width: 600px;
    flex: 1;
  `,

  m0: css`
    margin: 0 !important;
  `
});
