import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Radio,
  Space,
  Dropdown,
  DropdownProps,
  Row,
  Col,
  Typography,
  Input
} from "antd";
import { useQuery } from "@tanstack/react-query";
import queryString from "query-string";

import api, { queries } from "api";
import { AddContent, ContentListTable } from "features/content/components";
import {
  getContentTypes,
  useContentType
} from "features/content-types/contexts";
import { cssDropdown } from "style";
import {
  filterByStatus,
  FilterState,
  Filters,
  PageSettings,
  showErrorMessage
} from "utils";

export const ContentListPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [sortItems, setSortItems] = useState<string>("All");
  // Content types list
  const [{ contentTypesTuple }, dispatchContentType] = useContentType();
  const params = queryString.parse(search);

  // Fetch content type list
  const fetchContentTypes = useCallback(
    () => getContentTypes(dispatchContentType, api.contentType.getAll()),
    [dispatchContentType]
  );

  // Set sort by content types with "All" value
  const sortByContentTypes = useMemo(
    () => [
      {
        key: "all",
        label: "All"
      },
      ...contentTypesTuple.map(({ key, title }) => ({ key, label: title }))
    ],
    [contentTypesTuple]
  );

  // Fetch content list
  const {
    data: content,
    refetch: refetchContentList,
    isLoading
  } = useQuery({
    ...queries.content.search(params),
    queryFn: () =>
      api.content.search({
        search: params.searchTerm || undefined,
        type_id: params.type !== "all" ? params.type : undefined,
        status: params.status !== "all" ? params.status : undefined,
        page: params.page,
        per_page: params.pageSize
      }),
    onError: (error) => showErrorMessage(error)
  });

  // Handle Change Filters
  const handleChangeFilters = useCallback(
    (type: keyof FilterState, value: string | number | PageSettings) => {
      const isPageSettings = type === Filters.PAGE_SETTINGS;
      const { current: page, size: pageSize } = isPageSettings
        ? (value as PageSettings)
        : { current: params.page || 1, size: params.pageSize || 10 };

      const newFilters = {
        ...params,
        ...(!!value && !isPageSettings && { [type]: value }),
        page: isPageSettings ? page : 1,
        pageSize
      };

      if (value === "all") {
        delete newFilters[type];
      }

      const url = queryString.stringifyUrl({
        url: "/content",
        query: newFilters
      });

      navigate(url);
    },
    [params, navigate]
  );

  // Pagination config
  const paginationConfig = useMemo(() => {
    return {
      current: params.page,
      total: content && content.total ? content.total : content?.results.length,
      pageSize: params.pageSize,
      onChange: (current: number, size: number) =>
        handleChangeFilters(Filters.PAGE_SETTINGS, {
          current: Number(params.pageSize) === Number(size) ? current : 1,
          size
        })
    };
  }, [content, handleChangeFilters, params.page, params.pageSize]);

  useEffect(() => {
    if (!contentTypesTuple.length) {
      fetchContentTypes();
    }
  }, [contentTypesTuple.length, fetchContentTypes]);

  const dropDownProps: DropdownProps = {
    menu: {
      items: sortByContentTypes,
      selectable: true,
      style: cssDropdown,
      onSelect: ({ key }) => {
        const labelSort =
          sortByContentTypes.find((item) => item.key === key)?.label || "All";

        handleChangeFilters(Filters.SORT, key);
        setSortItems(labelSort);
      }
    },
    trigger: ["click"],
    placement: "bottomRight"
  };

  return (
    <>
      <Row
        gutter={[20, 10]}
        className="mb-20"
        justify="space-between"
        id="content-table-filters"
      >
        <Col xs={24} md={24} lg={24} xl={12}>
          <div className="d-flex align-items-center gap-8">
            <Typography.Title level={5} className="mb-0">
              {`Content (${content?.total || "0"})`}
            </Typography.Title>

            <Input.Search
              onSearch={(value) => handleChangeFilters(Filters.SEARCH, value)}
              className="mw-300"
              placeholder="Search"
              size="large"
            />
          </div>
        </Col>
        <Col>
          <Space>
            <Radio.Group
              defaultValue={(params.status as string) || "all"}
              options={filterByStatus}
              buttonStyle="solid"
              size="large"
              optionType="button"
              onChange={(event) =>
                handleChangeFilters(Filters.FILTER, event.target.value)
              }
            />

            <Dropdown.Button size="large" {...dropDownProps}>
              {sortItems}
            </Dropdown.Button>

            <AddContent
              contentTypes={contentTypesTuple}
              contentType={params.type as string}
            />
          </Space>
        </Col>
      </Row>

      <ContentListTable
        loading={isLoading}
        contentList={content?.results || []}
        fetchContentList={refetchContentList}
        paginationConfig={paginationConfig}
      />
    </>
  );
};
